import {version} from './Version';
import { AnterosRemoteResource, GET } from '@anterostecnologia/anteros-react-api';
import {makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const PESSOA = 'pessoa';
const pessoaReducerDef = makeDefaultReduxObject(PESSOA);
const pessoaConsultaReducerDef = makeDefaultReduxObject(PESSOA.toUpperCase()+"_SEARCH");

export class PessoaResource extends AnterosRemoteResource {
    constructor(){
        super(PESSOA,`${version}/abstractPessoa/`);
    }

    buscarClientesRegiao(resourceName, id, page, size, user, fieldsToForceLazy = "") {
      let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaClientesRegiaoComercial/${id}?page=${page}&size=${size}&fieldsToForceLazy=${fieldsToForceLazy}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    buscarClientesRegiaoFiltro(resourceName, id, page, size, user, fieldsToForceLazy = "", filter, fields, sort) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaClientesRegiaoComercialFiltro/${id}?page=${page}&size=${size}&fieldsToForceLazy=${fieldsToForceLazy}&filter=${filter}&fields=${fields}&sort=${sort}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    buscarHistoricoCompras(resourceName, user, idCliente) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaHistoricoDeCompras?clientId=${idCliente}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
}

export const pessoaReducer = createReducer(initialState,pessoaReducerDef);
export const pessoaConsultaReducer = createReducer(initialState,pessoaConsultaReducerDef);