import {version} from './Version';
import { AnterosRemoteResource, GET } from '@anterostecnologia/anteros-react-api';
import {makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const CHECKLIST = 'checklist';
const checklistReducerDef = makeDefaultReduxObject(CHECKLIST);
const checklistConsultaReducerDef = makeDefaultReduxObject(CHECKLIST.toUpperCase()+"_SEARCH");

export class ChecklistResource extends AnterosRemoteResource {
    constructor(){
        super(CHECKLIST,`${version}/checklist/`);
    }

    alteraStatusChecklist(resourceName, id, status, user) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}alteraStatusCheckList/${id}/${status}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
}

export const checklistReducer = createReducer(initialState,checklistReducerDef);
export const checklistConsultaReducer = createReducer(initialState,checklistConsultaReducerDef);