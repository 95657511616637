import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const FORMA_PAGAMENTO = 'formaPagamento';
const formaPagamentoReducerDef = makeDefaultReduxObject(FORMA_PAGAMENTO);
const formaPagamentoConsultaReducerDef = makeDefaultReduxObject(FORMA_PAGAMENTO.toUpperCase()+"_SEARCH");

export class FormaPagamentoResource extends AnterosRemoteResource {
    constructor(){
        super(FORMA_PAGAMENTO,`${version}/formaPagamento/`);
    }
}

export const formaPagamentoReducer = createReducer(initialState,formaPagamentoReducerDef);
export const formaPagamentoConsultaReducer = createReducer(initialState,formaPagamentoConsultaReducerDef);