import {version} from './Version';
import { AnterosRemoteResource, GET } from '@anterostecnologia/anteros-react-api';
import {makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const DASHBOARD_CONFIG = 'dashboardConfig';
const dashboardConfigReducerDef = makeDefaultReduxObject(DASHBOARD_CONFIG);
const dashboardConfigConsultaReducerDef = makeDefaultReduxObject(DASHBOARD_CONFIG.toUpperCase()+"_SEARCH");

export class DashboardConfigResource extends AnterosRemoteResource {
    constructor(){
        super(DASHBOARD_CONFIG,`${version}/dashboardConfig/`);
    }

    buscarDashboardConfig(resourceName, name, user) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}getConfigByForm?name=${name}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
}

export const dashboardConfigReducer = createReducer(initialState,dashboardConfigReducerDef);
export const dashboardConfigConsultaReducer = createReducer(initialState,dashboardConfigConsultaReducerDef);