import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const USUARIO_ACAO = 'usuarioAcao';
const usuarioAcaoReducerDef = makeDefaultReduxObject(USUARIO_ACAO);
const usuarioAcaoConsultaReducerDef = makeDefaultReduxObject(USUARIO_ACAO.toUpperCase()+"_SEARCH");

export class UsuarioAcaoResource extends AnterosRemoteResource {
    constructor(){
        super(USUARIO_ACAO,`${version}/usuarioAcao/`);
    }
}

export const usuarioAcaoReducer = createReducer(initialState,usuarioAcaoReducerDef);
export const usuarioAcaoConsultaReducer = createReducer(initialState,usuarioAcaoConsultaReducerDef);