import {version} from './Version';
import { AnterosRemoteResource, POST, GET } from '@anterostecnologia/anteros-react-api';
import {makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const PEDIDO_RECEBIDO = 'pedidoRecebido';
const pedidoRecebidoReducerDef = makeDefaultReduxObject(PEDIDO_RECEBIDO);
const pedidoRecebidoConsultaReducerDef = makeDefaultReduxObject(PEDIDO_RECEBIDO.toUpperCase()+"_SEARCH");

export class PedidoRecebidoResource extends AnterosRemoteResource {

    constructor() {
        super(PEDIDO_RECEBIDO,`${version}/pedidoRecebido/`)
    }

    liberarPedido(resourceName, user, pedidos) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        JSON.stringify()
        let result = {
            url: `${_resourceName}liberarpedidos`,
            method: POST,
            data: pedidos
        }
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    isDescontoAutomatico(resourceName, tabelaPrecoId, produtoId, user) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}descontoAutomatico?produtoId=${produtoId}&tabelaPrecoId=${tabelaPrecoId}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    
    buscarResumoPedidosRecebidos(resourceName, user) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaResumoPedidosRecebidos`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    buscarResumoPedidosRecebidosClientes(resourceName, user) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaResumoPedidosRecebidosClientes`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    buscarResumoClientesPorRegiao(resourceName, user, regiao) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaResumoClientes/${regiao}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    buscarResumoClientes(resourceName, user) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaResumoClientes`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    buscarResumoPedidosRecebidosPorRegiao(
        idRegiao,
        resourceName,
        page,
        size,
        sort,
        user,
        fieldsToForceLazy
    ) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaResumoPedidosRecebidos/${idRegiao}?&page=${page}&size=${size}&sort=${sort}&fieldsToForceLazy=${fieldsToForceLazy}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    enviarEmailPedido(resourceName, user, pedidoId, emails) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}enviarCopiaPorEmail?pedidoId=${pedidoId}&emails=${emails}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }


}

export const pedidoRecebidoReducer = createReducer(initialState,pedidoRecebidoReducerDef);
export const pedidoRecebidoConsultaReducer = createReducer(initialState,pedidoRecebidoConsultaReducerDef);

