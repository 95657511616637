import {
    version
} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const ATENDIMENTO = 'atendimento';
const atendimentoReducerDef = makeDefaultReduxObject(ATENDIMENTO);
const atendimentoConsultaReducerDef = makeDefaultReduxObject(ATENDIMENTO.toUpperCase()+"_SEARCH");

export class AtendimentoResource extends AnterosRemoteResource {

    constructor() {
        super(ATENDIMENTO,`${version}/atendimento/`)
    }

}

export const atendimentoReducer = createReducer(initialState,atendimentoReducerDef);
export const atendimentoConsultaReducer = createReducer(initialState,atendimentoConsultaReducerDef);