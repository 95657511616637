import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const TIPOLIGACAO = 'tipoLigacao';
const tipoLigacaoReducerDef = makeDefaultReduxObject(TIPOLIGACAO);
const tipoLigacaoConsultaReducerDef = makeDefaultReduxObject(TIPOLIGACAO.toUpperCase()+"_SEARCH");

export class TipoLigacaoResource extends AnterosRemoteResource {
    constructor(){
        super(TIPOLIGACAO,`${version}/tipoLigacao/`);
    }
}

export const tipoLigacaoReducer = createReducer(initialState,tipoLigacaoReducerDef);
export const tipoLigacaoConsultaReducer = createReducer(initialState,tipoLigacaoConsultaReducerDef);