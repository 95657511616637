import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const CATEGORIA = 'categoria';
const categoriaReducerDef = makeDefaultReduxObject(CATEGORIA);
const categoriaConsultaReducerDef = makeDefaultReduxObject(CATEGORIA.toUpperCase()+"_SEARCH");

export class CategoriaResource extends AnterosRemoteResource {
    constructor(){
        super(CATEGORIA,`${version}/categoria/`);
    }
}

export const categoriaReducer = createReducer(initialState,categoriaReducerDef);
export const categoriaConsultaReducer = createReducer(initialState,categoriaConsultaReducerDef);