export const SET_DATASOURCE_ONDE_ESTA_MINHA_EQUIPE = 'SET_DATASOURCE_ONDE_ESTA_MINHA_EQUIPE';
export const SET_FILTER_ONDE_ESTA_MINHA_EQUIPE = 'SET_FILTER_ONDE_ESTA_MINHA_EQUIPE';
export const CLEAR_ONDE_ESTA_MINHA_EQUIPE = 'CLEAR_ONDE_ESTA_MINHA_EQUIPE';
export const SET_NEEDREFRESH = 'SET_NEEDREFRESH';

export const actions = {
  setDatasource(dataSource) {
    return { type: SET_DATASOURCE_ONDE_ESTA_MINHA_EQUIPE, payload: { dataSource } };
  },
  setFilter(currentFilter, activeFilterIndex) {
    return {
      type: SET_FILTER_ONDE_ESTA_MINHA_EQUIPE,
      payload: { currentFilter, activeFilterIndex }
    };
  },
  clear() {
    return {
      type: CLEAR_ONDE_ESTA_MINHA_EQUIPE,
      payload: {}
    };
  },
  setNeedRefresh() {
    return {
      type: SET_NEEDREFRESH,
      payload: { needRefresh: true }
    }
  }
};

export default (
  state = {
    currentFilter: undefined,
    dataSource: undefined,
    activeFilterIndex: -1,
    needRefresh: false,
    needUpdateView: false
  },
  action
) => {
  switch (action.type) {
    case SET_DATASOURCE_ONDE_ESTA_MINHA_EQUIPE: {
      return { ...state, dataSource: action.payload.dataSource, needRefresh: false };
    }
    case SET_FILTER_ONDE_ESTA_MINHA_EQUIPE: {
      return {
        ...state,
        currentFilter: action.payload.currentFilter,
        activeFilterIndex: action.payload.activeFilterIndex
      };
    }
    case CLEAR_ONDE_ESTA_MINHA_EQUIPE: {
      return state;
    }
    case SET_NEEDREFRESH: {
      return { ...state, needRefresh: true };
    }
    default: {
      return state;
    }
  }
};
