import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const GRUPO_CLIENTE = 'grupoCliente';
const grupoClienteReducerDef = makeDefaultReduxObject(GRUPO_CLIENTE);
const grupoClienteConsultaReducerDef = makeDefaultReduxObject(GRUPO_CLIENTE.toUpperCase()+"_SEARCH");

export class GrupoClienteResource extends AnterosRemoteResource {
    constructor(){
        super(GRUPO_CLIENTE,`${version}/grupoCliente/`);
    }
}

export const grupoClienteReducer = createReducer(initialState,grupoClienteReducerDef);
export const grupoClienteConsultaReducer = createReducer(initialState,grupoClienteConsultaReducerDef);