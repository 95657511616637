import React from 'react';
import classNames from 'classnames';
import moment from 'moment/moment';
import _ from 'lodash';

import { Avatar, Paper, Typography, withStyles, TextField, IconButton, Icon } from '@material-ui/core';
import { boundClass } from '@anterostecnologia/anteros-react-core';

import FuseScrollbars from '../parts/FuseScrollbars';
import {AnterosReactComponent} from '@anterostecnologia/anteros-react-core';
const styles = theme => ({
    messageRow: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        padding: '0 16px 4px 16px',
        flex: '0 0 auto',
        '&.contact': {
            '& $bubble': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                '& $time': {
                    marginLeft: 12
                }
            },
            '&.first-of-group': {
                '& $bubble': {
                    borderTopLeftRadius: 20
                }
            },
            '&.last-of-group': {
                '& $bubble': {
                    borderBottomLeftRadius: 20
                }
            }
        },
        '&.me': {
            paddingLeft: 40,

            '& $avatar': {
                order: 2,
                margin: '0 0 0 16px'
            },

            '& $bubble': {
                marginLeft: 'auto',
                backgroundColor: theme.palette.grey[300],
                color: theme.palette.getContrastText(theme.palette.grey[300]),
                borderTopLeftRadius: 20,
                borderBottomLeftRadius: 20,
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
                '& $time': {
                    justifyContent: 'flex-end',
                    right: 0,
                    marginRight: 12
                }
            },
            '&.first-of-group': {
                '& $bubble': {
                    borderTopRightRadius: 20
                }
            },

            '&.last-of-group': {
                '& $bubble': {
                    borderBottomRightRadius: 20
                }
            }
        },
        '&.contact + .me, &.me + .contact': {
            paddingTop: 20,
            marginTop: 20
        },
        '&.first-of-group': {
            '& $bubble': {
                borderTopLeftRadius: 20,
                paddingTop: 13
            }
        },
        '&.last-of-group': {
            '& $bubble': {
                borderBottomLeftRadius: 20,
                paddingBottom: 13,
                '& $time': {
                    display: 'flex'
                }
            }
        }
    },
    avatar: {
        position: 'absolute',
        left: -32,
        margin: 0
    },
    bubble: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 12,
        maxWidth: '100%'
    },
    message: {
        whiteSpace: 'pre-wrap',
        lineHeight: 1.2
    },
    time: {
        position: 'absolute',
        display: 'none',
        width: '100%',
        fontSize: 11,
        marginTop: 8,
        top: '100%',
        left: 0,
        whiteSpace: 'nowrap'
    },
    bottom: {
        background: theme.palette.background.default,
        borderTop: '1px solid rgba(0, 0, 0, 0.13)'
    },
    inputWrapper: {
        borderRadius: 24
    }
});

@boundClass
class ChatView extends AnterosReactComponent {
    state = {
        messageText: ''
    };

    componentDidUpdate(prevProps) {
        if (this.props.history && !_.isEqual(prevProps.history, this.props.history)) {
            this.scrollToBottom();
        }
    }

    shouldShowContactAvatar(chat, i) {
        return (
            chat.item.message.from.split("/")[0] === this.props.jid &&
            (
                (
                    this.props.history[i + 1] && this.props.history[i + 1].item.message.from.split("/")[0] !== this.props.jid
                ) || !this.props.history[i + 1]
            )
        );
    };

    isFirstMessageOfGroup(chat, i) {
        return (
            i === 0 || (
                this.props.history[i - 1] &&
                this.props.history[i - 1].item.message.from.split("/")[0] !== chat.item.message.from.split("/")[0]
            )
        );
    };

    isLastMessageOfGroup(chat, i) {
        return (i === this.props.history.length - 1 || (this.props.history[i + 1] && this.props.history[i + 1].item.message.from.split("/")[0] !== chat.item.message.from.split("/")[0]));
    };

    onInputChange(ev) {
        this.setState({ messageText: ev.target.value });
    };

    onMessageSubmit(ev) {
        ev.preventDefault();
        if (this.state.messageText === '') {
            return;
        }
        let start = new Date();
        this.props.sendMessage(this.state.messageText, this.props.jid);
        this.props.getHistory(this.props.jid, start);
        this.setState({ messageText: '' });
        this.scrollToBottom();
    };

    scrollToBottom() {
        this.chatScroll.scrollTop = this.chatScroll.scrollHeight;
    };

    onInputKeyDown(ev) {
        if (ev.key === 'Enter') {
            this.onMessageSubmit(ev)
        }
    }

    findAvatar(vcard) {
        if (vcard) {
            if (vcard.records && vcard.records.length > 0) {
                for (let index = 0; index < vcard.records.length; index++) {
                    const record = vcard.records[index];
                    if (record.type === 'nickname') {
                        return this.getFoto(record.value, require('../assets/img/user.png'));
                    }
                }
            }
        }
        return require('../assets/img/user.png');
    }

    render() {
        const { classes, className, history, user, contact } = this.props;
        const { messageText } = this.state;

        return (
            <Paper elevation={3} className={classNames("flex flex-col", className)}>
                <FuseScrollbars
                    containerRef={(ref) => {
                        this.chatScroll = ref
                    }}
                    className="flex flex-1 flex-col overflow-y-auto"
                >
                    {!this.props.jid ?
                        (
                            <div className="flex flex-col flex-1 items-center justify-center p-24">
                                <Icon
                                    className="fad fa-comment-lines fad-lg"
                                    color="disabled"
                                    style={{ fontSize: '7em' }}
                                />
                                <Typography className="px-16 pb-24 mt-24 text-center" color="textSecondary">
                                    Selecione um contato para iniciar a conversa.
                                </Typography>
                            </div>
                        ) : (
                            <React.Fragment>
                                {history && history.length > 0 ?
                                    (
                                        <div className="flex flex-col pt-16 pl-40 pb-40">
                                            {history.map((chat, i) => {
                                                const from = chat.item.message.from.split("/")[0]
                                                return (
                                                    <div
                                                        key={chat.id}
                                                        className={classNames(
                                                            classes.messageRow,
                                                            { 'me': from === user.id },
                                                            { 'contact': from !== user.id },
                                                            { 'first-of-group': this.isFirstMessageOfGroup(chat, i) },
                                                            { 'last-of-group': this.isLastMessageOfGroup(chat, i) }
                                                        )}
                                                    >
                                                        {this.shouldShowContactAvatar(chat, i) && (
                                                            <Avatar
                                                                className={classes.avatar}
                                                                src={this.findAvatar(contact.vcard)}
                                                            />
                                                        )}
                                                        <div className={classes.bubble}>
                                                            <div className={classes.message}>{chat.item.message.body}</div>
                                                            <Typography className={classes.time} color="textSecondary">{moment(chat.item.delay.timestamp).format('DD/MM/YYYY, HH:mm:ss')}</Typography>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    ) : (
                                        <div className="flex flex-col flex-1">
                                            <div className="flex flex-col flex-1 items-center justify-center">
                                                <Icon
                                                    className="fad fa-comment-lines fad-lg"
                                                    color="disabled"
                                                    style={{ fontSize: '7em' }}
                                                />
                                            </div>
                                            <Typography className="px-16 pb-24 text-center" color="textSecondary">
                                                Inicie uma conversa digitando sua mensagem abaixo.
                                        </Typography>
                                        </div>
                                    )
                                }
                            </React.Fragment>
                        )
                    }
                </FuseScrollbars>
                <React.Fragment>
                    {this.props.jid ? (
                        <form onSubmit={this.onMessageSubmit} className={classNames(classes.bottom, "py-16 px-8")}>
                            <Paper className={classNames(classes.inputWrapper, "flex items-center relative")}>
                                <TextField
                                    autoFocus={false}
                                    id="message-input"
                                    className="flex-1"
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: {
                                            root: "flex flex-grow flex-no-shrink ml-16 mr-16 my-8",
                                            input: ""
                                        },
                                        placeholder: "Digite sua mensagem"
                                    }}
                                    InputLabelProps={{
                                        shrink: false,
                                        className: classes.bootstrapFormLabel
                                    }}
                                    onChange={this.onInputChange}
                                    onKeyDown={this.onInputKeyDown}
                                    value={messageText}
                                />
                                <IconButton className="absolute pin-r pin-t" type="submit">
                                    <Icon className="fal fa-paper-plane" color="action"></Icon>
                                </IconButton>
                            </Paper>
                        </form>
                    )
                        : null}
                </React.Fragment>
            </Paper>
        );
    }
}


export default withStyles(styles)(ChatView);
