import { boundClass } from '@anterostecnologia/anteros-react-core';
import { AppBar, ClickAwayListener, Icon, IconButton, Paper, Toolbar, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import keycode from 'keycode';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actions } from '../reducers/chatReducer';
import ChatContatosView from './ChatContatosView';
import ChatView from './ChatView';



const styles = theme => ({
    root: {
        width: 70,
        maxWidth: 70,
        minWidth: 70,
        [theme.breakpoints.down('md')]: {
            width: 0,
            maxWidth: 0,
            minWidth: 0
        }
    },
    panel: {
        position: 'absolute',
        width: 360,
        backgroundColor: theme.palette.background.paper,
        top: 0,
        height: '100%',
        minHeight: '100%',
        bottom: 0,
        right: 0,
        margin: 0,
        zIndex: 97,
        transform: 'translate3d(290px,0,0)',
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            transform: 'translate3d(360px,0,0)',
            boxShadow: 'none',
            '&.opened': {
                boxShadow: theme.shadows[5]
            }
        },
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.standard
        }),
        '&.opened': {
            transform: 'translateX(0)'
        }
    }
});

@boundClass
class ChatPanelView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            opened: false
        };
    }

    onChatAuth(credentials) {
        if (credentials) {
            console.log('Conectado ao chat');
        } else {
            alert('Falha ao conectar no chat');
        }
    }

    onSessionStarted(started) {
        let _this = this
        _this.props.user.chat.client.sendPresence()
        _this.props.user.chat.client.getRoster().then(roster => {
            if (roster.items && roster.items.length > 0) {
                let rosters_promisses = roster.items.map((item, index) => {
                    if (!item.hasOwnProperty('vcard')) {
                        return _this.props.user.chat.client.getVCard(item.jid).then(res => {
                            item.vcard = res;
                            return item;
                        }).catch(error => {
                            return item;
                        })
                    } else {
                        return item;
                    }
                })
                Promise.all(rosters_promisses).then(results => {
                    _this.props.setIsConnected(true)
                    _this.props.setRosters(results)
                    this.setState({
                        ...this.state,
                        update: Math.random()
                    });
                })
            }
        })
    }

    configureChat() {
        if (this.props.user.chat && this.props.user.chat.client) {
            this.props.user.chat.client.on('auth:success', this.onChatAuth);
            this.props.user.chat.client.on('auth:failed', this.onChatAuth);
            this.props.user.chat.client.on('session:started', this.onSessionStarted);
            this.props.user.chat.client.on('message', this.onMessage);
            this.props.user.chat.client.on('available', this.onAvailable);
            this.props.user.chat.client.on('unavailable', this.onUnavailable);
        }
    }

    connectChat() {
        if (this.props.user.chat && this.props.user.chat.client) {
            this.props.user.chat.client.connect();
        }
    }

    componentDidMount() {
        // this.props.getUserData();
        // this.props.getContacts();
        if (!this.props.isConnected) {
            this.configureChat();
            this.connectChat();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.state !== prevProps.state) {
            if (this.props.state) {
                document.addEventListener("keydown", this.handleDocumentKeyDown);
            }
            else {
                document.removeEventListener('keydown', this.handleDocumentKeyDown);
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleDocumentKeyDown);
    }

    handleDocumentKeyDown = event => {
        if (keycode(event) === 'esc') {
            this.closeChatPanel();
        }
    };


    closeChatPanel(event) {
        this.setState({ opened: false });
    }

    openChatPanel(event) {
        this.setState({ opened: !this.state.opened });
    }

    openChatPanelContact(event) {
        this.setState({ opened: true });
    }

    setChatTarget(jid) {
        this.props.setTargetJid(jid);
        if (!this.props.history[jid]) {
            let start = new Date();
            start.setDate(start.getDate() - 2);
            this.getHistory(jid, start);
        }
        this.markAsRead(jid);
    }

    sendMessage(msg, jid) {
        return this.props.user.chat.client.sendMessage({
            to: jid,
            body: msg
        })
    }

    getHistory(jid, start) {
        let _this = this
        let opts = {
            with: jid,
            start: start
        }
        _this.props.user.chat.client.searchHistory(opts).then((res) => {
            _this.props.addChatHistory(jid, res.results)
            this.setState({
                ...this.state,
                update: Math.random()
            });
        })
    }

    markAsRead(jid) {
        this.props.setRosterUnread(jid, false);
        this.setState({
            ...this.state,
            update: Math.random()
        });
    }

    onMessage(msg) {
        let start = new Date();
        let jid = msg.from.split("/")[0]
        if (jid !== this.props.user.chat.jid) {
            if (!this.props.history[jid]) {
                start.setDate(start.getDate() - 2);
            }
            this.getHistory(jid, start);
            if ((this.props.targetJid !== jid) || (!this.state.opened)) {
                this.props.setRosterUnread(jid, true);
            }
            this.setState({
                ...this.state,
                update: Math.random()
            });
        }
    }

    processPresence(presence, available) {
        let from = presence.from.split("/")[0];
        if (from !== this.props.user.chat.jid) {
            this.props.setRosterStatus(from, available ? 'online' : 'offline')
            this.setState({
                ...this.state,
                update: Math.random()
            });
        }
    }

    onAvailable(presence) {
        this.processPresence(presence, true);
    }

    onUnavailable(presence) {
        this.processPresence(presence, false);
    }

    render() {
        const { classes, state } = this.props;

        return (
            <div className={classes.root}>
                <ClickAwayListener onClickAway={() => this.state.opened && this.closeChatPanel()}>
                    <div className={classNames(classes.panel, { 'opened': this.state.opened }, "flex flex-col")}>
                        <AppBar position="static" elevation={1}>
                            <Toolbar className="pl-12 pr-8">
                                <div className="flex flex-1 items-center">
                                    {(!state) && (
                                        <React.Fragment>
                                            <IconButton color="inherit" onClick={this.openChatPanel}>
                                                <Icon className="fal fa-comment-lines">chat</Icon>
                                            </IconButton>
                                            <Typography className="ml-16 text-16" color="inherit">Chat</Typography>
                                        </React.Fragment>
                                    )}
                                </div>
                                <IconButton onClick={this.closeChatPanel} color="inherit">
                                    <Icon className="fal fa-times"></Icon>
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <Paper className="flex flex-1 flex-row min-h-px">
                            <ChatContatosView
                                openChatPanel={this.openChatPanelContact}
                                setChatTarget={this.setChatTarget}
                                isConnected={this.props.isConnected}
                                contacts={this.props.rosters}
                                targetJid={this.props.targetJid}
                                className="flex flex-no-shrink"
                            />
                            <ChatView
                                getHistory={this.getHistory}
                                isConnected={this.props.isConnected}
                                className="flex flex-1 z-10"
                                user={{ id: this.props.user.chat?this.props.user.chat.jid:''}}
                                contact={this.props.rosters[this.props.targetJid]}
                                sendMessage={this.sendMessage}
                                history={this.props.history[this.props.targetJid]}
                                jid={this.props.targetJid}
                            />
                        </Paper>
                    </div>
                </ClickAwayListener>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        rosters: state.chatReducer.rosters,
        isConnected: state.chatReducer.isConnected,
        targetJid: state.chatReducer.targetJid,
        history: state.chatReducer.history
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setIsConnected: isConnected => {
            dispatch(actions.setIsConnected(isConnected));
        },
        setRosters: rosters => {
            dispatch(actions.setRosters(rosters));
        },
        setTargetJid: jid => {
            dispatch(actions.setTargetJid(jid));
        },
        addChatHistory: (jid, history) => {
            dispatch(actions.addChatHistory(jid, history))
        },
        setRosterStatus: (jid, status) => {
            dispatch(actions.setRosterStatus(jid, status))
        },
        setRosterUnread: (jid, unread) => {
            dispatch(actions.setRosterUnread(jid, unread))
        }
    };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ChatPanelView));
