import {version} from './Version';
import { AnterosRemoteResource, GET } from '@anterostecnologia/anteros-react-api';
import {makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const TABPRECO_DESCONTO_PROGRESSIVO = 'tabelaPrecoDescontoProgressivo';
const tabelaPrecoDescontoProgressivoReducerDef = makeDefaultReduxObject(TABPRECO_DESCONTO_PROGRESSIVO);
const tabelaPrecoDescontoProgressivoConsultaReducerDef = makeDefaultReduxObject(TABPRECO_DESCONTO_PROGRESSIVO.toUpperCase()+"_SEARCH");

export class TabelaPrecoDescontoProgressivoResource extends AnterosRemoteResource {
    constructor(){
        super(TABPRECO_DESCONTO_PROGRESSIVO,`${version}/tabelaPrecoDescontoProgressivo/`);
    }

    buscarDescontoProgressivoTabelaPreco(
        idTabela,
        resourceName,
        page,
        size,
        user,
        fieldsToForceLazy
    ) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaDescProgTabelaPreco/${idTabela}?&page=${page}&size=${size}&fieldsToForceLazy=${fieldsToForceLazy}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
}

export const tabelaPrecoDescontoProgressivoReducer = createReducer(initialState,tabelaPrecoDescontoProgressivoReducerDef);
export const tabelaPrecoDescontoProgressivoConsultaReducer = createReducer(initialState,tabelaPrecoDescontoProgressivoConsultaReducerDef);