import React, { Component } from "react";
import LoginContainer from "../parts/LoginContainer";
import { autoBind } from "@anterostecnologia/anteros-react-core";
import { actions } from "../reducers/appReducer";
import { connect } from "react-redux";

class LoginView extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { update: undefined };
  }
  componentDidMount() {
    if (this.props.match.params.uuid) {
      this.props.setPathInitial(this.props.match.params.uuid);
      this.setState({ update: Math.random() });
    }
  }

  onLoginSuccess(user) {
    if (user.owner) this.props.history.push("/home/business/default");
    else this.props.history.push("/home/control/default");
  }

  render() {
    return <LoginContainer onLoginSuccess={this.onLoginSuccess} />;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authenticationReducer.user,
    authenticating: state.authenticationReducer.authenticating,
    authenticated: state.authenticationReducer.authenticated,
    serviceWorkerInitialized: state.swReducer.serviceWorkerInitialized,
    path: state.appReducer.path,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPathInitial: (path) => {
      dispatch(actions.setPathInitial(path));
    },
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
