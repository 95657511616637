import React, { Component, Fragment, lazy, Suspense } from "react";
import { AnterosSecurityRoute } from "@anterostecnologia/anteros-react-security";
import { AnterosButton } from "@anterostecnologia/anteros-react-buttons";
import { Switch } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import {
  autoBind,
  AnterosSweetAlert,
  processErrorMessage,
  boundClass,
} from "@anterostecnologia/anteros-react-core";
import { AnterosTruncateText } from "@anterostecnologia/anteros-react-label";
import { WithMainLayoutTemplate } from "@anterostecnologia/anteros-react-template";
import { connect } from "react-redux";
import {
  AnterosMegaMenu,
  AnterosMegaMenuItem,
  AnterosRibbonMenu,
  AnterosRibbonGroup,
  AnterosRibbonGroupItem,
  AnterosRibbonButton,
} from "@anterostecnologia/anteros-react-menu";
import { actions as actionsLogin } from "../reducers/authenticationReducer";
import { AnterosImage } from "@anterostecnologia/anteros-react-image";
import ChatPanelView from "./ChatPanelView";
import Modal from "react-modal";
import { OuterClick } from "react-outer-click";
import "react-command-palette/dist/themes/sublime.css";
import "react-command-palette/dist/themes/chrome.css";

const EmpresaView = lazy(() => import("./EmpresaView"));
const EquipamentoView = lazy(() => import("./EquipamentoView"));
const EquipamentoForm = lazy(() => import("../forms/EquipamentoForm"));
const EmpresaForm = lazy(() => import("../forms/EmpresaForm"));
const CondicaoPagamentoView = lazy(() => import("./CondicaoPagamentoView"));
const CondicaoPagamentoForm = lazy(() =>
  import("../forms/CondicaoPagamentoForm")
);
const FormaPagamentoView = lazy(() => import("./FormaPagamentoView"));
const FormaPagamentoForm = lazy(() => import("../forms/FormaPagamentoForm"));
const ModalidadeNegociacaoView = lazy(() =>
  import("./ModalidadeNegociacaoView")
);
const ModalidadeNegociacaoForm = lazy(() =>
  import("../forms/ModalidadeNegociacaoForm")
);
const CidadeView = lazy(() => import("./CidadeView"));
const CidadeForm = lazy(() => import("../forms/CidadeForm"));
const PesquisaComercialView = lazy(() => import("./PesquisaComercialView"));
const PesquisaComercialForm = lazy(() =>
  import("../forms/PesquisaComercialForm")
);
const SituacaoComercialView = lazy(() => import("./SituacaoComercialView"));
const SituacaoComercialForm = lazy(() =>
  import("../forms/SituacaoComercialForm")
);
const ColecaoView = lazy(() => import("./ColecaoView"));
const CatalogoView = lazy(() => import("./CatalogoView"));
const CatalogoForm = lazy(() => import("../forms/CatalogoForm"));
const CategoriaProdutoView = lazy(() => import("./CategoriaProdutoView"));
const CorView = lazy(() => import("./CorView"));
const CorForm = lazy(() => import("../forms/CorForm"));
const MarcaProdutoView = lazy(() => import("./MarcaProdutoView"));
const MarcaProdutoForm = lazy(() => import("../forms/MarcaProdutoForm"));
const MotivoNaoVendaView = lazy(() => import("./MotivoNaoVendaView"));
const MotivoNaoVendaForm = lazy(() => import("../forms/MotivoNaoVendaForm"));
const TipoLogradouroView = lazy(() => import("./TipoLogradouroView"));
const TipoLogradouroForm = lazy(() => import("../forms/TipoLogradouroForm"));
const StatusPedidoView = lazy(() => import("./StatusPedidoView"));
const StatusPedidoForm = lazy(() => import("../forms/StatusPedidoForm"));
const CategoriaProdutoForm = lazy(() =>
  import("../forms/CategoriaProdutoForm")
);
const ClassificacaoAtendimento = lazy(() =>
  import("./ClassificacaoAtendimentoView")
);
const ClassificacaoAtendimentoForm = lazy(() =>
  import("../forms/ClassificacaoAtendimentoForm")
);
const TamanhoProdutoView = lazy(() => import("./TamanhoProdutoView"));
const TamanhoProdutoForm = lazy(() => import("../forms/TamanhoProdutoForm"));
const TabelaPrecoView = lazy(() => import("./TabelaPrecoView"));
const TabelaPrecoForm = lazy(() => import("../forms/TabelaPrecoForm"));
const RegiaoComercialView = lazy(() => import("./RegiaoComercialView"));
const PessoaView = lazy(() => import("./PessoaView"));
const PessoaForm = lazy(() => import("../forms/PessoaForm"));
const RegiaoComercialForm = lazy(() => import("../forms/RegiaoComercialForm"));
const GrupoClienteView = lazy(() => import("./GrupoClienteView"));
const AutorizacaoVendaView = lazy(() => import("./AutorizacaoVendaView"));
const AutorizacaoVendaForm = lazy(() =>
  import("../forms/AutorizacaoVendaForm")
);
const ContratoComercialTableView = lazy(() =>
  import("./ContratoComercialTableView")
);
const ContratoRepresentanteComercialForm = lazy(() =>
  import("../forms/ContratoRepresentanteComercialForm")
);
const PlanejamentoRotaForm = lazy(() =>
  import("../forms/PlanejamentoRotaForm")
);
const MetaAtendimentoView = lazy(() => import("./MetaAtendimentoView"));
const MetaAtendimentoForm = lazy(() => import("../forms/MetaAtendimentoForm"));
const MetaVendaView = lazy(() => import("./MetaVendaView"));
const MetaVendaForm = lazy(() => import("../forms/MetaVendaForm"));
const ProdutoForm = lazy(() => import("../forms/ProdutoForm"));
const ColecaoForm = lazy(() => import("../forms/ColecaoForm"));
const GrupoClienteForm = lazy(() => import("../forms/GrupoClienteForm"));
const AtendimentoView = lazy(() => import("./AtendimentoView"));
const AtendimentoForm = lazy(() => import("../forms/AtendimentoForm"));
const TituloAReceberView = lazy(() => import("./TituloAReceberView"));
const TituloAReceberForm = lazy(() => import("../forms/TituloAReceberForm"));
const PedidosRecebidosView = lazy(() => import("./PedidosRecebidosView"));
const PedidoRecebidoForm = lazy(() => import("../forms/PedidoRecebidoForm"));
const RotaAtendimentoView = lazy(() => import("./RotaAtendimentoView"));
const PlanejamentoRotaView = lazy(() => import("./PlanejamentoRotaView"));
const RotaAtendimentoForm = lazy(() => import("../forms/RotaAtendimentoForm"));
const AcompanhamentoPedidosView = lazy(() =>
  import("./AcompanhamentoPedidosView")
);
const AcompanhamentoPedidosForm = lazy(() =>
  import("../forms/AcompanhamentoPedidosForm")
);
const OndeEstaMinhaEquipeView = lazy(() =>
  import("../containers/OndeEstaMinhaEquipeView")
);
const DashboardAtendimentoView = lazy(() =>
  import("./DashboardAtendimentoView")
);
const DashboardVendasView = lazy(() =>
  import("../containers/DashboardVendasView")
);
const CheckListView = lazy(() => import("./CheckListView"));
const CheckListForm = lazy(() => import("../forms/CheckListForm"));
const ModeloEmailView = lazy(() => import("./ModeloEmailView"));
const ModeloEmailForm = lazy(() => import("../forms/ModeloEmailForm"));
const FilaEmailView = lazy(() => import("./FilaEmailView"));
const FilaGeocodificacaoView = lazy(() => import("./FilaGeocodificacaoView"));
const HomologacaoView = lazy(() => import("./Homologacao"));
const GerenciadorArquivos = lazy(() => import("./GerenciadorArquivos"));
const PedidoView = lazy(() => import("./PedidoView"));
const PedidoForm = lazy(() => import("../forms/PedidoForm"));
const AcompanhamentoPesquisa = lazy(() => import("./AcompanhamentoPesquisa"));
const CuboDadosResumoFaturamentoView = lazy(() =>
  import("../reports/CuboDadosResumoFaturamentoView")
);
const CuboDadosResumoPedidosView = lazy(() =>
  import("../reports/CuboDadosResumoPedidosView")
);
const CuboDadosResumoAtendimentosView = lazy(() =>
  import("../reports/CuboDadosResumoAtendimentosView")
);
const PeriodoEntregaView = lazy(() => import("./PeriodoEntregaView"));
const PeriodoEntregaForm = lazy(() => import("../forms/PeriodoEntregaForm"));
const PerfisView = lazy(() => import("./PerfisView"));
const UsuariosView = lazy(() => import("./UsuariosView"));
const PerfilForm = lazy(() => import("../forms/PerfisForm"));
const UsuarioForm = lazy(() => import("../forms/UsuariosForm"));
const ResumoPedidosView = lazy(() => import("./ResumoPedidosView"));
const ExcecaoFiscalView = lazy(() => import("./ExcecaoFiscalView"));
const RegraTributacaoView = lazy(() => import("./RegraTributacaoView"));
const ExcecaoFiscalForm = lazy(() => import("../forms/ExcecaoFiscalForm"));
const RegraTributacaoForm = lazy(() =>
  import("../forms/RegraTributacaoForm")
);
const PoliticaVendaView = lazy(() => import("./PoliticaVendaView"));
const PoliticaVendaForm = lazy(() => import("../forms/PoliticaVendaForm"));
const FunilVendasView = lazy(() => import("../containers/FunilVendasView"));
const FunilVendasForm = lazy(() => import("../forms/FunilVendasForm"));
const OportunidadesView = lazy(() => import("../containers/OportunidadesView"));
const OportunidadesForm = lazy(() => import("../forms/OportunidadesForm"));
const AnalisesView = lazy(() => import("../containers/AnalisesView"));
const TipoLigacaoView = lazy(() => import("../containers/TipoLigacaoView"));
const TipoLigacaoForm = lazy(() => import("../forms/TipoLigacaoForm"));
const TipoAtividadeView = lazy(() => import("../containers/TipoAtividadeView"));
const TipoAtividadeForm = lazy(() => import("../forms/TipoAtividadeForm"));
const TipoCompromissoView = lazy(() =>
  import("../containers/TipoCompromissoView")
);
const TipoCompromissoForm = lazy(() => import("../forms/TipoCompromissoForm"));
const CanalAtendimentoView = lazy(() =>
  import("../containers/CanalAtendimentoView")
);
const CanalAtendimentoForm = lazy(() =>
  import("../forms/CanalAtendimentoForm")
);
const TagView = lazy(() => import("./TagView"));
const TagForm = lazy(() => import("../forms/TagForm"));
const HomeView = lazy(() => import("./HomeView"));
const ProdutoView = lazy(() => import("./ProdutoView"));
var pjson = require("../../package.json");

const CADASTRO = "CADASTRO";
const CRM = "CRM";
const ATENDIMENTO = "ATENDIMENTO";
const VENDA = "VENDA";
const ANALISE = "ANÁLISE";
const RELATORIO = "RELATÓRIO";
const FINANCEIRO = "FINANCEIRO";
const META = "META";

const getColorByCategory = (category) => {
  let color = "white";
  if (category === CADASTRO) {
    color = { backgroundColor: "#B5D38E", color: "white" };
  } else if (category === CRM) {
    color = { backgroundColor: "#FFD16B", color: "white" };
  } else if (category === ATENDIMENTO) {
    color = { backgroundColor: "#FA8072", color: "white" };
  } else if (category === VENDA) {
    color = { backgroundColor: "#415AAB", color: "white" };
  } else if (category === MetaVendaForm) {
    color = { backgroundColor: "#389FE4", color: "white" };
  } else if (category === ANALISE) {
    color = { backgroundColor: "#2E9381", color: "white" };
  } else if (category === RELATORIO) {
    color = { backgroundColor: "#98335B", color: "white" };
  } else if (category === FINANCEIRO) {
    color = { backgroundColor: "#C4414F", color: "white" };
  } else if (category === META) {
    color = { backgroundColor: "#7145A8", color: "white" };
  }
  return color;
};

const routes = [
  {
    path: "/home/business/default",
    component: HomeView,
    title: "Home",
    showOnCommands: false,
  },
  {
    path: "/home/business/analytics",
    component: AnalisesView,
    title: "Análises",
    showOnCommands: true,
    category: ANALISE,
  },
  {
    path: "/home/business/cadastros/empresa/consulta",
    component: EmpresaView,
    title: "Empresas",
    showOnCommands: false,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/empresa/adicionar",
    component: EmpresaForm,
    title: "Empresas",
    showOnCommands: false,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/empresa/editar",
    component: EmpresaForm,
    title: "Empresas",
    showOnCommands: false,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/crm/funil/consulta",
    component: FunilVendasView,
    title: "Funil de vendas",
    showOnCommands: true,
    category: CRM,
  },
  {
    path: "/home/business/cadastros/crm/funil/adicionar",
    component: FunilVendasForm,
    title: "Funil de vendas",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/crm/funil/editar",
    component: FunilVendasForm,
    title: "Funil de vendas",
    showOnCommands: false,
  },
  {
    path: "/home/business/crm/oportunidades/consulta",
    component: OportunidadesView,
    title: "Oportunidades",
    showOnCommands: true,
    category: CRM,
  },
  {
    path: "/home/business/crm/oportunidades/adicionar",
    component: OportunidadesForm,
    title: "Oportunidades",
    showOnCommands: false,
  },
  {
    path: "/home/business/crm/oportunidades/editar",
    component: OportunidadesForm,
    title: "Oportunidades",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/equipamento/consulta",
    component: EquipamentoView,
    title: "Equipamentos",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/equipamento/adicionar",
    component: EquipamentoForm,
    title: "Equipamentos",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/equipamento/editar",
    component: EquipamentoForm,
    title: "Equipamentos",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/condicaopagamento/consulta",
    component: CondicaoPagamentoView,
    title: "Condição de pagamento",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/condicaopagamento/adicionar",
    component: CondicaoPagamentoForm,
    title: "Condição de pagamento",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/condicaopagamento/editar",
    component: CondicaoPagamentoForm,
    title: "Condição de pagamento",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/excecaofiscal/consulta",
    component: ExcecaoFiscalView,
    title: "Exceção fiscal",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/excecaofiscal/adicionar",
    component: ExcecaoFiscalForm,
    title: "Exceção fiscal",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/excecaofiscal/editar",
    component: ExcecaoFiscalForm,
    title: "Exceção fiscal",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/regratributacao/consulta",
    component: RegraTributacaoView,
    title: "Regra de tributação",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/regratributacao/adicionar",
    component: RegraTributacaoForm,
    title: "Regra de tributação",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/regratributacao/editar",
    component: RegraTributacaoForm,
    title: "Regra de tributação",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/crm/tipoligacao/consulta",
    component: TipoLigacaoView,
    title: "Tipo de ligação",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/crm/tipoligacao/adicionar",
    component: TipoLigacaoForm,
    title: "Tipo de ligação",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/crm/tipoligacao/editar",
    component: TipoLigacaoForm,
    title: "Tipo de ligação",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/crm/tipoatividade/consulta",
    component: TipoAtividadeView,
    title: "Tipo de atividade",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/crm/tipoatividade/adicionar",
    component: TipoAtividadeForm,
    title: "Tipo de atividade",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/crm/tipoatividade/editar",
    component: TipoAtividadeForm,
    title: "Tipo de atividade",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/crm/tipocompromisso/consulta",
    component: TipoCompromissoView,
    title: "Tipo de compromisso",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/crm/tipocompromisso/adicionar",
    component: TipoCompromissoForm,
    title: "Tipo de compromisso",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/crm/tipocompromisso/editar",
    component: TipoCompromissoForm,
    title: "Tipo de compromisso",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/crm/tag/consulta",
    component: TagView,
    title: "Tag",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/crm/tag/adicionar",
    component: TagForm,
    title: "Tag",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/crm/tag/editar",
    component: TagForm,
    title: "Tag",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/formapagamento/consulta",
    component: FormaPagamentoView,
    title: "Home",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/formapagamento/adicionar",
    component: FormaPagamentoForm,
    title: "Home",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/formapagamento/editar",
    component: FormaPagamentoForm,
    title: "Home",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/modalidadenegociacao/consulta",
    component: ModalidadeNegociacaoView,
    title: "Modalidade de negociação",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/modalidadenegociacao/adicionar",
    component: ModalidadeNegociacaoForm,
    title: "Modalidade de negociação",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/modalidadenegociacao/editar",
    component: ModalidadeNegociacaoForm,
    title: "Modalidade de negociação",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/cidade/consulta",
    component: CidadeView,
    title: "Cidades",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/canal/adicionar",
    component: CanalAtendimentoForm,
    title: "Canal Atendimento",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/canal/editar",
    component: CanalAtendimentoForm,
    title: "Canal Atendimento",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/canal/consulta",
    component: CanalAtendimentoView,
    title: "Canal Atendimento",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/cidade/adicionar",
    component: CidadeForm,
    title: "Cidades",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/cidade/editar",
    component: CidadeForm,
    title: "Cidades",
    showOnCommands: false,
  },
  {
    path: "/home/business/pesquisa/pesquisacomercial/consulta",
    component: PesquisaComercialView,
    title: "Pesquisa comercial",
    showOnCommands: true,
    category: VENDA,
  },
  {
    path: "/home/business/pesquisa/pesquisacomercial/adicionar",
    component: PesquisaComercialForm,
    title: "Pesquisa comercial",
    showOnCommands: false,
  },
  {
    path: "/home/business/pesquisa/pesquisacomercial/editar",
    component: PesquisaComercialForm,
    title: "Pesquisa comercial",
    showOnCommands: false,
  },
  {
    path: "/home/business/pesquisa/checklist/consulta",
    component: CheckListView,
    title: "Checklist",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/pesquisa/checklist/adicionar",
    component: CheckListForm,
    title: "Checklist",
    showOnCommands: false,
  },
  {
    path: "/home/business/pesquisa/checklist/editar",
    component: CheckListForm,
    title: "Checklist",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/situacaocomercial/consulta",
    component: SituacaoComercialView,
    title: "Situação comercial",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/situacaocomercial/adicionar",
    component: SituacaoComercialForm,
    title: "Situação comercial",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/situacaocomercial/editar",
    component: SituacaoComercialForm,
    title: "Situação comercial",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/colecao/consulta",
    component: ColecaoView,
    title: "Coleção",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/arquivos/editar",
    component: GerenciadorArquivos,
    title: "Arquivos",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/colecao/adicionar",
    component: ColecaoForm,
    title: "Coleção",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/colecao/editar",
    component: ColecaoForm,
    title: "Coleção",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/catalogo/consulta",
    component: CatalogoView,
    title: "Catálogo",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/catalogo/adicionar",
    component: CatalogoForm,
    title: "Catálogo",
    showOnCommands: false,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/catalogo/editar",
    component: CatalogoForm,
    title: "Catálogo",
    showOnCommands: false,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/categoriaproduto/consulta",
    component: CategoriaProdutoView,
    title: "Categoria produtos",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/categoriaproduto/adicionar",
    component: CategoriaProdutoForm,
    title: "Categoria produtos",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/categoriaproduto/editar",
    component: CategoriaProdutoForm,
    title: "Categoria produtos",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/cor/consulta",
    component: CorView,
    title: "Cor",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/cor/adicionar",
    component: CorForm,
    title: "Cor",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/cor/editar",
    component: CorForm,
    title: "Cor",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/marca/consulta",
    component: MarcaProdutoView,
    title: "Marca",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/marca/adicionar",
    component: MarcaProdutoForm,
    title: "Marca",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/marca/editar",
    component: MarcaProdutoForm,
    title: "Marca",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/motivonaovenda/consulta",
    component: MotivoNaoVendaView,
    title: "Motivo não venda",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/motivonaovenda/adicionar",
    component: MotivoNaoVendaForm,
    title: "Motivo não venda",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/motivonaovenda/editar",
    component: MotivoNaoVendaForm,
    title: "Motivo não venda",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/produto/consulta",
    component: ProdutoView,
    title: "Produto",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/produto/adicionar",
    component: ProdutoForm,
    title: "Produto",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/produto/editar",
    component: ProdutoForm,
    title: "Home",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/tipologradouro/consulta",
    component: TipoLogradouroView,
    title: "Tipo logradouro",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/tipologradouro/adicionar",
    component: TipoLogradouroForm,
    title: "Tipo logradouro",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/tipologradouro/editar",
    component: TipoLogradouroForm,
    title: "Tipo logradouro",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/statuspedido/consulta",
    component: StatusPedidoView,
    title: "Status pedido",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/statuspedido/adicionar",
    component: StatusPedidoForm,
    title: "Status pedido",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/statuspedido/editar",
    component: StatusPedidoForm,
    title: "Status pedido",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/classifatendimento/consulta",
    component: ClassificacaoAtendimento,
    title: "Classificação atendimento",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/classifatendimento/adicionar",
    component: ClassificacaoAtendimentoForm,
    title: "Classificação atendimento",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/classifatendimento/editar",
    component: ClassificacaoAtendimentoForm,
    title: "Classificação atendimento",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/tamanho/consulta",
    component: TamanhoProdutoView,
    title: "Tamanho",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/tamanho/adicionar",
    component: TamanhoProdutoForm,
    title: "Tamanho",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/tamanho/editar",
    component: TamanhoProdutoForm,
    title: "Tamanho",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/tabelapreco/consulta",
    component: TabelaPrecoView,
    title: "Tabela preços",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/tabelapreco/adicionar",
    component: TabelaPrecoForm,
    title: "Tabela preço",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/tabelapreco/editar",
    component: TabelaPrecoForm,
    title: "Tabela preço",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/pessoa/consulta",
    component: PessoaView,
    title: "Pessoa",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/pessoa/adicionar",
    component: PessoaForm,
    title: "Pessoa",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/pessoa/editar",
    component: PessoaForm,
    title: "Pessoa",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/regiaocomercial/consulta",
    component: RegiaoComercialView,
    title: "Região comercial",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/regiaocomercial/adicionar",
    component: RegiaoComercialForm,
    title: "Região comercial",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/regiaocomercial/editar",
    component: RegiaoComercialForm,
    title: "Região comercial",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/grupocliente/consulta",
    component: GrupoClienteView,
    title: "Grupo clientes",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/grupocliente/adicionar",
    component: GrupoClienteForm,
    title: "Grupo clientes",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/grupocliente/editar",
    component: GrupoClienteForm,
    title: "Grupo clientes",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/autorizacaovenda/consulta",
    component: AutorizacaoVendaView,
    title: "Autorização venda",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/autorizacaovenda/adicionar",
    component: AutorizacaoVendaForm,
    title: "Autorização venda",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/autorizacaovenda/editar",
    component: AutorizacaoVendaForm,
    title: "Autorização venda",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/metaatendimento/consulta",
    component: MetaAtendimentoView,
    title: "Meta atendimento",
    showOnCommands: true,
    category: META,
  },
  {
    path: "/home/business/cadastros/metaatendimento/adicionar",
    component: MetaAtendimentoForm,
    title: "Meta atendimento",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/metaatendimento/editar",
    component: MetaAtendimentoForm,
    title: "Meta atendimentos",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/metavenda/consulta",
    component: MetaVendaView,
    title: "Meta venda",
    showOnCommands: true,
    category: META,
  },
  {
    path: "/home/business/cadastros/metavenda/adicionar",
    component: MetaVendaForm,
    title: "Meta venda",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/metavenda/editar",
    component: MetaVendaForm,
    title: "Meta venda",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/contratorepresentante/consulta",
    component: ContratoComercialTableView,
    title: "Contrato representante",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/contratorepresentante/adicionar",
    component: ContratoRepresentanteComercialForm,
    title: "Contrato representante",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/contratorepresentante/editar",
    component: ContratoRepresentanteComercialForm,
    title: "Contrato representante",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/atendimento/consulta",
    component: AtendimentoView,
    title: "Atendimento",
    showOnCommands: true,
    category: VENDA,
  },
  {
    path: "/home/business/cadastros/atendimento/adicionar",
    component: AtendimentoForm,
    title: "Atendimento",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/atendimento/editar",
    component: AtendimentoForm,
    title: "Atendimento",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/resumopedidos/consulta",
    component: ResumoPedidosView,
    title: "Resumo pedidos",
    showOnCommands: true,
    category: VENDA,
  },
  {
    path: "/home/business/dashboard/atendimento",
    component: DashboardAtendimentoView,
    title: "Dashboard atendimento",
    showOnCommands: true,
    category: ANALISE,
  },
  {
    path: "/home/business/dashboard/vendas",
    component: DashboardVendasView,
    title: "Dashboard vendas",
    showOnCommands: true,
    category: ANALISE,
  },
  {
    path: "/home/business/cadastros/tituloreceber/consulta",
    component: TituloAReceberView,
    title: "Titulos a receber",
    showOnCommands: true,
    category: FINANCEIRO,
  },
  {
    path: "/home/business/cadastros/tituloreceber/adicionar",
    component: TituloAReceberForm,
    title: "Titulos a receber",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/tituloreceber/editar",
    component: TituloAReceberForm,
    title: "Titulos a receber",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/pedidorecebido/consulta",
    component: PedidosRecebidosView,
    title: "Pedido recebido",
    showOnCommands: true,
    category: VENDA,
  },
  {
    path: "/home/business/cadastros/pedidorecebido/adicionar",
    component: PedidoRecebidoForm,
    title: "Pedido recebido",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/pedidorecebido/editar",
    component: PedidoRecebidoForm,
    title: "Pedido recebido",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/rota/consulta",
    component: RotaAtendimentoView,
    title: "Rota atendimento",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/rota/adicionar",
    component: RotaAtendimentoForm,
    title: "Rota atendimento",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/rota/editar",
    component: RotaAtendimentoForm,
    title: "Rota atendimento",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/planejamentorota/consulta",
    component: PlanejamentoRotaView,
    title: "Planejamento rota",
    showOnCommands: true,
    category: VENDA,
  },
  {
    path: "/home/business/cadastros/planejamentorota/adicionar",
    component: PlanejamentoRotaForm,
    title: "Planejamento rota",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/planejamentorota/editar",
    component: PlanejamentoRotaForm,
    title: "Planejamento rota",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/acompanhamentopedido/consulta",
    component: AcompanhamentoPedidosView,
    title: "Acompanhamento pedidos",
    showOnCommands: true,
    category: VENDA,
  },
  {
    path: "/home/business/cadastros/acompanhamentopedido/adicionar",
    component: AcompanhamentoPedidosForm,
    title: "Acompanhamento pedidos",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/acompanhamentopedido/editar",
    component: AcompanhamentoPedidosForm,
    title: "Acompanhamento pedidos",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/ondeestaminhaequipe/consulta",
    component: OndeEstaMinhaEquipeView,
    title: "Onde está minha equipe?",
    showOnCommands: true,
    category: VENDA,
  },
  {
    path: "/home/business/cadastros/modeloemail/consulta",
    component: ModeloEmailView,
    title: "Modelo de email",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/modeloemail/adicionar",
    component: ModeloEmailForm,
    title: "Modelo de email",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/modeloemail/editar",
    component: ModeloEmailForm,
    title: "Modelo de email",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/filaemail/consulta",
    component: FilaEmailView,
    title: "Fila de email",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/filageocodificacao/consulta",
    component: FilaGeocodificacaoView,
    title: "Fila geocodificação",
    showOnCommands: true,
  },
  {
    path: "/home/business/cadastros/homologacao/consulta",
    component: HomologacaoView,
    title: "Homologação endereço",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/pesquisa/acompanhamento/consulta",
    component: AcompanhamentoPesquisa,
    title: "Acompanhamento pesquisa",
    showOnCommands: true,
    category: VENDA,
  },
  {
    path: "/home/business/reports/cubopedidos",
    component: CuboDadosResumoPedidosView,
    title: "Cubo de dados pedido",
    showOnCommands: true,
    category: RELATORIO,
  },
  {
    path: "/home/business/reports/cuboatendimentos",
    component: CuboDadosResumoAtendimentosView,
    title: "Cubo de dados atendimento",
    showOnCommands: true,
    category: RELATORIO,
  },
  {
    path: "/home/business/reports/cubofaturamento",
    component: CuboDadosResumoFaturamentoView,
    title: "Cubo de dados faturamento",
    showOnCommands: true,
    category: RELATORIO,
  },
  {
    path: "/home/business/cadastros/pedido/consulta",
    component: PedidoView,
    title: "Pedido",
    showOnCommands: true,
    category: VENDA,
  },
  {
    path: "/home/business/cadastros/pedido/editar",
    component: PedidoForm,
    title: "Pedido",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/periodoentrega/consulta",
    component: PeriodoEntregaView,
    title: "Periodo de entrega",
    showOnCommands: true,
    category: VENDA,
  },
  {
    path: "/home/business/cadastros/periodoentrega/adicionar",
    component: PeriodoEntregaForm,
    title: "Período de entrega",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/periodoentrega/editar",
    component: PeriodoEntregaForm,
    title: "Período de entrega",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/perfil/consulta",
    component: PerfisView,
    title: "Perfil usuário",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/perfil/adicionar",
    component: PerfilForm,
    title: "Perfil usuário",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/perfil/editar",
    component: PerfilForm,
    title: "Perfil usuário",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/usuario/consulta",
    component: UsuariosView,
    title: "Usuário",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/usuario/adicionar",
    component: UsuarioForm,
    title: "Usuários",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/usuario/editar",
    component: UsuarioForm,
    title: "Usuários",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/politicavenda/consulta",
    component: PoliticaVendaView,
    title: "Politica de Venda",
    showOnCommands: true,
    category: CADASTRO,
  },
  {
    path: "/home/business/cadastros/politicavenda/adicionar",
    component: PoliticaVendaForm,
    title: "Politica de Venda",
    showOnCommands: false,
  },
  {
    path: "/home/business/cadastros/politicavenda/editar",
    component: PoliticaVendaForm,
    title: "Politica de Venda",
    showOnCommands: false,
  },
];

@WithMainLayoutTemplate({
  logo: require("../assets/img/logo_interno.svg"),
  logoSmall: require("../assets/img/logo_interno_pequeno.svg"),
  enableSidebarBackgroundImage: false,
  defaultAvatar: require("../assets/img/user.png"),
  showInputSearch: false,
  showUserBlock: false,
  toolbarIconColor: "white",
  quickLinkHeaderColor: "rgb(79, 191, 202)",
})
class LayoutBusiness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      isOpenMenu: false,
    };
    autoBind(this);
    this.cleanCache = this.cleanCache.bind(this);
  }

  componentDidMount() {
    if (!this.props.user.company) {
      this.props.history.push("/home/business/cadastros/empresa/consulta");
    }
  }

  isMainMenuVisible() {
    return this.props.authenticated && this.props.user.company !== undefined;
  }

  isSideBarVisible() {
    return this.props.authenticated && this.props.user.company !== undefined;
  }

  isMenuItemAccessible(item) {
    if (
      this.props.user &&
      this.props.user.profile.login.toLowerCase() === "admin"
    ) {
      return true;
    }
    let actions = [];
    if (
      this.props.user.profile.profile &&
      this.props.user.profile.profile.simpleActions
    ) {
      actions = actions.concat(this.props.user.profile.profile.simpleActions);
    }
    if (this.props.user.profile.simpleActions) {
      actions = actions.concat(this.props.user.profile.simpleActions);
    }
    let found = false;
    actions.forEach((action) => {
      if (
        action.action === item &&
        this.props.user.company &&
        action.company === this.props.user.company.id + ""
      ) {
        found = true;
      }
    });

    return found;
  }

  getRightSidebar() {
    return <ChatPanelView user={this.props.user} />;
  }

  getUserActions() {
    return null;
  }

  getQuickLinks() {
    return;
  }

  getCommands() {
    let result = [];
    routes.forEach((item, index) => {
      if (item.showOnCommands === true) {
        result.push({
          id: index,
          command: () => {
            this.props.history.push(item.path);
          },
          category: item.category,
          color: getColorByCategory(item.category),
          name: item.title,
        });
      }
    });
    console.log(result);
    return result;
  }

  onOpenQuickLinkMenu(left, top) {
    this.setState({
      ...this.state,
      left,
      top,
      isOpenMenu: !this.state.isOpenMenu,
    });
  }

  onClick(route) {
    this.props.history.push(route);
    this.props.ownerTemplate.setState({
      ...this.props.ownerTemplate.state,
      isOpenMenu: false,
    });
  }

  getSwitch() {
    return (
      <Switch>
        <Suspense
          fallback={
            <div
              style={{
                width: "100%",
                height: "calc(100vh - 100px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TailSpin />
            </div>
          }
        >
          {routes.map((item) => {
            return (
              <AnterosSecurityRoute
                allowAccess={this.props.authenticated}
                path={item.path}
                component={item.component}
              />
            );
          })}
        </Suspense>
      </Switch>
    );
  }

  logout = () => {
    let _this = this;
    AnterosSweetAlert({
      title: "Deseja sair da aplicação ?",
      text: "",
      type: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      focusCancel: false,
    })
      .then(function() {
        _this.cleanCache();
      })
      .catch((error) => {
        AnterosSweetAlert(processErrorMessage(error));
      });
  };

  swapCompany = () => {
    let _this = this;
    AnterosSweetAlert({
      title: "Deseja trocar de empresa ?",
      text: "",
      type: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      focusCancel: false,
    })
      .then(function() {
        _this.props.handleSwapCompany();
        _this.props.history.push("/");
      })
      .catch((error) => {
        AnterosSweetAlert(processErrorMessage(error));
      });
  };

  getMainMenu() {
    return MainMenu;
  }

  connectSupport() {
    //
  }

  onCloseModal() {
    this.props.ownerTemplate.setState({
      ...this.props.state,
      isOpenMenu: false,
    });
  }

  getToolbarCenterContent() {
    return "";
  }

  getFoto(company){
    if (company && company.logoMarca) {
      if (this.isBase64(company.logoMarca)) {
        if (this.isUrl(atob(company.logoMarca))) {
          return atob(company.logoMarca);
        } else {
          return "data:image;base64," + company.logoMarca;
        }
      } else {
        return company.logoMarca;
      }
    } else {
      return require("../assets/img/user.png");
    }
  }

  cleanCache() {
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      let href = window.location.href.split("/");
      let homePage = `${href[0]}//${href[2]}`;
      this.props.exit();
      window.location.replace(homePage);
    }
  }

  getToolbarEndContent() {
    return (
      <Fragment>
        {this.props.user.company ? (
          <Fragment>
            <div
              style={{
                color: "white",
                backgroundColor: "rgb(18, 51, 219,0.0)",
                fontSize: "26px",
                height: "60px",
                textAlign: "right",
                borderRadius: "26px",
                paddingRight: "30px",
                width: "auto",
              }}
            >
              {this.props.user && this.props.user.company && this.props.user.company.logoMarca?<AnterosImage
              height="56px"
              styleContainer={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              style={{ border:"1px solid yellow", borderRadius: "4px" }}
              src={this.getFoto(this.props.user.company)}
            />:<AnterosTruncateText
            text={
              this.props.user.company
                ? this.props.user.company.nome
                : this.props.user.company.razaoSocial
            }
          />}              
            </div>
            <AnterosButton
              medium
              circle
              icon="fal fa-retweet"
              iconSize="24px"
              hint="Trocar empresa"
              onButtonClick={this.swapCompany}
              color="white"
              hintPosition="down"
            />
          </Fragment>
        ) : null}
        <AnterosButton
          medium
          circle
          icon="fal fa-headset"
          iconSize="24px"
          hint="Suporte técnico"
          onButtonClick={this.connectSupport}
          color="white"
          hintPosition="down"
        />
        <AnterosButton
          medium
          circle
          icon="fas fa-badge-check"
          iconSize="24px"
          hint="Atualizar versão"
          onButtonClick={this.cleanCache}
          color={this.props.serviceWorkerUpdated ? "yellow" : "white"}
          hintPosition="down"
        />
        <AnterosButton
          medium
          circle
          icon="fal fa-expand"
          iconSize="24px"
          hint="Tela cheia"
          onButtonClick={this.toggleScreenFull}
          color="white"
          hintPosition="down"
        />
        <AnterosButton
          medium
          circle
          icon="fal fa-sign-out-alt"
          iconSize="24px"
          hint="Sair do sistema"
          onButtonClick={this.logout}
          color="white"
          hintPosition="down"
        />
      </Fragment>
    );
  }

  render() {
    return (
      <RibbonMenu
        onClick={this.onClick}
        top={this.props.state.top + 44}
        left={this.props.state.left}
        isOpen={this.props.state.isOpenMenu}
        onRequestClose={this.onCloseModal}
      />
    );
  }
}

@boundClass
class MainMenu extends Component {
  menuItemConfigInicial() {
    return (
      <AnterosMegaMenuItem
        id="mniConfiInicial"
        caption="Configuração inicial"
        icon="fad fa-check-double"
      >
        <AnterosMegaMenuItem
          id="mniPerfis"
          caption="Perfis"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-user-circle"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniPerfis")}
          route="/home/business/cadastros/perfil/consulta"
        />
        <AnterosMegaMenuItem
          id="mniUsuarios"
          caption="Usuarios"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-users"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniUsuarios")}
          route="/home/business/cadastros/usuario/consulta"
        />
        <AnterosMegaMenuItem
          id="mniEquipamento"
          caption="Equipamento"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-mobile"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniPerfis")}
          route="/home/business/cadastros/equipamento/consulta"
        />
        <AnterosMegaMenuItem
          id="mniTabelaPrecos"
          caption="Tabela de Preços"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-file-invoice-dollar"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniTabelaPrecos")}
          route="/home/business/cadastros/tabelapreco/consulta"
        />
        <AnterosMegaMenuItem
          id="mniRegioes"
          caption="Regiões"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-globe-americas"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniPerfis")}
          route="/home/business/cadastros/regiaocomercial/consulta"
        />
        <AnterosMegaMenuItem
          id="mniContratos"
          caption="Contratos"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-file-signature"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniTabelaPrecos")}
          route="/home/business/cadastros/contratorepresentante/consulta"
        />
        <AnterosMegaMenuItem
          id="mniCondicaoPagamento"
          caption="Condição pagamento"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-calendar-day white"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniCondicaoPagamento")}
          route="/home/business/cadastros/condicaopagamento/consulta"
        />
        <AnterosMegaMenuItem
          id="mniFormaPagamento"
          caption="Forma pagamento"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fab fa-cc-paypal white"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniFormaPagamento")}
          route="/home/business/cadastros/formapagamento/consulta"
        />
        <AnterosMegaMenuItem
          id="mniModalidadeNegociacao"
          caption="Modalidade negociação"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-badge-dollar white"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniModalidadeNegociacao")}
          route="/home/business/cadastros/modalidadenegociacao/consulta"
        />
      </AnterosMegaMenuItem>
    );
  }

  menuItemDashboard() {
    return (
      <AnterosMegaMenuItem
        id="mniDashboard"
        caption="Dashboard"
        icon="fad fa-tachometer-alt"
      >
        <AnterosMegaMenuItem
          id="mniDashboardAtendimento"
          caption="Dashboard de atendimentos"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-chalkboard-teacher white"
          route="/home/business/dashboard/atendimento"
          visible={this.props.isMenuItemAccessible("mniDashboard")}
        />
        <AnterosMegaMenuItem
          id="mniDashboardVendas"
          caption="Dashboard de vendas"
          style={{ color: "rgb(9, 112, 240)" }}
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-chart-area"
          route="/home/business/dashboard/vendas"
          visible={this.props.isMenuItemAccessible("mniDashboardVendas")}
        />
      </AnterosMegaMenuItem>
    );
  }

  menuItemAnalytics() {
    return (
      <AnterosMegaMenuItem
        id="mniAnalytics"
        caption="Análises"
        icon="fad fa-analytics"
        route="/home/business/analytics"
        onSelectMenuItem={this.props.onSelectMenuItem}
        visible={this.props.isMenuItemAccessible("mniAnalytics")}
      ></AnterosMegaMenuItem>
    );
  }

  menuItemReports() {
    return (
      <AnterosMegaMenuItem
        id="mniReports"
        caption="Relatórios"
        icon="fad fa-file-chart-line"
      >
        <AnterosMegaMenuItem
          id="mniCuboPedidos"
          caption="Cubo pedidos"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-cube"
          route="/home/business/reports/cubopedidos"
          visible={this.props.isMenuItemAccessible("mniReports")}
        />
        <AnterosMegaMenuItem
          id="mniCuboAtendimentos"
          caption="Cubo atendimentos"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-cube"
          route="/home/business/reports/cuboatendimentos"
          visible={this.props.isMenuItemAccessible("mniReports")}
        />
        <AnterosMegaMenuItem
          id="mniCuboFaturamento"
          caption="Cubo faturamento"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-cube"
          route="/home/business/reports/cubofaturamento"
          visible={this.props.isMenuItemAccessible("mniReports")}
        />
      </AnterosMegaMenuItem>
    );
  }

  menuItemCadastrosComerciais() {
    return (
      <AnterosMegaMenuItem
        id="mniCadastrosComercial"
        caption="Comercial"
        hint="Comercial"
        iconColor="yellow"
        icon="fad fa-chart-line-down"
      >
        <AnterosMegaMenuItem
          id="mniCondicaoPagamento"
          caption="Condição pagamento"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-calendar-day white"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniCondicaoPagamento")}
          route="/home/business/cadastros/condicaopagamento/consulta"
        />
        <AnterosMegaMenuItem
          id="mniFormaPagamento"
          caption="Forma pagamento"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fab fa-cc-paypal white"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniFormaPagamento")}
          route="/home/business/cadastros/formapagamento/consulta"
        />
        <AnterosMegaMenuItem
          id="mniCanalAtendimento"
          caption="Canal atendimento"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-chart-network white"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniCanalAtendimento")}
          route="/home/business/cadastros/canal/consulta"
        />
        <AnterosMegaMenuItem
          id="mniModalidadeNegociacao"
          caption="Modalidade negociação"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-badge-dollar white"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniModalidadeNegociacao")}
          route="/home/business/cadastros/modalidadenegociacao/consulta"
        />
        <AnterosMegaMenuItem
          id="mniSituacaoComercial"
          caption="Situação comercial"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-tasks white"
          visible={this.props.isMenuItemAccessible("mniSituacaoComercial")}
          route="/home/business/cadastros/situacaocomercial/consulta"
        />
        <AnterosMegaMenuItem
          id="mniMotivoNaoVenda"
          caption="Motivo de não venda"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-list-alt white"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniMotivoNaoVenda")}
          route="/home/business/cadastros/motivonaovenda/consulta"
        />
        <AnterosMegaMenuItem
          id="mniStatusPedido"
          caption="Status dos pedidos"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-bars white"
          visible={this.props.isMenuItemAccessible("mniStatusPedido")}
          route="/home/business/cadastros/statuspedido/consulta"
        />
        <AnterosMegaMenuItem
          id="mniClassificacaoAtendimento"
          caption="Classific.atendimentos"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-poll-people white"
          visible={this.props.isMenuItemAccessible(
            "mniClassificacaoAtendimento"
          )}
          route="/home/business/cadastros/classifatendimento/consulta"
        />
      </AnterosMegaMenuItem>
    );
  }
  menuItemCadastrosCRM() {
    return (
      <AnterosMegaMenuItem
        id="mniCadastrosCRM"
        caption="CRM"
        hint="CRM"
        iconColor="yellow"
        icon="fad fa-hands-usd"
      >
        <AnterosMegaMenuItem
          id="mniTipoAtividade"
          caption="Tipo de atividade"
          hint="Tipo de atividade"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-tasks"
          route="/home/business/cadastros/crm/tipoatividade/consulta"
          visible={this.props.isMenuItemAccessible("mniTipoAtividade")}
        />
        <AnterosMegaMenuItem
          id="mniTipoCompromisso"
          caption="Tipo de compromisso"
          hint="Tipo de compromisso"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-clock"
          route="/home/business/cadastros/crm/tipocompromisso/consulta"
          visible={this.props.isMenuItemAccessible("mniTipoCompromisso")}
        />
        <AnterosMegaMenuItem
          id="mniTipoLigacao"
          caption="Tipo de ligação"
          hint="Tipo de ligação"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-phone-volume"
          route="/home/business/cadastros/crm/tipoligacao/consulta"
          visible={this.props.isMenuItemAccessible("mniTipoLigacao")}
        />
        <AnterosMegaMenuItem
          id="mniTag"
          caption="Tag"
          hint="Tag"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-tag"
          route="/home/business/cadastros/crm/tag/consulta"
          visible={this.props.isMenuItemAccessible("mniTag")}
        />
        <AnterosMegaMenuItem
          id="mniFunilVendas"
          caption="Funil de vendas"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-funnel-dollar white"
          visible={this.props.isMenuItemAccessible("mniFunilVendas")}
          route="/home/business/cadastros/crm/funil/consulta"
        />
      </AnterosMegaMenuItem>
    );
  }

  menuItemCadastros() {
    return (
      <AnterosMegaMenuItem
        id="mniCadastros"
        caption="Cadastros"
        hint="Cadastros"
        iconColor="yellow"
        icon="fad fa-address-card"
      >
        {this.menuItemCadastrosComerciais()}
        {this.menuItemCadastrosCRM()}
        <AnterosMegaMenuItem
          id="mniCidade"
          caption="Cidade"
          hint="Cidade"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-city"
          route="/home/business/cadastros/cidade/consulta"
          visible={this.props.isMenuItemAccessible("mniCadastros")}
        />
        <AnterosMegaMenuItem
          id="mniEquipamento"
          caption="Equipamento"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-mobile"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniEquipamento")}
          route="/home/business/cadastros/equipamento/consulta"
        />
        <AnterosMegaMenuItem
          id="mniTipoLogradouro"
          caption="Tipo de logradouro"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-road white"
          visible={this.props.isMenuItemAccessible("mniTipoLogradouro")}
          route="/home/business/cadastros/tipologradouro/consulta"
        />

        <AnterosMegaMenuItem
          id="mniFilaEmail"
          caption="Fila de e-mails"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-inbox-out white"
          visible={this.props.isMenuItemAccessible("mniFilaEmail")}
          route="/home/business/cadastros/filaemail/consulta"
        />
        <AnterosMegaMenuItem
          id="mniFilaGeocodificao"
          caption="Fila geocodificacao"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-map-marker-alt white"
          visible={this.props.isMenuItemAccessible("mniFilaGeocodificao")}
          route="/home/business/cadastros/filageocodificacao/consulta"
        />
        <AnterosMegaMenuItem
          id="mniGerenciamentoArquivos"
          caption="Arquivos"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-file-upload white"
          visible={this.props.isMenuItemAccessible("mniGerenciamentoArquivos")}
          route="/home/business/cadastros/arquivos/editar"
        />
        <AnterosMegaMenuItem
          id="mniModeloEmail"
          caption="Modelos de e-mails"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-file-spreadsheet white"
          visible={this.props.isMenuItemAccessible("mniModeloEmail")}
          route="/home/business/cadastros/modeloemail/consulta"
        />
        <AnterosMegaMenuItem
          id="mniHomologacao"
          caption="Homologação de endereços"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-map-marked-alt white"
          visible={this.props.isMenuItemAccessible("mniHomologacao")}
          route="/home/business/cadastros/homologacao/consulta"
        />
      </AnterosMegaMenuItem>
    );
  }

  menuItemCadastrosProdutos() {
    return (
      <AnterosMegaMenuItem
        id="mniCadastroProdutos"
        caption="Produtos"
        icon="fad fa-boxes white"
      >
        <AnterosMegaMenuItem
          id="mniCategoria"
          caption="Categoria"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-list-ol white"
          visible={this.props.isMenuItemAccessible("mniCadastroProdutos")}
          route="/home/business/cadastros/categoriaproduto/consulta"
        />
        <AnterosMegaMenuItem
          id="mniCor"
          caption="Cor"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-palette"
          visible={this.props.isMenuItemAccessible("mniCor")}
          route="/home/business/cadastros/cor/consulta"
        />
        <AnterosMegaMenuItem
          id="mniTamanho"
          caption="Tamanho"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-file-alt white"
          visible={this.props.isMenuItemAccessible("mniTamanho")}
          route="/home/business/cadastros/tamanho/consulta"
        />
        <AnterosMegaMenuItem
          id="mniMarca"
          caption="Marca"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-file-alt white"
          visible={this.props.isMenuItemAccessible("mniMarca")}
          route="/home/business/cadastros/marca/consulta"
        />
        <AnterosMegaMenuItem
          id="mniProduto"
          caption="Produto"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-box-usd"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniProduto")}
          route="/home/business/cadastros/produto/consulta"
        />
        <AnterosMegaMenuItem
          id="mniColecao"
          caption="Coleção"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-ballot-check"
          visible={this.props.isMenuItemAccessible("mniColecao")}
          route="/home/business/cadastros/colecao/consulta"
        />
        <AnterosMegaMenuItem
          id="mniCatalogo"
          caption="Catálogo"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-file-alt white"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniCatalogo")}
          route="/home/business/cadastros/catalogo/consulta"
        />
        <AnterosMegaMenuItem
          id="mniExcecaoFiscal"
          caption="Exceção fiscal"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-value-absolute white"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniExcecaoFiscal")}
          route="/home/business/cadastros/excecaofiscal/consulta"
        />
        <AnterosMegaMenuItem
          id="mniRegraTributacao"
          caption="Regra de tributação"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-badge-percent white"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniRegraTributacao")}
          route="/home/business/cadastros/regratributacao/consulta"
        />
      </AnterosMegaMenuItem>
    );
  }

  menuItemCadastroPessoas() {
    return (
      <AnterosMegaMenuItem id="mniPessoa" caption="Pessoa" icon="fad fa-user">
        <AnterosMegaMenuItem
          id="mniPessoas"
          caption="Cadastro de pessoas"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-user-plus"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniPessoas")}
          route="/home/business/cadastros/pessoa/consulta"
        />
        <AnterosMegaMenuItem
          id="mniHomologacaoEndereco"
          caption="Homologação de endereço"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-user-plus"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniPessoa")}
          route="/home/business/cadastros/homologacao/consulta"
        />
        <AnterosMegaMenuItem
          id="mniGrupoClientes"
          caption="Grupo de clientes"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-users"
          visible={this.props.isMenuItemAccessible("mniGrupoClientes")}
          route="/home/business/cadastros/grupocliente/consulta"
        />
      </AnterosMegaMenuItem>
    );
  }

  menuItemCadastroPesquisas() {
    return (
      <AnterosMegaMenuItem
        id="mniPesquisa"
        caption="Pesquisa"
        icon="fad fa-chart-line-down white"
      >
        <AnterosMegaMenuItem
          id="mniPesquisaComercial"
          caption="Pesquisa comercial "
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-chart-line-down white"
          visible={this.props.isMenuItemAccessible("mniPesquisaComercial")}
          route="/home/business/pesquisa/pesquisacomercial/consulta"
        />
        <AnterosMegaMenuItem
          id="mniAcompanhamentoPesquisa"
          caption="Acompanhamento pesquisa"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-clipboard-list-check white"
          visible={this.props.isMenuItemAccessible("mniAcompanhamentoPesquisa")}
          route="/home/business/pesquisa/acompanhamento/consulta"
        />
      </AnterosMegaMenuItem>
    );
  }

  menuItemTabelaPrecos() {
    return (
      <AnterosMegaMenuItem
        id="mniTabelaPrecos"
        caption="Tabela de Preços"
        onSelectMenuItem={this.props.onSelectMenuItem}
        icon="fad fa-file-invoice-dollar"
        style={{ color: "rgb(9, 112, 240)" }}
        visible={this.props.isMenuItemAccessible("mniTabelaPrecos")}
        route="/home/business/cadastros/tabelapreco/consulta"
      />
    );
  }

  menuItemCRM() {
    return (
      <AnterosMegaMenuItem id="mniCRM" caption="CRM" icon="fad fa-hands-usd">
        <AnterosMegaMenuItem
          id="mniOportunidades"
          caption="Oportunidades"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-funnel-dollar"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniOportunidades")}
          route="/home/business/crm/oportunidades/consulta"
        />
      </AnterosMegaMenuItem>
    );
  }

  menuItemComercial() {
    return (
      <AnterosMegaMenuItem
        id="mniComercial"
        caption="Comercial"
        icon="fad fa-user-chart"
      >
        <AnterosMegaMenuItem
          id="mniPoliticaVenda"
          caption="Politica de Venda"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-award"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniPoliticaVenda")}
          route="/home/business/cadastros/politicavenda/consulta"
        />
        <AnterosMegaMenuItem
          id="mniPeriodoEntrega"
          caption="Período entrega"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-calendar-alt"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniComercial")}
          route="/home/business/cadastros/periodoentrega/consulta"
        />
        <AnterosMegaMenuItem
          id="mniRegioes"
          caption="Regiões"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-globe-americas"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniRegioes")}
          route="/home/business/cadastros/regiaocomercial/consulta"
        />
        <AnterosMegaMenuItem
          id="mniContratos"
          caption="Contratos"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-file-signature"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniContratos")}
          route="/home/business/cadastros/contratorepresentante/consulta"
        />
        <AnterosMegaMenuItem
          id="mniRotas"
          caption="Rotas de atendimento"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-route"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniRotas")}
          route="/home/business/cadastros/rota/consulta"
        />
        <AnterosMegaMenuItem
          id="mniOndeEstaMinhaEquipe"
          caption="Onde está minha equipe ?"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-map-marker-alt"
          visible={this.props.isMenuItemAccessible("mniOndeEstaMinhaEquipe")}
          route="/home/business/cadastros/ondeestaminhaequipe/consulta"
        />
        <AnterosMegaMenuItem
          id="mniPlanejamentoRotas"
          caption="Planejamento das rotas"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-map-marked-alt"
          visible={this.props.isMenuItemAccessible("mniPlanejamentoRotas")}
          route="/home/business/cadastros/planejamentorota/consulta"
        />
        <AnterosMegaMenuItem
          id="mniAcompanhamentoPedidos"
          caption="Acompanhamento dos pedidos"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-badge-percent white"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniAcompanhamentoPedidos")}
          route="/home/business/cadastros/acompanhamentopedido/consulta"
        />
        <AnterosMegaMenuItem
          id="mniAtendimentos"
          caption="Atendimentos"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-comments"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniAtendimentos")}
          route="/home/business/cadastros/atendimento/consulta"
        />
        <AnterosMegaMenuItem
          id="mniPedidosRecebidos"
          caption="Pedidos recebidos"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-money-check white"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniPedidosRecebidos")}
          route="/home/business/cadastros/pedidorecebido/consulta"
        />
        {/* <AnterosMegaMenuItem  
          id="mniResumoPedido"
          caption="Resumo de pedidos"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-badge-check white"
          style={{ color: "rgb(9, 112, 240)" }}
          visible={this.props.isMenuItemAccessible("mniResumoPedido")}
          route="/home/business/cadastros/resumopedidos/consulta"
        /> */}
        <AnterosMegaMenuItem
          id="mniAutorizacaoVendas"
          caption="Autorização de vendas"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-file-check"
          visible={this.props.isMenuItemAccessible("mniAutorizacaoVendas")}
          route="/home/business/cadastros/autorizacaovenda/consulta"
        />
        <AnterosMegaMenuItem
          id="mniPedido"
          caption="Pedido"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-file-spreadsheet"
          visible={this.props.isMenuItemAccessible("mniPedido")}
          route="/home/business/cadastros/pedido/consulta"
        />
        <AnterosMegaMenuItem
          id="mniMetasAtendimento"
          caption="Metas de atendimento"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-chart-bar"
          visible={this.props.isMenuItemAccessible("mniMetasAtendimento")}
          route="/home/business/cadastros/metaatendimento/consulta"
        />
        <AnterosMegaMenuItem
          id="mniMetasVendas"
          caption="Metas de vendas"
          onSelectMenuItem={this.props.onSelectMenuItem}
          icon="fad fa-chart-line"
          visible={this.props.isMenuItemAccessible("mniMetasVendas")}
          route="/home/business/cadastros/metavenda/consulta"
        />
      </AnterosMegaMenuItem>
    );
  }

  onChangeFavorites(favorites) {
    if (this.props.user) {
      let sFavorites = JSON.stringify(favorites);
      localStorage.setItem(
        "favorites_" + this.props.user.profile.login,
        sFavorites
      );
    }
  }

  getFavorites() {
    if (this.props.user) {
      let sFavorites = localStorage.getItem(
        "favorites_" + this.props.user.profile.login
      );
      if (sFavorites) {
        return JSON.parse(sFavorites);
      }
    }
    return [];
  }

  render() {
    return (
      <AnterosMegaMenu
        menuOpened={false}
        withoutUserBlock={this.props.withoutUserBlock}
        onExpandMenu={this.props.onExpandMenu}
        onCollapseMenu={this.props.onCollapseMenu}
        getFavorites={this.getFavorites}
        onChangeFavorites={this.onChangeFavorites}
        version={pjson.version}
        onChangeMenuFormat={this.props.onChangeMenuFormat}
      >
        {this.menuItemConfigInicial()}
        {this.menuItemAnalytics()}
        {this.menuItemDashboard()}
        {this.menuItemReports()}
        {/* {this.menuItemCRM()} */}
        {this.menuItemComercial()}
        {this.menuItemCadastros()}
        {this.menuItemCadastrosProdutos()}
        {this.menuItemCadastroPessoas()}
        {this.menuItemCadastroPesquisas()}
        {this.menuItemTabelaPrecos()}
      </AnterosMegaMenu>
    );
  }
}

class RibbonMenu extends Component {
  render() {
    return (
      <Modal
        id={this.props.id}
        key={this.props.key}
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        style={{
          overlay: {
            position: "absolute",
            left: this.props.left,
            top: this.props.top,
            borderRadius: "8px",
            width: "60%",
            height: "150px",
            zIndex: 999,
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            inset: 0,
            padding: 0,
            position: "absolute",
            border: "1px solid rgb(204, 204, 204)",
            background: "rgb(255, 255, 255)",
            borderRadius: "8px",
            outline: "none",
            height: "150px",
            zIndex: 999,
          },
        }}
        centered={true}
      >
        <OuterClick
          onOuterClick={(event) => {
            event.preventDefault();
            this.props.onRequestClose && this.props.onRequestClose();
          }}
        >
          <AnterosRibbonMenu>
            <AnterosRibbonGroup
              itemId={1}
              title={CADASTRO}
              colClass="col-xs-3"
              backgroundTitle={getColorByCategory(CADASTRO)}
            >
              <AnterosRibbonGroupItem colClass="col-xs-4">
                <AnterosRibbonButton
                  color="green"
                  icon="fad fa-user-plus"
                  caption="Pessoa"
                  onClick={this.props.onClick}
                  route="/home/business/cadastros/pessoa/consulta"
                />
                <AnterosRibbonButton
                  color="red"
                  icon="fad fa-file-invoice-dollar"
                  caption="Tabela preço"
                  onClick={this.props.onClick}
                  wordBreak={true}
                  route="/home/business/cadastros/tabelapreco/consulta"
                />
                <AnterosRibbonButton
                  color="blue"
                  icon="fad fa-globe-americas"
                  caption="Região"
                  onClick={this.props.onClick}
                  route="/home/business/cadastros/regiaocomercial/consulta"
                />

                <AnterosRibbonButton
                  color="#d62d20"
                  icon="fad fa-award"
                  wordBreak={true}
                  onClick={this.props.onClick}
                  route="/home/business/cadastros/politicavenda/consulta"
                  caption="Política venda"
                />
                <AnterosRibbonButton
                  color="#e3ae64"
                  icon="fad fa-box-usd"
                  onClick={this.props.onClick}
                  caption="Produto"
                  route="/home/business/cadastros/produto/consulta"
                />
                <AnterosRibbonButton
                  color="#d66a60"
                  icon="fad fa-file-alt"
                  onClick={this.props.onClick}
                  caption="Catálogo"
                  route="/home/business/cadastros/catalogo/consulta"
                />
                <AnterosRibbonButton
                  color="#4777bf"
                  icon="fad fa-mobile"
                  onClick={this.props.onClick}
                  caption="Equipamento"
                  route="/home/business/cadastros/equipamento/consulta"
                />
              </AnterosRibbonGroupItem>
            </AnterosRibbonGroup>

            <AnterosRibbonGroup
              itemId={2}
              title={CRM}
              colClass="col-xs-3"
              backgroundTitle={getColorByCategory(CRM)}
            >
              <AnterosRibbonGroupItem colClass="col-xs-4">
                <AnterosRibbonButton
                  icon="fad fa-funnel-dollar"
                  caption="Oportunidade"
                  onClick={this.props.onClick}
                  color={"#2a4c87"}
                  route="/home/business/crm/oportunidades/consulta"
                />
              </AnterosRibbonGroupItem>
            </AnterosRibbonGroup>
            <AnterosRibbonGroup
              itemId={3}
              title={ATENDIMENTO}
              colClass="col-xs-3"
              onClick={this.props.onClick}
              backgroundTitle={getColorByCategory(ATENDIMENTO)}
            >
              <AnterosRibbonGroupItem colClass="col-xs-4">
                <AnterosRibbonButton
                  icon="fad fa-comments"
                  caption="Atendimento"
                  onClick={this.props.onClick}
                  color="#b31b4b"
                  route="/home/business/cadastros/atendimento/consulta"
                />
                <AnterosRibbonButton
                  icon="fad fa-map-marked-alt"
                  caption="Planejamento rota"
                  wordBreak={true}
                  color="#7d5764"
                  route="/home/business/cadastros/planejamentorota/consulta"
                />
              </AnterosRibbonGroupItem>
            </AnterosRibbonGroup>
            <AnterosRibbonGroup
              itemId={4}
              title={VENDA}
              colClass="col-xs-3"
              backgroundTitle={getColorByCategory(VENDA)}
            >
              <AnterosRibbonGroupItem colClass="col-xs-4">
                <AnterosRibbonButton
                  icon="fad fa-money-check white"
                  caption="Pedido recebido"
                  onClick={this.props.onClick}
                  color="#48734d"
                  route="/home/business/cadastros/pedidorecebido/consulta"
                />
                <AnterosRibbonButton
                  icon="fad fa-file-spreadsheet"
                  caption="Pedido"
                  onClick={this.props.onClick}
                  color="#159e25"
                  route="/home/business/cadastros/pedido/consulta"
                />
              </AnterosRibbonGroupItem>
            </AnterosRibbonGroup>
            <AnterosRibbonGroup
              itemId={4}
              title={ANALISE}
              colClass="col-xs-3"
              backgroundTitle={getColorByCategory(ANALISE)}
            >
              <AnterosRibbonGroupItem colClass="col-xs-4">
                <AnterosRibbonButton
                  icon="fad fa-money-check white"
                  caption="Análises"
                  onClick={this.props.onClick}
                  color="#1976d2"
                  route="/home/business/analytics"
                />
              </AnterosRibbonGroupItem>
            </AnterosRibbonGroup>
          </AnterosRibbonMenu>
        </OuterClick>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authenticationReducer.user,
    authenticating: state.authenticationReducer.authenticating,
    authenticated: state.authenticationReducer.authenticated,
    serviceWorkerInitialized: state.swReducer.serviceWorkerInitialized,
    serviceWorkerUpdated: state.swReducer.serviceWorkerUpdated,
    serviceWorkerRegistration: state.swReducer.serviceWorkerRegistration,
    path: state.appReducer.path,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    exit: () => {
      dispatch({
        type: "RESET",
      });
      dispatch({ type: "@@redux/INIT" });
    },
    handleSwapCompany: () => {
      dispatch(actionsLogin.selectCompany(undefined));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutBusiness);
