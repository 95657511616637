import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const COR = 'cor';
const corReducerDef = makeDefaultReduxObject(COR);
const corConsultaReducerDef = makeDefaultReduxObject(COR.toUpperCase()+"_SEARCH");

export class CorResource extends AnterosRemoteResource {
    constructor(){
        super(COR,`${version}/cor/`);
    }
}

export const corReducer = createReducer(initialState,corReducerDef);
export const corConsultaReducer = createReducer(initialState,corConsultaReducerDef);