import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const CONTRATO_REPRESENTACAO_COMERCIAL = 'contratoRepresentacaoComercial';
const contratoRepresentacaoComercialReducerDef = makeDefaultReduxObject(CONTRATO_REPRESENTACAO_COMERCIAL);
const contratoRepresentacaoComercialConsultaReducerDef = makeDefaultReduxObject(CONTRATO_REPRESENTACAO_COMERCIAL.toUpperCase()+"_SEARCH");

export class ContratoRepresentacaoComercialResource extends AnterosRemoteResource {
    constructor(){ 
        super(CONTRATO_REPRESENTACAO_COMERCIAL,`${version}/contratoRepresentacaoComercial/`);
    }
}

export const contratoRepresentacaoComercialReducer = createReducer(initialState,contratoRepresentacaoComercialReducerDef);
export const contratoRepresentacaoComercialConsultaReducer = createReducer(initialState,contratoRepresentacaoComercialConsultaReducerDef);