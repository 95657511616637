import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const FILA_EMAIL = 'filaEmail';
const filaEmailReducerDef = makeDefaultReduxObject(FILA_EMAIL);
const filaEmailConsultaReducerDef = makeDefaultReduxObject(FILA_EMAIL.toUpperCase()+"_SEARCH");

export class FilaEmailResource extends AnterosRemoteResource {
    constructor(){
        super(FILA_EMAIL,`${version}/filaEmail/`);
    }
}

export const filaEmailReducer = createReducer(initialState,filaEmailReducerDef);
export const filaEmailConsultaReducer = createReducer(initialState,filaEmailConsultaReducerDef);