import {version} from './Version';
import { AnterosRemoteResource, GET } from '@anterostecnologia/anteros-react-api';
import {makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const TABPRECO_DESCONTO = 'tabelaPrecoDesconto';
const tabelaPrecoDescontoReducerDef = makeDefaultReduxObject(TABPRECO_DESCONTO);
const tabelaPrecoDescontoConsultaReducerDef = makeDefaultReduxObject(TABPRECO_DESCONTO.toUpperCase()+"_SEARCH");

export class TabelaPrecoDescontoResource extends AnterosRemoteResource {
    constructor(){
        super(TABPRECO_DESCONTO,`${version}/tabelaPrecoDesconto/`);
    }

    buscarTabelaPrecoDescontoPorVlMinimo(resourceName, tabelaPrecoId, condicaoPagamentoId, valor, user, fieldsToForceLazy = "") {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaPorVlMinimo?condicaoPagamentoId=${condicaoPagamentoId}&tabelaPrecoId=${tabelaPrecoId}&valor=${valor}&fieldsToForceLazy=${fieldsToForceLazy}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    buscarDescontosTabelaPreco(
        idTabela,
        resourceName,
        page,
        size,
        user,
        fieldsToForceLazy
    ) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaDescontosTabelaPreco/${idTabela}?&page=${page}&size=${size}&fieldsToForceLazy=${fieldsToForceLazy}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
}

export const tabelaPrecoDescontoReducer = createReducer(initialState,tabelaPrecoDescontoReducerDef);
export const tabelaPrecoDescontoConsultaReducer = createReducer(initialState,tabelaPrecoDescontoConsultaReducerDef);