import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const AUTORIZACAO_VENDA = 'autorizacaoVenda';
const autorizacaoVendaReducerDef = makeDefaultReduxObject(AUTORIZACAO_VENDA);
const autorizacaoVendaConsultaReducerDef = makeDefaultReduxObject(AUTORIZACAO_VENDA.toUpperCase()+"_SEARCH");

export class AutorizacaoVendaResource extends AnterosRemoteResource {
    constructor(){
        super(AUTORIZACAO_VENDA,`${version}/autorizacaoVenda/`);
    }
}

export const autorizacaoVendaReducer = createReducer(initialState,autorizacaoVendaReducerDef);
export const autorizacaoVendaConsultaReducer = createReducer(initialState,autorizacaoVendaConsultaReducerDef);