import {authService, userService, tokenService } from '@anterostecnologia/anteros-react-api';

const authBaseURL =
  'https://auth.relevantsolutions.com.br:14101/api';
const versatilSaaSBaseURL = 
  'https://versatil-saas.relevantsolutions.com.br:14102/api';  
const versatilBaseURL =
  'https://versatil-salescloud.relevantsolutions.com.br:14104/api';
const versatilSocialBaseURL =  'https://versatil-condominio.relevantsolutions.com.br:14110/api'; 

const apiVersion = 'v1';
const apiSocialVersion = 'v1';

const config = {
    clientId:'versatil-salescloud',
    clientSecret:'LZr4PfAp9ut5Fr8AfrkxeHBSaWKdwK',
    clientIdOwner:'versatil-saas',
    clientSecretOwner:'y2Rwq9jhU7Y8Fjm5vcanSqQAkA52jW',
    secretKey : 'VGgkWzkrRmVxNDJoNE5aNA==',
    url_baseSaas: versatilSaaSBaseURL,
    url_token: authBaseURL,
    token: '/oauth/token',
    apiVersion: apiVersion,
    url_user_with_owner: `${versatilBaseURL}/${apiVersion}`,
    url_owner: `${versatilBaseURL}/${apiVersion}`,
    getUser: '/user/getUserByLogin',
    getOwner: '/empresa/buscarProprietario/',
    url_social: `${versatilSocialBaseURL}/${apiSocialVersion}/social/socialUser/getUserSocialByLogin`
}

authService.setConfiguration(config);
tokenService.setConfiguration(config);
userService.setConfiguration(config);

export { authBaseURL, versatilBaseURL, versatilSaaSBaseURL, versatilSocialBaseURL };
