import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const PROPRIETARIO = 'proprietario';
const proprietarioReducerDef = makeDefaultReduxObject(PROPRIETARIO);
const proprietarioConsultaReducerDef = makeDefaultReduxObject(PROPRIETARIO.toUpperCase()+"_SEARCH");

export class ProprietarioResource extends AnterosRemoteResource {
    constructor(){
        super(PROPRIETARIO,`${version}/proprietario/`);
    }
}

export const proprietarioReducer = createReducer(initialState,proprietarioReducerDef);
export const proprietarioConsultaReducer = createReducer(initialState,proprietarioConsultaReducerDef);