import {version} from './Version';
import {AnterosRemoteResource,GET, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const CONDICAO_PAGAMENTO = 'condicaoPagamento';
const condicaoPagamentoReducerDef = makeDefaultReduxObject(CONDICAO_PAGAMENTO);
const condicaoPagamentoConsultaReducerDef = makeDefaultReduxObject(CONDICAO_PAGAMENTO.toUpperCase()+"_SEARCH");

export class CondicaoPagamentoResource extends AnterosRemoteResource {
    constructor(){
        super(CONDICAO_PAGAMENTO,`${version}/condicaoPagamento/`);
    }

    buscarCondicoesPagamentoPorRegiao(resourceName, value, page, size, user, fieldsToForceLazy = "") {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscarCondicoesPagamentoPorRegiao?regiao=${value}&page=${page}&size=${size}&fieldsToForceLazy=${fieldsToForceLazy}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
}

export const condicaoPagamentoReducer = createReducer(initialState,condicaoPagamentoReducerDef);
export const condicaoPagamentoConsultaReducer = createReducer(initialState,condicaoPagamentoConsultaReducerDef);