export const ActionType = {
    UPDATE_ROUTING_PLAN: 'UPDATE_ROUTING_PLAN',
    DELETE_LOCATION: 'DELETE_LOCATION',
    ADD_LOCATION: 'ADD_LOCATION',
    ADD_ALL_LOCATION: 'ADD_ALL_LOCATION',
    OPTIMIZE_ROUTE: 'OPTIMIZE_ROUTE',
    ADD_VEHICLE: 'ADD_VEHICLE',
    DELETE_VEHICLE: 'DELETE_VEHICLE',
    CLEAR_SOLUTION: 'CLEAR_SOLUTION',
}

export const actions = {
    addVehicle: () => ({
        type: ActionType.ADD_VEHICLE,
    }),

    deleteVehicle: id => ({
        type: ActionType.DELETE_VEHICLE,
        value: id,
    }),

    addLocation: location => ({
        type: ActionType.ADD_LOCATION,
        value: location,
    }),

    addAllLocation: locations => ({
        type: ActionType.ADD_ALL_LOCATION,
        value: locations,
    }),

    optimizeRoute: locations => ({
        type: ActionType.OPTIMIZE_ROUTE,
        value: locations,
    }),

    deleteLocation: id => ({
        type: ActionType.DELETE_LOCATION,
        value: id,
    }),

    clearRoute: () => ({
        type: ActionType.CLEAR_SOLUTION,
    }),

    updateRoute: plan => ({
        plan,
        type: ActionType.UPDATE_ROUTING_PLAN,
    })
}


export const operations = {
    addLocation: (location => (dispatch, getState, client) => {
        dispatch(actions.addLocation(location));
        client.addLocation(location);
    }),

    addAllLocation: (locations => (dispatch, getState, client) => {
        dispatch(actions.addAllLocation(locations));
        client.addAllLocation(locations);
    }),

    optimizeRoute: (route => (dispatch, getState, client) => {
        dispatch(actions.optimizeRoute(route));
        client.optimizeRoute(route);
    }),

    deleteLocation: (locationId => (dispatch, getState, client) => {
        dispatch(actions.deleteLocation(locationId));
        client.deleteLocation(locationId);
    }),

    addVehicle: (() => (dispatch, getState, client) => {
        dispatch(actions.addVehicle());
        client.addVehicle();
    }),

    deleteVehicle: (vehicleId => (dispatch, getState, client) => {
        dispatch(actions.deleteVehicle(vehicleId));
        client.deleteVehicle(vehicleId);
    }),

    deleteAnyVehicle: (() => (dispatch, getState, client) => {
        client.deleteAnyVehicle();
    }),

    changeVehicleCapacity: (({
        vehicleId,
        capacity
    }) => (dispatch, getState, client) => {
        client.changeVehicleCapacity(vehicleId, capacity);
    }),

    clearRoute: (() => (dispatch, getState, client) => {
        dispatch(actions.clearRoute());
        client.clear();
    })
}

export const initialRouteState = {
    distance: 'no data',
    vehicles: [],
    depot: null,
    visits: [],
    routes: [],
};

const routeReducer = (state = initialRouteState, action) => {
    switch (action.type) {
        case ActionType.UPDATE_ROUTING_PLAN: {
            return action.plan;
        }
        default:
            return state;
    }
};

export default routeReducer;