import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const TIPOATIVIDADE = 'tipoAtividade';
const tipoAtividadeReducerDef = makeDefaultReduxObject(TIPOATIVIDADE);
const tipoAtividadeConsultaReducerDef = makeDefaultReduxObject(TIPOATIVIDADE.toUpperCase()+"_SEARCH");

export class TipoAtividadeResource extends AnterosRemoteResource {
    constructor(){
        super(TIPOATIVIDADE,`${version}/tipoAtividade/`);
    }
}

export const tipoAtividadeReducer = createReducer(initialState,tipoAtividadeReducerDef);
export const tipoAtividadeConsultaReducer = createReducer(initialState,tipoAtividadeConsultaReducerDef);