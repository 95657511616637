import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const MODALIDADE_NEGOCIACAO = 'modalidadeNegociacao';
const modalidadeNegociacaoReducerDef = makeDefaultReduxObject(MODALIDADE_NEGOCIACAO);
const modalidadeNegociacaoConsultaReducerDef = makeDefaultReduxObject(MODALIDADE_NEGOCIACAO.toUpperCase()+"_SEARCH");

export class ModalidadeNegociacaoResource extends AnterosRemoteResource {
    constructor(){
        super(MODALIDADE_NEGOCIACAO,`${version}/modalidadeNegociacao/`);
    }
}

export const modalidadeNegociacaoReducer = createReducer(initialState,modalidadeNegociacaoReducerDef);
export const modalidadeNegociacaoConsultaReducer = createReducer(initialState,modalidadeNegociacaoConsultaReducerDef);