import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const PERIODO_ENTREGA = 'periodoEntrega';
const periodoEntregaReducerDef = makeDefaultReduxObject(PERIODO_ENTREGA);
const periodoEntregaConsultaReducerDef = makeDefaultReduxObject(PERIODO_ENTREGA.toUpperCase()+"_SEARCH");

export class PeriodoEntregaResource extends AnterosRemoteResource {
    constructor(){
        super(PERIODO_ENTREGA,`${version}/periodoEntrega/`);
    }
}

export const periodoEntregaReducer = createReducer(initialState,periodoEntregaReducerDef);
export const periodoEntregaConsultaReducer = createReducer(initialState,periodoEntregaConsultaReducerDef);