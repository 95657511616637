import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const MARCA = 'marca';
const marcaReducerDef = makeDefaultReduxObject(MARCA);
const marcaConsultaReducerDef = makeDefaultReduxObject(MARCA.toUpperCase()+"_SEARCH");

export class MarcaResource extends AnterosRemoteResource {
    constructor(){
        super(MARCA,`${version}/marca/`);
    }
}

export const marcaReducer = createReducer(initialState,marcaReducerDef);
export const marcaConsultaReducer = createReducer(initialState,marcaConsultaReducerDef);