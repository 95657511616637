import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const CIDADE = 'cidade';
const cidadeReducerDef = makeDefaultReduxObject(CIDADE);
const cidadeConsultaReducerDef = makeDefaultReduxObject(CIDADE.toUpperCase()+"_SEARCH");

export class CidadeResource extends AnterosRemoteResource {
    constructor(){
        super(CIDADE,`${version}/cidade/`);
    }
}

export const cidadeReducer = createReducer(initialState,cidadeReducerDef);
export const cidadeConsultaReducer = createReducer(initialState,cidadeConsultaReducerDef);