import {version} from './Version';
import { AnterosRemoteResource, GET } from '@anterostecnologia/anteros-react-api';
import {makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const EMPRESA = 'empresa';
const empresaReducerDef = makeDefaultReduxObject(EMPRESA);
const empresaConsultaReducerDef = makeDefaultReduxObject(EMPRESA.toUpperCase()+"_SEARCH");

export class EmpresaResource extends AnterosRemoteResource {
    constructor(){
        super(EMPRESA,`${version}/empresa/`);
    }

    buscarChecklistInicial(user) {
        let result = {
            url: `${version}/empresa/checkListInicial`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
}

export const empresaReducer = createReducer(initialState,empresaReducerDef);
export const empresaConsultaReducer = createReducer(initialState,empresaConsultaReducerDef);