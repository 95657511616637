import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const TIPOCOMPROMISSO = 'tipoCompromisso';
const tipoCompromissoReducerDef = makeDefaultReduxObject(TIPOCOMPROMISSO);
const tipoCompromissoConsultaReducerDef = makeDefaultReduxObject(TIPOCOMPROMISSO.toUpperCase()+"_SEARCH");

export class TipoCompromissoResource extends AnterosRemoteResource {
    constructor(){
        super(TIPOCOMPROMISSO,`${version}/tipoCompromisso/`);
    }
}

export const tipoCompromissoReducer = createReducer(initialState,tipoCompromissoReducerDef);
export const tipoCompromissoConsultaReducer = createReducer(initialState,tipoCompromissoConsultaReducerDef);