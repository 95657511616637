import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const PEDIDO_ITEM = 'abstractPedidoItem';
const pedidoItemReducerDef = makeDefaultReduxObject(PEDIDO_ITEM);
const pedidoItemConsultaReducerDef = makeDefaultReduxObject(PEDIDO_ITEM.toUpperCase()+"_SEARCH");

export class PedidoItemResource extends AnterosRemoteResource {
    constructor(){
        super(PEDIDO_ITEM,`${version}/abstractPedidoItem/`);
    }
}

export const pedidoItemReducer = createReducer(initialState,pedidoItemReducerDef);
export const pedidoItemConsultaReducer = createReducer(initialState,pedidoItemConsultaReducerDef);