import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const CONTRATO_LICENCA = 'contratoLicenca';
const contratoLicencaReducerDef = makeDefaultReduxObject(CONTRATO_LICENCA);
const contratoLicencaConsultaReducerDef = makeDefaultReduxObject(CONTRATO_LICENCA.toUpperCase()+"_SEARCH");

export class ContratoLicencaResource extends AnterosRemoteResource {
    constructor(){
        super(CONTRATO_LICENCA,`${version}/contratoLicenca/`);
    }
}

export const contratoLicencaReducer = createReducer(initialState,contratoLicencaReducerDef);
export const contratoLicencaConsultaReducer = createReducer(initialState,contratoLicencaConsultaReducerDef);