import {version} from './Version';
import { AnterosRemoteResource, GET } from '@anterostecnologia/anteros-react-api';
import {makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const EVENTO = 'abstractEvento';
const abstractEventoReducerDef = makeDefaultReduxObject(EVENTO);
const abstractEventoConsultaReducerDef = makeDefaultReduxObject(EVENTO.toUpperCase()+"_SEARCH");

export class AbstractEventoResource extends AnterosRemoteResource {
    constructor(){
        super(EVENTO,`${version}/abstractEvento/`);
    }

    buscarEventosPeriodo(resourceName, dtInicio, dtFim, user) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaEventosPorPeriodo`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
}

export const abstractEventoReducer = createReducer(initialState,abstractEventoReducerDef);
export const abstractEventoConsultaReducer = createReducer(initialState,abstractEventoConsultaReducerDef);