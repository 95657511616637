import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const TAMANHO = 'tamanho';
const tamanhoReducerDef = makeDefaultReduxObject(TAMANHO);
const tamanhoConsultaReducerDef = makeDefaultReduxObject(TAMANHO.toUpperCase()+"_SEARCH");

export class TamanhoResource extends AnterosRemoteResource {
    constructor(){
        super(TAMANHO,`${version}/tamanho/`);
    }
}

export const tamanhoReducer = createReducer(initialState,tamanhoReducerDef);
export const tamanhoConsultaReducer = createReducer(initialState,tamanhoConsultaReducerDef);