import {version} from './Version';
import { AnterosRemoteResource, GET } from '@anterostecnologia/anteros-react-api';
import {makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const PRODUTO_SKU = 'produtoSKU';
const produtoSkuReducerDef = makeDefaultReduxObject(PRODUTO_SKU);
const produtoSkuConsultaReducerDef = makeDefaultReduxObject(PRODUTO_SKU.toUpperCase()+"_SEARCH");

export class ProdutoSkuResource extends AnterosRemoteResource {
    constructor(){
        super(PRODUTO_SKU,`${version}/produtoSKU/`);
    }

    buscarSKUsPorProduto(resourceName, user, produto) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaPorProduto?produto=${produto}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
    
    buscarSKUPorProduto(resourceName, user, produto, id, code) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaPorProduto/${produto}?id=${id}&code=${code}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
}

export const produtoSkuReducer = createReducer(initialState,produtoSkuReducerDef);
export const produtoSkuConsultaReducer = createReducer(initialState,produtoSkuConsultaReducerDef);