export const SET_NEED_UPDATEVIEW = 'SET_NEED_UPDATEVIEW';

export const actions = {
  setNeedUpdateView() {
    return { type: SET_NEED_UPDATEVIEW, payload: { needUpdateView:true } };
  }
};

export default (
  state = {
    needUpdateView: false
  },
  action
) => {
  switch (action.type) {
    case SET_NEED_UPDATEVIEW: {
      return { ...state, needUpdateView: !state.needUpdateView };
    }
    default: {
      return state;
    }
  }
};
