import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const OPORTUNIDADE = 'oportunidade';
const oportunidadeConsultaReducerDef = makeDefaultReduxObject(OPORTUNIDADE.toUpperCase()+"_SEARCH");

const _actions = {
    setDatasource(dataSource) {
      return { type: 'SET_DATASOURCE_OPORTUNIDADE', payload: { dataSource } };
    },
    setConfigView(configView) {
        return { type: 'SET_CONFIG_OPORTUNIDADE', payload: { configView } };
      },
    setFilter(currentFilter, activeFilterIndex) {
      return {
        type: 'SET_FILTER_OPORTUNIDADE',
        payload: { currentFilter, activeFilterIndex }
      };
    },
    clear() {
      return {
        type: 'CLEAR_OPORTUNIDADE',
        payload: {}
      };
    },
    setNeedRefresh() {
      return {
        type: 'SET_NEEDREFRESH_OPORTUNIDADE',
        payload: { needRefresh: true }
      }
    }
  };

  const oportunidadeReducerDef = {
    'SET_DATASOURCE_OPORTUNIDADE': (state, payload) => ({
        ...state,
        dataSource: payload.dataSource,
        needRefresh: false
    }),
    'SET_CONFIG_OPORTUNIDADE': (state, payload) => ({
        ...state,
        configView: payload.configView,
        needRefresh: false
    }),
    'SET_FILTER_OPORTUNIDADE': (state, payload) => ({
        ...state,
        currentFilter: payload.currentFilter,
        activeFilterIndex: payload.activeFilterIndex
    }),
    'CLEAR_OPORTUNIDADE': { initialState },
    'SET_NEEDREFRESH_OPORTUNIDADE': (state, payload) => ({
        ...state,
        needRefresh: true
    })
  };

export class OportunidadesResource extends AnterosRemoteResource {
    constructor(){
        super(OPORTUNIDADE,`${version}/oportunidadeNegocio/`);
    }

    get actions() {
        return _actions;
    }
}

export const oportunidadeReducer = createReducer(initialState,oportunidadeReducerDef);
export const oportunidadeConsultaReducer = createReducer(initialState,oportunidadeConsultaReducerDef);