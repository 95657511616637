import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const USUARIOS = 'usuarios';
const usuariosReducerDef = makeDefaultReduxObject(USUARIOS);
const usuariosConsultaReducerDef = makeDefaultReduxObject(USUARIOS.toUpperCase()+"_SEARCH");

export class UsuariosResource extends AnterosRemoteResource {
    constructor(){
        super(USUARIOS,`${version}/user/`);
    }
}

export const usuariosReducer = createReducer(initialState,usuariosReducerDef);
export const usuariosConsultaReducer = createReducer(initialState,usuariosConsultaReducerDef);