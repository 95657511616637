import {version} from './Version';
import { AnterosRemoteResource, POST } from '@anterostecnologia/anteros-react-api';
import {makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
import { AnterosJacksonParser} from '@anterostecnologia/anteros-react-core';
const GEOCODE_HOMOLOGACAO = 'geocodeHomologacao';
const geocodeHomologacaoReducerDef = makeDefaultReduxObject(GEOCODE_HOMOLOGACAO);
const geocodeHomologacaoConsultaReducerDef = makeDefaultReduxObject(GEOCODE_HOMOLOGACAO.toUpperCase()+"_SEARCH");

export class GeocodeHomologacaoResource extends AnterosRemoteResource {
    constructor(){
        super(GEOCODE_HOMOLOGACAO,`${version}/geocodeHomologacao/`);
    }

    homologar(resourceName, user, data) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let entityJson = AnterosJacksonParser.convertObjectToJson(data);
        let result = {
            url: `${_resourceName}`,
            method: POST,
            data: entityJson
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
}

export const geocodeHomologacaoReducer = createReducer(initialState,geocodeHomologacaoReducerDef);
export const geocodeHomologacaoConsultaReducer = createReducer(initialState,geocodeHomologacaoConsultaReducerDef);