import React from 'react';
import {
    withStyles,
    Button,
    Avatar,
    Tooltip
} from '@material-ui/core';
import classNames from 'classnames';
import FuseScrollbars from '../parts/FuseScrollbars';
import FuseAnimateGroup from '../parts/FuseAnimateGroup';
import {AnterosReactComponent} from '@anterostecnologia/anteros-react-core';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';

const styles = theme => ({
    root: {
        background: '#EEF2F4!important',
        overflowX: 'hidden',
        '&:first-child': {
            width: 70,
            justifyContent: 'center',
            display: 'grid'
        }
    },
    contactButton: {
        width: 70,
        minWidth: 70,
        flex: '0 0 auto',
        '&.active:after': {
            position: 'absolute',
            top: 8,
            right: 0,
            bottom: 8,
            content: "''",
            width: 4,
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            backgroundColor: '#EEF2F4'
        }
    },
    unreadBadge: {
        position: 'absolute',
        minWidth: 18,
        height: 18,
        top: 4,
        left: 10,
        borderRadius: 9,
        padding: '0 5px',
        fontSize: 11,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#EEF2F4',
        color: theme.palette.secondary.contrastText,
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.35)',
        zIndex: 10
    },
    status: {
        position: 'absolute',
        width: 12,
        height: 12,
        bottom: 4,
        left: 44,
        border: '2px solid ' + theme.palette.background.default,
        borderRadius: '50%',
        zIndex: 10,

        '&.online': {
            backgroundColor: '#4CAF50'
        },

        '&.do-not-disturb': {
            backgroundColor: '#F44336'
        },

        '&.away': {
            backgroundColor: '#FFC107'
        },

        '&.offline': {
            backgroundColor: '#646464'
        }
    }
});

class ChatContatosView extends AnterosReactComponent {

    handleContactClick = (jid) => {
        this.props.setChatTarget(jid)
        this.props.openChatPanel();
        this.scrollToTop();
    };

    scrollToTop = () => {
        this.contactListScroll.scrollTop = 0;
    };

    findAvatar(vcard) {
        if (vcard) {
            if (vcard.records && vcard.records.length > 0) {
                for (let index = 0; index < vcard.records.length; index++) {
                    const record = vcard.records[index];
                    if (record.type === 'nickname') {
                        if (this.isBase64(record.value)) {
                            return 'data:image;base64,' + record.value;
                        } else {
                            return record.value ? record.value : require('../assets/img/user.png');
                        }
                    }
                }
            }
        }
        return require('../assets/img/user.png');
    }

    render() {
        const { classes, targetJid, isConnected } = this.props;
        const contactsList = this.props.contacts;
        const contacts = Object.keys(this.props.contacts);
        const ContactButton = ({ contact, jid }) => {
            const name = contact.vcard && contact.vcard.fullName ? contact.vcard.fullName : 'Contato';
            const avatar = this.findAvatar(contact.vcard);
            return (
                <Tooltip title={name} placement="left">
                    <Button
                        onClick={() => this.handleContactClick(jid)}
                        className={classNames(classes.contactButton, { 'active': (targetJid === jid) })}
                    >
                        {contact.unread ? (
                            <div className={classes.unreadBadge}>{contact.unread}</div>
                        ) : null}
                        <div className={classNames(contact.status, classes.status)} />
                        <Avatar
                            src={avatar}
                            alt={name}
                        >
                            {!avatar || avatar === '' ? name : ''}
                        </Avatar>
                    </Button>
                </Tooltip>
            )
        };

        return (
            <FuseScrollbars
                className={classNames(classes.root, "flex flex-no-shrink flex-col overflow-y-auto py-8")}
                containerRef={(ref) => {
                    this.contactListScroll = ref
                }}
                style={{
                    overflowY: isConnected ? 'auto' : 'hidden'
                }}
            >
                {contacts.length > 0 && isConnected ? (
                    <React.Fragment>
                        <FuseAnimateGroup
                            enter={{
                                animation: "transition.expandIn"
                            }}
                            className="flex flex-col flex-no-shrink"
                        >
                            {contacts.map(jid => {
                                return (
                                    <ContactButton
                                        key={jid.split("@")[0]}
                                        contact={contactsList[jid]}
                                        jid={jid}
                                    />
                                )
                            })}
                        </FuseAnimateGroup>
                    </React.Fragment>
                ) : (
                        <SkeletonTheme baseColor="#202020" highlightColor="#e1e7eb">
                            <Skeleton circle={true} className="loading-chat" height={50} width={50} count={15} />
                        </SkeletonTheme>
                    )}
            </FuseScrollbars>
        );
    };
}


export default withStyles(styles)(ChatContatosView);

