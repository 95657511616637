import React, { PureComponent } from "react";

export class AnterosErrorFallback extends PureComponent {
  onClick(){
    window.location.reload();
  }

  render() {
    console.log(this.props);
    return (
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h1>Oops!</h1>
          </div>
          <h3>Sentimos muito, ocorreu um erro inesperado.</h3>
          <div
            style={{
              minHeight: "50px",
              borderRadius: "8px",
              borderWidth: "1px 1px 1px 10px",
              borderStyle: "solid",
              borderColor: "silver silver silver red",
              fontSize: "18px",
              color: "red",
              margin: "20px",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              padding: "8px",
            }}
          >
            {this.props.error.message}
          </div>
          <button onClick={this.onClick}>Voltar</button>
        </div>
      </div>
    );
  }
}
