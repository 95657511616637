import {version} from './Version';
import {AnterosRemoteResource, GET, DELETE, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const EQUIPAMENTO = 'equipamento';
const equipamentoReducerDef = makeDefaultReduxObject(EQUIPAMENTO);
const equipamentoConsultaReducerDef = makeDefaultReduxObject(EQUIPAMENTO.toUpperCase()+"_SEARCH");

export class EquipamentoResource extends AnterosRemoteResource {
    constructor(){
        super(EQUIPAMENTO,`${version}/equipamento/`);
    }
    alterarStatusEquipamento(resourceName, uuid, status, user) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}alteraStatusEquipamento/${uuid}/${status}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    delete(resourceName, entity, user) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {};
        result = {
            url: `${_resourceName}${entity.uuid}`,
            method: DELETE
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    buscarChecklistEquipamento(uuid, user) {
        let result = {
            url: `${version}/equipamento/checkList/${uuid}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
}

export const equipamentoReducer = createReducer(initialState,equipamentoReducerDef);
export const equipamentoConsultaReducer = createReducer(initialState,equipamentoConsultaReducerDef);