import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const TIPO_LOGRADOURO = 'tipoLogradouro';
const tipoLogradouroReducerDef = makeDefaultReduxObject(TIPO_LOGRADOURO);
const tipoLogradouroConsultaReducerDef = makeDefaultReduxObject(TIPO_LOGRADOURO.toUpperCase()+"_SEARCH");

export class TipoLogradouroResource extends AnterosRemoteResource {
    constructor(){
        super(TIPO_LOGRADOURO,`${version}/tipoLogradouro/`);
    }
}

export const tipoLogradouroReducer = createReducer(initialState,tipoLogradouroReducerDef);
export const tipoLogradouroConsultaReducer = createReducer(initialState,tipoLogradouroConsultaReducerDef);