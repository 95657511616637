import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const REGRA_TRIBUTACAO = 'regraTributacao';
const regraTributacaoReducerDef = makeDefaultReduxObject(REGRA_TRIBUTACAO);
const regraTributacaoConsultaReducerDef = makeDefaultReduxObject(REGRA_TRIBUTACAO.toUpperCase()+"_SEARCH");

export class RegraTributacaoResource extends AnterosRemoteResource {
    constructor(){
        super(REGRA_TRIBUTACAO,`${version}/regraTributacao/`);
    }
}

export const regraTributacaoReducer = createReducer(initialState,regraTributacaoReducerDef);
export const regraTributacaoConsultaReducer = createReducer(initialState,regraTributacaoConsultaReducerDef);