import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const TITULO_RECEBER = 'tituloAReceber';
const tituloAReceberReducerDef = makeDefaultReduxObject(TITULO_RECEBER);
const tituloAReceberConsultaReducerDef = makeDefaultReduxObject(TITULO_RECEBER.toUpperCase()+"_SEARCH");

export class TituloAReceberResource extends AnterosRemoteResource {
    constructor(){
        super(TITULO_RECEBER,`${version}/tituloReceber/`);
    }
}

export const tituloAReceberReducer = createReducer(initialState,tituloAReceberReducerDef);
export const tituloAReceberConsultaReducer = createReducer(initialState,tituloAReceberConsultaReducerDef);