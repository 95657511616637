import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const ESTADO = 'estado';
const estadoReducerDef = makeDefaultReduxObject(ESTADO);
const estadoConsultaReducerDef = makeDefaultReduxObject(ESTADO.toUpperCase()+"_SEARCH");

export class EstadoResource extends AnterosRemoteResource {
    constructor(){
        super(ESTADO,`${version}/estado/`);
    }
}

export const estadoReducer = createReducer(initialState,estadoReducerDef);
export const estadoConsultaReducer = createReducer(initialState,estadoConsultaReducerDef);