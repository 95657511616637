import {version} from './Version';
import { AnterosRemoteResource, GET, makeDefaultReduxObject, initialState, createReducer } from '@anterostecnologia/anteros-react-api';
const TABELA_PRECO_VL_MINIMO = 'tabelaPrecoVlMinimo';
const tabelaPrecoVlMinimoReducerDef = makeDefaultReduxObject(TABELA_PRECO_VL_MINIMO);
const tabelaPrecoVlMinimoConsultaReducerDef = makeDefaultReduxObject(TABELA_PRECO_VL_MINIMO.toUpperCase()+"_SEARCH");

export class TabelaPrecoValorMinimoResource extends AnterosRemoteResource {
    constructor(){
        super(TABELA_PRECO_VL_MINIMO,`${version}/tabelaPrecoQtMinima/`);
    }

    buscarQtMinimaTabelaPreco(
        idTabela,
        resourceName,
        page,
        size,
        user,
        fieldsToForceLazy
    ) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaQtMinimaTabelaPreco/${idTabela}?&page=${page}&size=${size}&fieldsToForceLazy=${fieldsToForceLazy}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
}

export const tabelaPrecoVlMinimoReducer = createReducer(initialState,tabelaPrecoVlMinimoReducerDef);
export const tabelaPrecoVlMinimoConsultaReducer = createReducer(initialState,tabelaPrecoVlMinimoConsultaReducerDef);