import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const TABELA_PRECO = 'tabelaPreco';
const tabelaPrecoReducerDef = makeDefaultReduxObject(TABELA_PRECO);
const tabelaPrecoConsultaReducerDef = makeDefaultReduxObject(TABELA_PRECO.toUpperCase()+"_SEARCH");

export class TabelaPrecoResource extends AnterosRemoteResource {
    constructor(){
        super(TABELA_PRECO,`${version}/tabelaPreco/`);
    }
}

export const tabelaPrecoReducer = createReducer(initialState,tabelaPrecoReducerDef);
export const tabelaPrecoConsultaReducer = createReducer(initialState,tabelaPrecoConsultaReducerDef);