import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const FILA_GEOCODE = 'filaGeocodificacao';
const filaGeocodificacaoReducerDef = makeDefaultReduxObject(FILA_GEOCODE);
const filaGeocodificacaoConsultaReducerDef = makeDefaultReduxObject(FILA_GEOCODE.toUpperCase()+"_SEARCH");

export class FilaGeocodificacaoResource extends AnterosRemoteResource {
    constructor(){
        super(FILA_GEOCODE,`${version}/filaGeocodificacao/`);
    }
}

export const filaGeocodificacaoReducer = createReducer(initialState,filaGeocodificacaoReducerDef);
export const filaGeocodificacaoConsultaReducer = createReducer(initialState,filaGeocodificacaoConsultaReducerDef);