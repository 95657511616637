import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const ROTA_ATENDIMENTO = 'rotaAtendimento';
const rotaAtendimentoReducerDef = makeDefaultReduxObject(ROTA_ATENDIMENTO);
const rotaAtendimentoConsultaReducerDef = makeDefaultReduxObject(ROTA_ATENDIMENTO.toUpperCase()+"_SEARCH");

export class RotaAtendimentoResource extends AnterosRemoteResource {
    constructor(){
        super(ROTA_ATENDIMENTO,`${version}/rotaComercial/`);
    }
}

export const rotaAtendimentoReducer = createReducer(initialState,rotaAtendimentoReducerDef);
export const rotaAtendimentoConsultaReducer = createReducer(initialState,rotaAtendimentoConsultaReducerDef);