export const SW_INIT = 'SW_INIT';
export const SW_UPDATE = 'SW_UPDATE';

export const actions = {
  initSW() {
    return { type: SW_INIT, payload: {} };
  },
  updateSW(reg) {
    return {
      type: SW_UPDATE,
      payload: { reg }
    };
  }
};

export default (
  state = {
    serviceWorkerInitialized: false, 
    serviceWorkerUpdated: false, 
    serviceWorkerRegistration: null, 
  },
  action
) => {
  switch (action.type) {
    case SW_INIT: {
      return { ...state, serviceWorkerInitialized: true };
    }
    case SW_UPDATE: {
      return {
        ...state, serviceWorkerUpdated: true, serviceWorkerRegistration: action.payload.reg        
      };
    }
    default: {
      return state;
    }
  }
};
