import React, { Component } from "react";
import PropTypes from "prop-types";
import { AnterosButton } from "@anterostecnologia/anteros-react-buttons";
import {
  AnterosForm,
  AnterosFormGroup,
  AnterosFullBackground,
} from "@anterostecnologia/anteros-react-containers";
import {
  AnterosSweetAlert,
  boundClass,
  AnterosUtils,
  AnterosResizeDetector
} from "@anterostecnologia/anteros-react-core";
import {
  AnterosEdit,
  AnterosPassword,AnterosCheckbox
} from "@anterostecnologia/anteros-react-editors";
import { AnterosImage } from "@anterostecnologia/anteros-react-image";
import {
  AnterosLabel,
  AnterosText,
} from "@anterostecnologia/anteros-react-label";
import { AnterosLinearProgressBar } from "@anterostecnologia/anteros-react-misc";
import { AnterosAlert } from "@anterostecnologia/anteros-react-notification";
import { connect } from "react-redux";
import { actions } from "../reducers/authenticationReducer";
import { AnterosList } from "@anterostecnologia/anteros-react-list";
import { AnterosLocalDatasource } from "@anterostecnologia/anteros-react-datasource";
import shallowCompare from "react-addons-shallow-compare";
import {
  userService,
  preferencesService,
} from "@anterostecnologia/anteros-react-api";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
var pjson = require("../../package.json");

const form = {
  display: "inline-block",
  width: "100%",
  maxWidth: "500px",
  textAlign: "left",
  transition: "all 0.4s ease",
  maxHeight: "fit-content",
};

const botaoEntrarStyle = {
  backgroundColor: "#4FBFCA",
  color: "#064E89",
  marginRight: "10px",
  marginTop: ".5rem",
  maxWidth: "110px",
  WebkitBoxShadow: "0 0 0 rgba(0, 0, 0, 0.16)",
  boxShadow: "0 0 0 rgba(0, 0, 0, 0.16)",
};

const PREFERENCES_KEY = "credentials_salescloud";
const REMINDME_KEY = "remindMe_salescloud";

@boundClass
class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userName: "",
      password: "",
      owner: undefined,
      showOwnerList: false,
      activeListIndex: -1,
      remindMe: null,
      error: undefined,
      selectedItem: undefined,
      loadingOwners: false,
      showSlide: true,
      data: [],
      update: Math.random(),
    };
    this.login = this.login.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.dsOwners = new AnterosLocalDatasource([]);
  }

  onKeyDown(event) {
    if (event.keyCode === 13) {
      if (this.state.userName && this.state.password) {
        this.login();
      }
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.consultaOwners(this.props);
      this.inputUserRef.setFocus();
    }, 100);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  login() {
    if (!this.state.owner) {
      AnterosSweetAlert("Selecione um proprietário.");
      return;
    }
    if (!this.state.userName) {
      AnterosSweetAlert("Informe o nome do usuário.");
      return;
    }
    if (!this.state.password) {
      AnterosSweetAlert("Informe a senha do usuário.");
      return;
    }

    let _this = this;
    _this.setState({ ..._this.state, error: undefined });
    let credentials = {
      username: this.state.userName.startsWith("admin")?"admin":this.state.userName,
      password: this.state.password,
      owner: this.state.owner,
    };
    this.props.dispatch(
      actions.validateOwnerAndLogin(credentials, (user, error) => {
        if (error) {
          _this.setState({ ..._this.state, error: error });
        } else {
          _this.loginSuccess(credentials, user);
        }
      })
    );
  }

  loginSuccess(credentials, user) {
    preferencesService.saveLoginCredentials(
      PREFERENCES_KEY,
      credentials,
      this.state.remindMe
    );
    preferencesService.saveRemindMe(REMINDME_KEY, this.state.remindMe);
    this.props.dispatch(actions.setUser(user));
    this.props.onLoginSuccess(user);
  }

  onChangeOwner(event, value) {
    this.setState({ ...this.state, owner: value });
  }

  onChangeUser(event, value) {
    clearInterval(this.intervalUser);
    let _this = this;
    this.setState({ ...this.state, userName: value }, () => {
      _this.intervalUser = setInterval(() => {
        _this.consultaOwners(this.props);
      }, 200);
    });
  }

  onChangePassword(event, value) {
    this.setState({ ...this.state, password: event.target.value });
  }

  onChangeRemindMe(value, checked, checkbox) {
    this.setState({ ...this.state, remindMe: checked });
  }
  componentWillReceiveProps(nextProps) {
    this.consultaOwners(nextProps);
  }

  componentWillMount() {
    if (this.state.remindMe === null) {
      let value = preferencesService.getPref(REMINDME_KEY);
      if (value) {
        this.setState({
          ...this.state,
          remindMe: JSON.parse(value),
        });
      } else {
        this.setState({ ...this.state, remindMe: false });
      }
    }
  }

  consultaOwners(props) {
    clearInterval(this.intervalUser);
    if (props.path) {
      let _this = this;
      this.setState({ ...this.state, loadingOwners: true });
      userService.getQueryOwners(
        props.path,
        (error) => {
          _this.updateOwnerList([]);
        },
        (list) => {
          if (list && list.length > 0) {
            this.updateOwnerList(list);
          }
        }
      );
    } else {
      if (
        this.state.userName &&
        ((this.state.userName.startsWith("admin") &&
          this.state.userName.includes("@")) ||
          this.validateEmail(this.state.userName))
      ) {
        let _this = this;
        this.setState({ ...this.state, loadingOwners: true });
        userService.getQueryOwnersByUser(
          _this.state.userName,
          (error) => {
            _this.updateOwnerList([]);
          },
          (list) => {
            _this.updateOwnerList(list);
          }
        );
      }
    }
  }

  validateEmail(email) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  }

  updateOwnerList(list) {
    let newList = [];
    newList = newList.concat(list);
    this.dsOwners.open(newList);
    let activeListIndex = -1;
    let owner = undefined;
    if (list.length === 1) {
      activeListIndex = 0;
      owner = this.dsOwners.fieldByName("uuid");
    }
    this.setState({
      ...this.state,
      showOwnerList: newList.length > 0,
      activeListIndex,
      owner,
      loadingOwners: false,
    });
  }

  onCloseAlert() {
    this.setState({ ...this.state, error: undefined });
  }

  clickList(event) {
    preferencesService.getLoginCredentials(
      PREFERENCES_KEY,
      event.target.textContent,
      (owner, userName, password, item) => {
        this.setState({
          ...this.state,
          owner: owner,
          userName: userName,
          password: password,
          selectedItem: item,
        });
      }
    );
  }

  removeCredentials(event) {
    preferencesService.removeCredentials(
      PREFERENCES_KEY,
      event.target.parentElement.textContent
    );
    this.setState({ ...this.state, update: Math.random() });
  }

  onSelectListItem(index, data) {
    this.dsOwners.gotoRecordByData(data);
    let owner = this.dsOwners.fieldByName("uuid");
    this.setState({
      ...this.state,
      owner,
      activeListIndex: index,
      update: Math.random(),
    });
  }

  onResize(width, height) {
    this.setState({
      ...this.state,
      showSlide: width > 1024 
    });
  }

  render() {
    const { loading } = this.state;
    const { authenticating } = this.props;

    return (
      <AnterosFullBackground
        color="#e6e5f2"
        custom="#4306AB"
        style={{
          overflowY: "hidden",
          display: "flex",
        }}
      >
        <AnterosResizeDetector
          handleWidth
          handleHeight
          onResize={this.onResize}
        />
        {loading && <AnterosLinearProgressBar />}
        <div style={{ height: "100%", width: "100%", minHeight: "640px" }}>
        {this.state.showSlide?<div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Splide options={{ arrows: 'slider',rewind: true, 
            autoplay:true, type:'loop', 
            interval:10000,
              width: "auto", height:"auto", speed:1000 }}>
              <SplideSlide>
                <img
                  alt=""
                  style={{
                    "-webkit-user-select": "none",
                    margin: "auto",
                    backgroundColor: "hsl(0, 0%, 90%)",
                    transition: "background-color 300ms;",
                    width:"100%",
                    height:"100%"
                  }}
                  src={require("../assets/img/slide1.png")}                  
                />
              </SplideSlide>
              <SplideSlide>
                <img
                  alt=""
                  style={{
                    "-webkit-user-select": "none",
                    margin: "auto",
                    backgroundColor: "hsl(0, 0%, 90%)",
                    transition: "background-color 300ms;",
                    width:"100%",
                    height:"100%"
                  }}
                  src={require("../assets/img/slide2.png")}
                />
              </SplideSlide>
              <SplideSlide>
                <img
                  alt=""
                  style={{
                    "-webkit-user-select": "none",
                    margin: "auto",
                    backgroundColor: "hsl(0, 0%, 90%)", 
                    transition: "background-color 300ms;",
                    width:"100%",
                    height:"100%"
                  }}
                  src={require("../assets/img/slide3.png")}
                />
              </SplideSlide>
              <SplideSlide>
                <img
                  alt=""
                  style={{
                    "-webkit-user-select": "none",
                    margin: "auto",
                    backgroundColor: "hsl(0, 0%, 90%)", 
                    transition: "background-color 300ms;",
                    width:"100%",
                    height:"100%"
                  }}
                  src={require("../assets/img/slide4.png")}
                />
              </SplideSlide>
            </Splide>
          </div>:null}
          <div
            className="flex-0 login-panel flex flex-all-center"
            style={{ borderRadius: "8px", backgroundColor: "white" }}
          >
            <div
              id="login-content"
              class="login-content flex flex-align-center flex-column"
            >
              <div className="flex flex-column flex-all-center">
                <div className="width100 flex flex-column flex-all-center login-inner-container">
                  <div style={{textAlign:'center', marginBottom:'10px'}}>
                    <AnterosImage
                      src={require("../assets/img/logo_login.svg")}
                      width="170px"
                      className="hidden-md"
                      style={{
                        zIndex: "10",
                      }}
                    />
                    <AnterosText
                      style={{
                        color: "#464D69",
                        zIndex: "10",
                      }}
                      text={"Versão " + pjson.version}
                    ></AnterosText>
                  </div>
                  <AnterosForm style={form}>
                    <AnterosLabel
                      caption="Informe suas credencias para acesso:"
                      small={12}
                      style={{
                        fontSize: "22px",
                        color: "#464D69",
                      }}
                    />
                    {this.state.showOwnerList ? (
                      <div
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          justifyItems: "center",
                          display: "flex",
                        }}
                      >
                        <AnterosList
                          height="170px"
                          horizontal
                          dataSource={this.dsOwners}
                          activeIndex={this.state.activeListIndex}
                          onSelectListItem={this.onSelectListItem}
                          showBorder={false}
                          style={{ overflowY: "auto", margin: "10px" }}
                          ref={(ref) => (this.ownerListRef = ref)}
                          component={OwnerItem}
                        />
                      </div>
                    ) : <SemOwnerSelecionado  active={false}
                    recordData={{imagemApresentacao:require("../assets/img/logo-placeholder.png"),
                    disabled:false}}/>}
                    <AnterosFormGroup row={false}>
                      <AnterosLabel
                        caption="Usuário"
                        small={2}
                        style={{
                          color: "#464D69",
                        }}
                      />
                      <AnterosEdit
                        ref = {(ref)=>this.inputUserRef = ref}
                        small={12}
                        large={12}
                        medium={12}
                        id="userName"
                        onChange={this.onChangeUser}
                        value={this.state.userName}
                        disabled={authenticating}
                        onKeyDown={this.onKeyDown}
                        icon="fal fa-user-circle"
                      />
                    </AnterosFormGroup>
                    <AnterosFormGroup row={false}>
                      <AnterosLabel
                        caption="Senha"
                        small={6}
                        large={6}
                        medium={6}
                        style={{
                          color: "#464D69",
                        }}
                      />
                      <AnterosPassword
                        small={12}
                        large={12}
                        medium={12}
                        id="password"
                        onChange={this.onChangePassword}
                        value={this.state.password}
                        disabled={authenticating}
                        onKeyDown={this.onKeyDown}
                        required
                      />
                    </AnterosFormGroup>
                    <AnterosFormGroup row={false}>
                      <AnterosAlert
                        danger
                        fill
                        isOpen={!this.state.error ? false : true}
                        autoCloseInterval={25000}
                        onClose={this.onCloseAlert}
                      >
                        {this.state.error}
                      </AnterosAlert>
                    </AnterosFormGroup>
                    <AnterosFormGroup row={false}>
                      <AnterosLabel
                        caption=""
                        small={6}
                        large={6}
                        medium={6}
                        style={{ color: "#464D69" }}
                      />
                      <AnterosCheckbox
                        small={12}
                        large={12}
                        medium={12}
                        id="chLogin"
                        style={{ color: "cornflowerblue !important" }}
                        value="Lembre-me."
                        checked={this.state.remindMe}
                        valueChecked={true}
                        onCheckboxChange={this.onChangeRemindMe}
                        valueUnchecked={false}
                      />
                    </AnterosFormGroup>
                    <AnterosFormGroup row={false}>
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          paddingLeft: "15px",
                        }}
                      >
                        <AnterosButton
                          block
                          medium
                          style={botaoEntrarStyle}
                          disabled={authenticating || this.state.loadingOwners}
                          caption={
                            authenticating || this.state.loadingOwners
                              ? "Aguarde..."
                              : "Entrar"
                          }
                          onButtonClick={this.login}
                        />{" "}
                        {/* <AnterosLabel
                          caption="Esqueceu sua senha?"
                          style={{
                            color: "#464D69",
                          }}
                        /> */}
                      </div>
                    </AnterosFormGroup>
                  </AnterosForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AnterosFullBackground>
    );
  }
}

class OwnerItem extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  convertFromBase64(value) {
    if (!value) {
      return "";
    }
    return atob(value);
  }

  onClick(event) {
    event.preventDefault();
    if (!this.props.disabled) {
      if (this.props.handleSelectItem) {
        this.props.handleSelectItem(this.props.index, this);
      }
      if (this.props.onSelectListItem) {
        this.props.onSelectListItem(this.props.index, this.props.recordData);
      }
    }
  }

  isBase64(str) {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  }

  isUrl(string) {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  }

  getFoto() {
    if (this.props.recordData.imagemApresentacao) {
      if (this.isBase64(this.props.recordData.imagemApresentacao)) {
        if (this.isUrl(atob(this.props.recordData.imagemApresentacao))) {
          return atob(this.props.recordData.imagemApresentacao);
        } else {
          return (
            "data:image;base64," + this.props.recordData.imagemApresentacao
          );
        }
      } else {
        return this.props.recordData.imagemApresentacao;
      }
    }
  }

  render() {
    let className = AnterosUtils.buildClassNames(
      "list-group-item-action list-group-item",
      this.props.showBorder ? "list-group-item-border" : "",
      this.props.active ? " active" : "",
      this.props.recordData.disabled ? " disabled" : ""
    );
    let style = {
      marginTop: "4px",
      backgroundColor: "#eff1f3",
      border: "1px solid #d5dbe1",
      borderRadius: "6px",
      height: "150px",
      minWidth: "150px",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      marginRight: "10px",
    };
    if (this.props.active) {
      style = {
        marginTop: "4px",
        backgroundColor: "#d5ecf5",
        border: "2px solid yellow",
        borderRadius: "6px",
        height: "150px",
        minWidth: "150px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        color: "rgb(6, 78, 137)",
        marginRight: "10px",
      };
    }

    return (
      <li
        style={style}
        ref={(ref) => (this.item = ref)}
        id={"item" + this.props.id}
        className={className}
        onClick={this.onClick}
      >
        <img alt="#" style={{ width: "100px" }} src={this.getFoto()} />
      </li>
    );
  }
}

class SemOwnerSelecionado extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  convertFromBase64(value) {
    if (!value) {
      return "";
    }
    return atob(value);
  }

  onClick(event) {
    event.preventDefault();
    if (!this.props.disabled) {
      if (this.props.handleSelectItem) {
        this.props.handleSelectItem(this.props.index, this);
      }
      if (this.props.onSelectListItem) {
        this.props.onSelectListItem(this.props.index, this.props.recordData);
      }
    }
  }

  isBase64(str) {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  }

  isUrl(string) {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  }

  getFoto() {
    if (this.props.recordData.imagemApresentacao) {
      if (this.isBase64(this.props.recordData.imagemApresentacao)) {
        if (this.isUrl(atob(this.props.recordData.imagemApresentacao))) {
          return atob(this.props.recordData.imagemApresentacao);
        } else {
          return (
            "data:image;base64," + this.props.recordData.imagemApresentacao
          );
        }
      } else {
        return this.props.recordData.imagemApresentacao;
      }
    }
  }

  render() {
    let style = {
      marginTop: "4px",
      backgroundColor: "#eff1f3",
      border: "1px solid #d5dbe1",
      borderRadius: "6px",
      height: "150px",
      minWidth: "150px",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      marginRight: "10px",
    };
    if (this.props.active) {
      style = {
        marginTop: "4px",
        backgroundColor: "#d5ecf5",
        border: "2px solid yellow",
        borderRadius: "6px",
        height: "150px",
        minWidth: "150px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        color: "rgb(6, 78, 137)",
        marginRight: "10px",
      };
    }

    return (
      <div
        style={style}
        ref={(ref) => (this.item = ref)}
        id={"item" + this.props.id}
        onClick={this.onClick}
      >
        <img alt="#" style={{ width: "80%" }} src={this.getFoto()} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authenticationReducer.user,
    authenticating: state.authenticationReducer.authenticating,
    authenticated: state.authenticationReducer.authenticated,
  };
};

export default connect(mapStateToProps, null)(LoginContainer);

LoginContainer.propTypes = {
  onLoginSuccess: PropTypes.func,
};

LoginContainer.defaultProps = {
  onLoginSuccess: (f) => f,
};
