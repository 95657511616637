export const ActionType = {
    UPDATE_VIEWPORT: 'UPDATE_VIEWPORT',
    RESET_VIEWPORT: 'RESET_VIEWPORT',
}

export const actions = {
    updateViewport: viewport => ({
        type: ActionType.UPDATE_VIEWPORT,
        value: viewport,
    }),

    resetViewport: () => ({
        type: ActionType.RESET_VIEWPORT,
    })
}

export const initialViewportState = {
    isDirty: false,
    center: [0, 0],
    zoom: 2,
};



const clientReducer = (state = initialViewportState, action) => {
    switch (action.type) {
        case ActionType.UPDATE_VIEWPORT: {
            if (!action.value || !action.value.zoom || !action.value.center) return state;
            return {
                isDirty: true,
                zoom: action.value.zoom,
                center: action.value.center
            };
        }
        case ActionType.RESET_VIEWPORT: {
            return initialViewportState;
        }
        default:
            return state;
    }
};

export default clientReducer;