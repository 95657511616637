import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const PESSOA_EDICAO = 'pessoaEdicao';
const pessoaEdicaoReducerDef = makeDefaultReduxObject(PESSOA_EDICAO);
const pessoaEdicaoConsultaReducerDef = makeDefaultReduxObject(PESSOA_EDICAO.toUpperCase()+"_SEARCH");

export class PessoaEdicaoResource extends AnterosRemoteResource {
    constructor(){
        super(PESSOA_EDICAO,`${version}/abstractPessoa/`);
    }

}

export const pessoaEdicaoReducer = createReducer(initialState,pessoaEdicaoReducerDef);
export const pessoaEdicaoConsultaReducer = createReducer(initialState,pessoaEdicaoConsultaReducerDef);