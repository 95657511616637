export const SET_PATH_INITIAL = 'SET_PATH_INITIAL';

export const actions = {
  setPathInitial(path) {
    return { type: SET_PATH_INITIAL, payload: path };
  }
};

export default (
  state = {
    path: undefined
  },
  action
) => {
  switch (action.type) {
    case SET_PATH_INITIAL: {
      return { ...state, path: action.payload };
    }
    default: {
      return state;
    }
  }
};
