import {actions as actionsServer} from './wsServerReducer';
import {actions as actionsRoute} from './wsRouteReducer';

export const WebSocketConnectionStatus = {
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
    ERROR: 'ERROR',
}

export const ActionType = {
    WS_CONNECT: 'WS_CONNECT',
    WS_CONNECT_SUCCESS: 'WS_CONNECT_SUCCESS',
    WS_CONNECT_FAILURE: 'WS_CONNECT_FAILURE'
}

export const actions = {
    initWsConnection : () => ({
        type: ActionType.WS_CONNECT,
    }),

    wsConnectionSuccess : () => ({
        type: ActionType.WS_CONNECT_SUCCESS,
    }),

    wsConnectionFailure : error => ({
        type: ActionType.WS_CONNECT_FAILURE,
        error,
    }),
    connectClient : () => (dispatch, getState, client) => {
          dispatch(actions.initWsConnection());
          client.connect(
            () => {
              dispatch(actions.wsConnectionSuccess());
              client.subscribeToServerInfo((serverInfo) => {
                dispatch(actionsServer.serverInfo(serverInfo));
              });
              client.subscribeToRoute((plan) => {
                dispatch(actionsRoute.updateRoute(plan));
              });
            },
            (err) => {
              dispatch(actions.wsConnectionFailure(JSON.stringify(err)));
              setTimeout(() => dispatch(actions.connectClient()), 1000);
            },
          );
        }
}

const wsReducer = (state = WebSocketConnectionStatus.CLOSED, action) => {
    switch (action.type) {
        case ActionType.WS_CONNECT_SUCCESS: {
            return WebSocketConnectionStatus.OPEN;
        }
        case ActionType.WS_CONNECT_FAILURE: {
            return WebSocketConnectionStatus.ERROR;
        }
        default:
            return state;
    }
};

export default wsReducer;