import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const PEDIDO_EDICAO = 'pedidoEdicao';
const pedidoEdicaoReducerDef = makeDefaultReduxObject(PEDIDO_EDICAO);

export class PedidoEdicaoResource extends AnterosRemoteResource {
    constructor(){
        super(PEDIDO_EDICAO,`${version}/pedido/`);
    }
}

export const pedidoEdicaoReducer = createReducer(initialState,pedidoEdicaoReducerDef);
