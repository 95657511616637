import {version} from './Version';
import { AnterosRemoteResource,makeDefaultReduxObject, initialState, createReducer } from '@anterostecnologia/anteros-react-api';
const CATALOGO_PRODUTO = 'catalogoImagemProduto';
const catalogoImagemProdutoReducerDef = makeDefaultReduxObject(CATALOGO_PRODUTO);
const catalogoImagemProdutoConsultaReducerDef = makeDefaultReduxObject(CATALOGO_PRODUTO.toUpperCase()+"_SEARCH");

export class CatalogoImagemProdutoResource extends AnterosRemoteResource {
    constructor(){
        super(CATALOGO_PRODUTO,`${version}/catalogoImagemProduto/`);
    }
}

export const catalogoImagemProdutoReducer = createReducer(initialState,catalogoImagemProdutoReducerDef);
export const catalogoImagemProdutoConsultaReducer = createReducer(initialState,catalogoImagemProdutoConsultaReducerDef);