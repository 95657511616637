import {
    version
} from './Version';
import { AnterosRemoteResource} from '@anterostecnologia/anteros-react-api';
import { makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const ATENDIMENTO_EDICAO = 'atendimentoEdicao';
const atendimentoEdicaoReducerDef = makeDefaultReduxObject(ATENDIMENTO_EDICAO);

export class AtendimentoEdicaoResource extends AnterosRemoteResource {

    constructor() {
        super(ATENDIMENTO_EDICAO,`${version}/atendimento/`)
    }

}

export const atendimentoEdicaoReducer = createReducer(initialState,atendimentoEdicaoReducerDef);
