import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const SITUACAO_COMERCIAL = 'situacaoComercial';
const situacaoComercialReducerDef = makeDefaultReduxObject(SITUACAO_COMERCIAL);
const situacaoComercialConsultaReducerDef = makeDefaultReduxObject(SITUACAO_COMERCIAL.toUpperCase()+"_SEARCH");

export class SituacaoComercialResource extends AnterosRemoteResource {
    constructor(){
        super(SITUACAO_COMERCIAL,`${version}/situacaoComercial/`);
    }
}

export const situacaoComercialReducer = createReducer(initialState,situacaoComercialReducerDef);
export const situacaoComercialConsultaReducer = createReducer(initialState,situacaoComercialConsultaReducerDef);