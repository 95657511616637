import {version} from './Version';
import { AnterosRemoteResource, GET, POST, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const TABPRECO_PRODUTO = 'tabelaPrecoProduto';
const tabelaPrecoProdutoReducerDef = makeDefaultReduxObject(TABPRECO_PRODUTO);
const tabelaPrecoProdutoConsultaReducerDef = makeDefaultReduxObject(TABPRECO_PRODUTO.toUpperCase()+"_SEARCH");

export class TabelaPrecoProdutoResource extends AnterosRemoteResource {
    constructor(){
        super(TABPRECO_PRODUTO,`${version}/tabelaPrecoProduto/`);
    }

    buscarTabelaPrecoProdutoPorQtMinima(resourceName, tabelaPrecoId, produtoId, qt, user, fieldsToForceLazy = "") {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaPorQtMinima?produtoId=${produtoId}&tabelaPrecoId=${tabelaPrecoId}&qt=${qt}&fieldsToForceLazy=${fieldsToForceLazy}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    buscarProdutoPorSKUTabelaPreco(resourceName, id, produto, sku, user) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaProdutoPorSKU/${id}?produto=${produto}&sku=${sku}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    findWithFilterByTabelaPreco(resourceName, idTabela, data, page, size, user, fieldsToForceLazy = "") {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}findWithFilterByTabelaPreco/${idTabela}?page=${page}&size=${size}&fieldsToForceLazy=${fieldsToForceLazy}`,
            data: data,
            method: POST
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    findByTabelaPrecoMultipleFields(
        resourceName,
        idTabela,
        filter,
        fields,
        page,
        size,
        sort,
        user,
        fieldsToForceLazy = ""
    ) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}findByTabelaPrecoMultipleFields/${idTabela}?filter=${encodeURIComponent(filter)}&fields=${fields}&page=${page}&size=${size}&sort=${sort}&fieldsToForceLazy=${fieldsToForceLazy}`,
            method: POST
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    findTabelaPrecoMultipleFieldsByProduto(
        resourceName,
        idTabela,
        filter,
        fields,
        page,
        size,
        sort,
        user,
        fieldsToForceLazy = ""
    ) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}findByTabelaPrecoMultipleFieldsPorProduto/${idTabela}?filter=${encodeURIComponent(filter)}&fields=${fields}&page=${page}&size=${size}&sort=${sort}&fieldsToForceLazy=${fieldsToForceLazy}`,
            method: POST
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    buscaProdutosTabelaPreco(
        idTabela,
        resourceName,
        page,
        size,
        user,
        fieldsToForceLazy
    ) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaProdutosTabelaPreco/${idTabela}?&page=${page}&size=${size}&fieldsToForceLazy=${fieldsToForceLazy}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
}

export const tabelaPrecoProdutoReducer = createReducer(initialState,tabelaPrecoProdutoReducerDef);
export const tabelaPrecoProdutoConsultaReducer = createReducer(initialState,tabelaPrecoProdutoConsultaReducerDef);