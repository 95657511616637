import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const CLASSIF_ATENDIMENTO = 'classificacaoAtendimento';
const classificacaoAtendimentoReducerDef = makeDefaultReduxObject(CLASSIF_ATENDIMENTO);
const classificacaoAtendimentoConsultaReducerDef = makeDefaultReduxObject(CLASSIF_ATENDIMENTO.toUpperCase()+"_SEARCH");

export class ClassificacaoAtendimentoResource extends AnterosRemoteResource {
    constructor(){
        super(CLASSIF_ATENDIMENTO,`${version}/classificacaoAtendimento/`);
    }
}

export const classificacaoAtendimentoReducer = createReducer(initialState,classificacaoAtendimentoReducerDef);
export const classificacaoAtendimentoConsultaReducer = createReducer(initialState,classificacaoAtendimentoConsultaReducerDef);