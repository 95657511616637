import { createStore, applyMiddleware, combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import reduxReset from 'redux-reset';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';

import authenticationReducer from '../reducers/authenticationReducer';
import ondeEstaMinhaEquipeReducer from '../reducers/ondeEstaMinhaEquipeReducer';
import clientReducer from '../reducers/wsClientReducer';
import routeReducer from '../reducers/wsRouteReducer';
import serverReducer from '../reducers/wsServerReducer';
import wsReducer from '../reducers/wsReducer';
import WebSocketClient from '../websocket/WebSocketClient';
import swReducer from "../reducers/swReducer";
import layoutReducer from '../reducers/layoutReducer';
import chatReducer from '../reducers/chatReducer';
import appReducer from '../reducers/appReducer';
import {getAllReducersFromResources} from '../resources/Resources';

const appReducers = combineReducers({
  authenticationReducer,
  ondeEstaMinhaEquipeReducer,
  connectionStatus: wsReducer,
  serverInfo: serverReducer,
  plan: routeReducer,
  userViewport: clientReducer,
  swReducer,
  layoutReducer,
  chatReducer,
  appReducer,
  ...getAllReducersFromResources()
});

const rootReducer = (state, action) => {
  // quando uma ação de logout é despachada ela irá resetar o estado redux
  if (action.type === 'auth-module/SELECT_COMPANY') {
    // mantemos uma referência das chaves que queremos manter
    // outras chaves serão passadas como indefinidas e isso chamará
    //redutores com estado inicial
    const { authenticationReducer, appReducer } = state;

    state = { authenticationReducer, appReducer };
  }

  return appReducers(state, action);
};


export function configureStore(initialState, socketUrl) {
  const webSocketClient = new WebSocketClient(socketUrl);

  const middlewares = [thunk.withExtraArgument(webSocketClient), createLogger(), promise()];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer, reduxReset()];
  const composedEnhancers = composeWithDevTools(...enhancers);

  return createStore(
    rootReducer,
    initialState,
    composedEnhancers
  );
}
