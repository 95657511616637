import {version} from './Version';
import {AnterosRemoteResource, GET, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const PRODUTO_EDICAO = 'produtoEdicao';
const produtoEdicaoReducerDef = makeDefaultReduxObject(PRODUTO_EDICAO);

export class ProdutoEdicaoResource extends AnterosRemoteResource {
    constructor(){
        super(PRODUTO_EDICAO,`${version}/produto/`);
    }

    buscaProdutoNaTabelaPreco(resourceName, value, tabelaPreco, user, fieldsToForceLazy = "") {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaProdutoDaTabela/${value}?tabelaPreco=${tabelaPreco.join()}&fieldsToForceLazy=${fieldsToForceLazy}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    buscaProdutosPorTabelaPreco(resourceName, id, page, size, user, fieldsToForceLazy = "") {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaProdutosPorTabela?tabelaPreco=${id.join()}&page=${page}&size=${size}&fieldsToForceLazy=${fieldsToForceLazy}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    buscaProdutosPorCatalogo(resourceName, id, page, size, user, fieldsToForceLazy = "") {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaProdutosPorCatalogo?catalogo=${id}&page=${page}&size=${size}&fieldsToForceLazy=${fieldsToForceLazy}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    buscaProdutosPorTabelaECatalogo(resourceName, idTabela, idCatalogo, page, size, user, fieldsToForceLazy = "") {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaProdutosPorTabelaCatalogo?tabelaPreco=${idTabela.join()}&catalogo=${idCatalogo}&page=${page}&size=${size}&fieldsToForceLazy=${fieldsToForceLazy}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
}

export const produtoEdicaoReducer = createReducer(initialState,produtoEdicaoReducerDef); 
