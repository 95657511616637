import React from 'react';
import ReactDOM from 'react-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
import App from './App.jsx';
import * as serviceWorker from './service_worker/serviceWorker';
import {configureStore} from './store/index.jsx';
import { Provider } from 'react-redux';

// import { SW_INIT, SW_UPDATE } from './types';

window.React = React;

const store = configureStore({},
  "https://versatil-vrp.relevantsolutions.com.br:14106/api/vrp-websocket");

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// serviceWorker.register({
//   onSuccess: () => store.dispatch({ type: SW_INIT }),
//   onUpdate: registration =>
//     store.dispatch({ type: SW_UPDATE, payload: registration }),
// });

serviceWorker.unregister();
