import {version} from './Version';
import { AnterosRemoteResource, GET, POST } from '@anterostecnologia/anteros-react-api';
import {makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const ENDERECO = 'endereco';
const enderecoReducerDef = makeDefaultReduxObject(ENDERECO);
const enderecoConsultaReducerDef = makeDefaultReduxObject(ENDERECO.toUpperCase()+"_SEARCH");

export class EnderecoResource extends AnterosRemoteResource {
    constructor(){
        super(ENDERECO,`${version}/endereco/`);
    }

    findAllEnderecos(resourceName, page, size, sort, user, fieldsToForceLazy, withGeodata) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let url = sort ? `${_resourceName}findAll?page=${page}&size=${size}&sort=${sort}&fieldsToForceLazy=${fieldsToForceLazy}&withGeodata=${withGeodata}` : `${resourceName}findAll?page=${page}&size=${size}&fieldsToForceLazy=${fieldsToForceLazy}&withGeodata=${withGeodata}`;
        let result = {
            url: url,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    findWithFilterEndereco(resourceName, filter, page, size, user, fieldsToForceLazy, withGeodata) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}findWithFilter?page=${page}&size=${size}&fieldsToForceLazy=${fieldsToForceLazy}&withGeodata=${withGeodata}`,
            data: filter,
            method: POST
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
}

export const enderecoReducer = createReducer(initialState,enderecoReducerDef);
export const enderecoConsultaReducer = createReducer(initialState,enderecoConsultaReducerDef);