import * as XMPP from 'stanza';

export const CREATE_CLIENT = 'CREATE_CLIENT';
export const DESTROY_CLIENT = 'DESTROY_CLIENT';
export const SET_IS_CONNECTED = 'SET_IS_CONNECTED';
export const SET_ROSTERS = 'SET_ROSTERS';
export const SET_ROSTER_STATUS = 'SET_ROSTER_STATUS';
export const SET_ROSTER_UNREAD = 'SET_ROSTER_UNREAD';
export const SET_TARGET_JID = 'SET_TARGET_JID';
export const ADD_CHAT_HISTORY = 'ADD_CHAT_HISTORY';

const removeDuplicates = (myArr, prop) => {
    return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
}

export const actions = {
    createClient(username, pwd) {
        return {
            type: CREATE_CLIENT,
            payload: {
                client: XMPP.createClient({
                    jid: username,
                    password: pwd,
                    transports: {
                        bosh: 'https://chat.sendsales.com.br/bosh/'
                    }
                })
            }
        };
    },
    destroyClient(client) {
        client.disconnect()
        return {
            type: DESTROY_CLIENT,
            payload: {
                client: undefined,
                isConnected: false
            }
        };
    },
    setIsConnected(isConnected) {
        return {
            type: SET_IS_CONNECTED,
            payload: {
                isConnected
            }
        }
    },
    setRosters(rosters) {
        return {
            type: SET_ROSTERS,
            payload: {
                rosters
            }
        }
    },
    setRosterStatus(jid, status) {
        return {
            type: SET_ROSTER_STATUS,
            payload: {
                jid,
                status
            }
        }
    },
    setRosterUnread(jid, unread) {
        return {
            type: SET_ROSTER_UNREAD,
            payload: {
                jid,
                unread
            }
        }
    },
    setTargetJid(jid) {
        return {
            type: SET_TARGET_JID,
            payload: {
                jid
            }
        }
    },
    addChatHistory(jid, history) {
        return {
            type: ADD_CHAT_HISTORY,
            payload: {
                jid,
                history
            }
        }
    }
}

export default (state = { client: undefined, isConnected: false, rosters: {}, targetJid: undefined, history: {} }, action) => {
    switch (action.type) {
        case CREATE_CLIENT:
            return { ...state, client: action.payload.client };
        case DESTROY_CLIENT:
            return { ...state, client: action.payload.client };
        case SET_IS_CONNECTED:
            return { ...state, isConnected: action.payload.isConnected };
        case SET_ROSTERS:
            let new_rosters = state.rosters;
            for (let index = 0; index < action.payload.rosters.length; index++) {
                const roster = action.payload.rosters[index];
                new_rosters[roster.jid] = {
                    ...new_rosters[roster.jid],
                    vcard: new_rosters[roster.jid] && new_rosters[roster.jid].vcard ? new_rosters[roster.jid].vcard : roster.vcard,
                    status: new_rosters[roster.jid] && new_rosters[roster.jid].status ? new_rosters[roster.jid].status : 'offline',
                    unread: new_rosters[roster.jid] && new_rosters[roster.jid].unread ? new_rosters[roster.jid].unread : false,
                };
            }
            return { ...state, rosters: new_rosters }
        case SET_ROSTER_STATUS:
            let rosters_status = state.rosters;
            if (!rosters_status[action.payload.jid]) {
                rosters_status[action.payload.jid] = {};
            }
            rosters_status[action.payload.jid].status = action.payload.status
            return { ...state, rosters: rosters_status };
        case SET_ROSTER_UNREAD:
            let rosters_unread = state.rosters;
            if (!rosters_unread[action.payload.jid]) {
                rosters_unread[action.payload.jid] = {};
            }
            rosters_unread[action.payload.jid].unread = action.payload.unread
            return { ...state, rosters: rosters_unread };
        case SET_TARGET_JID:
            return { ...state, targetJid: action.payload.jid };
        case ADD_CHAT_HISTORY:
            let new_history = state.history
            if (new_history[action.payload.jid]) {
                new_history[action.payload.jid] = [...new_history[action.payload.jid], ...action.payload.history];
            } else {
                new_history[action.payload.jid] = [...action.payload.history];
            }
            new_history[action.payload.jid] = removeDuplicates(new_history[action.payload.jid], 'id');
            return { ...state, history: new_history };
        default:
            return state;
    }
}

