import {
    versatilBaseURL,
    authBaseURL,
    versatilSocialBaseURL,
    versatilSaaSBaseURL
  } from '../constantes';
  import {authService, userService, preferencesService, tokenService, AnterosRemoteApi as remoteApi} from '@anterostecnologia/anteros-react-api';
  const apiVersion = 'v1';
  const apiSocialVersion = 'v1';
  
  const config = {
      clientId:'versatil-salescloud',
      clientSecret:'LZr4PfAp9ut5Fr8AfrkxeHBSaWKdwK',
      clientIdOwner:'versatil-saas',
      clientSecretOwner:'y2Rwq9jhU7Y8Fjm5vcanSqQAkA52jW',
      secretKey : 'VGgkWzkrRmVxNDJoNE5aNA==',
      url_baseSaas: versatilSaaSBaseURL,
      url_token: authBaseURL,
      token: '/oauth/token',
      apiVersion: apiVersion,
      url_user_with_owner: `${versatilBaseURL}/${apiVersion}`,
      url_owner: `${versatilBaseURL}/${apiVersion}`,
      getUser: '/user/getUserByLogin',
      getOwner: '/empresa/buscarProprietario/',
      url_social: `${versatilSocialBaseURL}/${apiSocialVersion}/social/socialUser/getUserSocialByLogin`
  }
  
  authService.setConfiguration(config);
  tokenService.setConfiguration(config);
  userService.setConfiguration(config);
  preferencesService.setConfiguration(config);
  
  const initialState = {
      authenticating: false,
      authenticated: false,
      error: null,
      user: null,
  };
  
  const CONFIG = 'auth-module/CONFIG';
  const ACTION_LOGIN_PENDING = 'auth-module/LOGIN_PENDING';
  const ACTION_LOGIN_SUCCESS = 'auth-module/LOGIN_SUCCESS';
  const ACTION_LOGIN_ERROR = 'auth-module/LOGIN_ERROR';
  const ACTION_LOGOUT = 'auth-module/LOGOUT';
  const ACTION_GET_USER_PENDING = 'auth-module/GET_USER_PENDING';
  const ACTION_GET_USER_SUCCESS = 'auth-module/GET_USER_SUCCESS';
  const ACTION_SET_USER = 'auth-module/ACTION_SET_USER';
  const ACTION_GET_USER_ERROR = 'auth-module/GET_USER_ERROR';
  const ACTION_SELECT_COMPANY = 'auth-module/SELECT_COMPANY';
  const ACTION_UNSELECT_COMPANY = 'auth-module/UNSELECT_COMPANY';
  const CLEAR_AUTHENTICATION = 'CLEAR_AUTHENTICATION';
  
  export const actions = {
      loginStart() {
          return {
              type: ACTION_LOGIN_PENDING
          };
      },
      loginSuccess(user) {
          return {
              type: ACTION_LOGIN_SUCCESS,
              payload: {
                  user
              }
          };
      },
      loginError(error) {
          return {
              type: ACTION_LOGIN_ERROR,
              payload: {
                  error
              }
          };
      },
      selectCompany(company) {
          return {
              type: ACTION_SELECT_COMPANY,
              payload: {
                  company
              }
          };
      },
      unSelectCompany() {
          return {
              type: ACTION_UNSELECT_COMPANY,
              payload: {}
          };
      },
      clear() {
          return {
              type: CLEAR_AUTHENTICATION,
              payload: {}
          };
      },
      getUserStart() {
          return {
              type: ACTION_GET_USER_PENDING
          };
      },
      getUserSuccess(user) {
          return {
              type: ACTION_GET_USER_SUCCESS,
              payload: {
                  user
              }
          };
      },
      setUser(user) {
          return {
              type: ACTION_SET_USER,
              payload: {
                  user
              }
          };
      },
      getUserError(error) {
          return {
              type: ACTION_GET_USER_ERROR,
              payload: {
                  error
              }
          };
      },
      validateOwnerAndLogin(credentials, callback) {
          if (!credentials.owner) {
              return (dispatch, getState) => {
                  dispatch(actions.loginStart());
                  dispatch(actions.login(credentials, undefined, callback));
              }
          } else {
              return (dispatch, getState) => {
                  dispatch(actions.loginStart());
                  userService.validateOwner(credentials, (error)=>{
                      callback({},error);
                      dispatch(actions.loginError(error));
                  }, (data)=>{
                      dispatch(actions.login(credentials, data, callback));
                  });
              };
          }
      },
      login(credentials, ownerUrl, callback) {
          return (dispatch, getState) => {
              return authService.login(ownerUrl,credentials,(error)=>{
                  callback({},error);
                  dispatch(actions.loginError(error));
              }, (data)=>{
                  dispatch(actions.getUserInformation(credentials, data, ownerUrl, callback));
              });
          };
      },
      getUserInformation(credentials, token, ownerUrl, callback) {
          return (dispatch, getState) => {
              return userService.getUserInformation(credentials, token, ownerUrl, (error)=>{
                  callback({}, error);
                  dispatch(actions.loginError(error));
              }, (data)=>{
                  dispatch(
                    actions.getRemoteAccessTokenOwner(credentials, data, ownerUrl, callback)
                  );
              });
          };
      },
      getUserSocial(credentials, token, profile, ownerUrl, callback) {
          return (dispatch, getState) => {
              return userService.getUserSocial(credentials,token,profile,ownerUrl,(error)=>{
                  callback({}, error);
                  dispatch(actions.loginError(error));
              }, (data)=>{
                  if (!credentials.owner) {
                      dispatch(
                          actions.loginSuccess({
                              token: token,
                              owner: undefined,
                              profile: {...profile, userSocial: data}
                          })
                      );
                      callback({
                          token: token,
                          profile: {...profile, userSocial: data},
                          owner: undefined
                      });
                  } else {
                      profile = {...profile, userSocial: data};
                      dispatch(
                          actions.getRemoteAccessTokenOwner(credentials, profile, ownerUrl, callback)
                      );
                  }
              });
          };
      },
      getRemoteAccessTokenOwner(credentials, profile, ownerUrl, callback) {
          return (dispatch, getState) => {
              return tokenService.getRemoteAccessTokenSaaS(credentials,(error)=>{
                 callback({},error);
                 dispatch(actions.loginError(error));     
              }, (data)=>{
                  dispatch(actions.getOwnerInfo(credentials, data, profile, ownerUrl, callback));
              });
          };
      },
      getOwnerInfo(credentials, token, profile, ownerUrl, callback) {
          return (dispatch, getState) => {
              return userService.getOwnerInfo(credentials,token,profile,ownerUrl,(error)=>{
                  callback({}, error);
                  dispatch(
                      actions.loginError(error)
                  );
              }, (data)=>{
                  remoteApi.setCredentials(credentials);
                  remoteApi.setBaseURL(data.urlAPI);
                  dispatch(
                      actions.loginSuccess({
                          token: token,
                          owner: data,
                          profile: {...profile,login:credentials.username},
                      })
                  );
                  callback({
                      token: token,
                      profile: {...profile,login:credentials.username},
                      owner: data
                  });
              })
          };
      }
  };
  
  export default (state = initialState, action) => {
      switch (action.type) {
          case CONFIG:
              return {
                  ...state, config: action.payload
              };
          case ACTION_LOGIN_PENDING: {
              return {
                  ...state,
                  authenticating: true,
                  authenticated: false,
                  user: null,
                  error: null
              };
          }
          case ACTION_LOGIN_ERROR: {
              return {
                  ...state,
                  authenticating: false,
                  authenticated: false,
                  user: null,
                  error: action.payload.error
              };
          }
          case ACTION_LOGIN_SUCCESS: {
              return {
                  ...state,
                  authenticating: false,
                  authenticated: true,
                  error: null,
                  user: action.payload.user
              };
          }
          case ACTION_SET_USER: {
              return {
                  ...state,
                  user: action.payload.user
              };
          }
          case ACTION_LOGOUT: {
              return {
                  ...state,
                  authenticating: false,
                  authenticated: false,
                  error: null,
                  user: {
                      token: null,
                      profile: null
                  }
              };
          }
          case ACTION_SELECT_COMPANY: {
              return {
                  ...state,
                  user: {
                      ...state.user,
                      company: action.payload.company
                  }
              };
          }
          case ACTION_UNSELECT_COMPANY: {
              return {
                  ...state,
                  user: {
                      ...state.user,
                      company: undefined
                  }
              };
          }
          case CLEAR_AUTHENTICATION: {
              return {
                  ...state
              }
          }
          default:
              return {
                  ...state
              };
      }
  };
  