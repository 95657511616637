import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const COMPROMISSO = 'compromisso';
const compromissoReducerDef = makeDefaultReduxObject(COMPROMISSO);
const compromissoConsultaReducerDef = makeDefaultReduxObject(COMPROMISSO.toUpperCase()+"_SEARCH");

export class CompromissoResource extends AnterosRemoteResource {
    constructor(){
        super(COMPROMISSO,`${version}/compromisso/`);
    }
}

export const compromissoReducer = createReducer(initialState,compromissoReducerDef);
export const compromissoConsultaReducer = createReducer(initialState,compromissoConsultaReducerDef);