import {version} from './Version';
import { AnterosRemoteResource, GET, POST } from '@anterostecnologia/anteros-react-api';
import {makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const CATALOGO_IMAGEM = 'catalogoImagem';
const catalogoImagemReducerDef = makeDefaultReduxObject(CATALOGO_IMAGEM);
const catalogoImagemConsultaReducerDef = makeDefaultReduxObject(CATALOGO_IMAGEM.toUpperCase()+"_SEARCH");

export class CatalogoImagemResource extends AnterosRemoteResource {
    constructor(){
        super(CATALOGO_IMAGEM,`${version}/catalogoImagem/`);
    }

    buscarImagensCatalogo(resourceName, id, page, size, user, fieldsToForceLazy = "") {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaImagensCatalogo/${id}?page=${page}&size=${size}&fieldsToForceLazy=${fieldsToForceLazy}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    updateSequencia(resourceName, idCatalogo, data, user) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}updateSequencia/${idCatalogo}`,
            data: data,
            method: POST
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
}

export const catalogoImagemReducer = createReducer(initialState,catalogoImagemReducerDef);
export const catalogoImagemConsultaReducer = createReducer(initialState,catalogoImagemConsultaReducerDef);