import {version} from './Version';
import { AnterosRemoteResource, GET, makeDefaultReduxObject, initialState, createReducer } from '@anterostecnologia/anteros-react-api';
const PRODUTO = 'produto';
const produtoConsultaReducerDef = makeDefaultReduxObject(PRODUTO.toUpperCase()+"_SEARCH");

const _actions = {
    setDatasource(dataSource) {
      return { type: 'SET_DATASOURCE_PRODUTO', payload: { dataSource } };
    },
    setFilter(currentFilter, activeFilterIndex) {
      return {
        type: 'SET_FILTER_PRODUTO',
        payload: { currentFilter, activeFilterIndex }
      };
    },
    clear() {
      return {
        type: 'CLEAR_PRODUTO',
        payload: {}
      };
    },
    setDatasourceEdition(dataSource) {
        return { type: 'SET_DATASOURCE_PRODUTO_EDICAO', payload: { dataSource } };
      },
    setNeedRefresh() {
      return {
        type: 'SET_NEEDREFRESH_PRODUTO',
        payload: { needRefresh: true }
      }
    }
  };

const produtoReducerDef = {
    'SET_DATASOURCE_PRODUTO': (state, payload) => ({
        ...state,
        dataSource: payload.dataSource,
        needRefresh: false
    }),
    'SET_DATASOURCE_PRODUTO_EDICAO': (state, payload) => ({
        ...state, dataSourceEdicao: payload.dataSource
     }),
    'SET_FILTER_PRODUTO': (state, payload) => ({
        ...state,
        currentFilter: payload.currentFilter,
        activeFilterIndex: payload.activeFilterIndex
    }),
    'CLEAR_PRODUTO': { initialState },
    'SET_NEEDREFRESH_PRODUTO': (state, payload) => ({
        ...state,
        needRefresh: true
    })
  };


export class ProdutoResource extends AnterosRemoteResource {
    constructor(){
        super(PRODUTO,`${version}/produto/`);
    }

    get actions() {
        return _actions;
    }

    buscaProdutoNaTabelaPreco(resourceName, value, tabelaPreco, user, fieldsToForceLazy = "") {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaProdutoDaTabela/${value}?tabelaPreco=${tabelaPreco.join()}&fieldsToForceLazy=${fieldsToForceLazy}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    buscaProdutosPorTabelaPreco(resourceName, id, page, size, user, fieldsToForceLazy = "") {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaProdutosPorTabela?tabelaPreco=${id.join()}&page=${page}&size=${size}&fieldsToForceLazy=${fieldsToForceLazy}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    buscaProdutosPorCatalogo(resourceName, id, page, size, user, fieldsToForceLazy = "") {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaProdutosPorCatalogo?catalogo=${id}&page=${page}&size=${size}&fieldsToForceLazy=${fieldsToForceLazy}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    buscaProdutosPorTabelaECatalogo(resourceName, idTabela, idCatalogo, page, size, user, fieldsToForceLazy = "") {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}buscaProdutosPorTabelaCatalogo?tabelaPreco=${idTabela.join()}&catalogo=${idCatalogo}&page=${page}&size=${size}&fieldsToForceLazy=${fieldsToForceLazy}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
}

export const produtoReducer = createReducer(initialState,produtoReducerDef); 
export const produtoConsultaReducer = createReducer(initialState,produtoConsultaReducerDef);