import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const TABELA_PRECO_EDICAO = 'tabelaPrecoEdicao';
const tabelaPrecoEdicaoReducerDef = makeDefaultReduxObject(TABELA_PRECO_EDICAO);

export class TabelaPrecoEdicaoResource extends AnterosRemoteResource {
    constructor(){
        super(TABELA_PRECO_EDICAO,`${version}/tabelaPreco/`);
    }
}

export const tabelaPrecoEdicaoReducer = createReducer(initialState,tabelaPrecoEdicaoReducerDef);
