import {version} from './Version';
import { AnterosRemoteResource } from '@anterostecnologia/anteros-react-api';
import {initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const RESUMO_PEDIDOS_RECEBIDOS = 'resumoPedidosRecebidos';

const _actions = {
    setDatasource(dataSource) {
      return { type: 'SET_DATASOURCE_PEDIDORECEBIDO_RESUMO', payload: { dataSource } };
    },
    setDatasourceCliente(dataSource) {
        return { type: 'SET_DATASOURCE_CLIENTE_PEDIDORECEBIDO_RESUMO', payload: { dataSource } };
      },
    setFilter(currentFilter, activeFilterIndex) {
      return {
        type: 'SET_FILTER_PEDIDORECEBIDO_RESUMO',
        payload: { currentFilter, activeFilterIndex }
      };
    },
    clear() {
      return {
        type: 'CLEAR_PEDIDORECEBIDO_RESUMO',
        payload: {}
      };
    },
    setNeedRefresh() {
      return {
        type: 'SET_NEEDREFRESH_PEDIDORECEBIDO_RESUMO',
        payload: { needRefresh: true }
      }
    }
  };

const resumoPedidosRecebidosReducerDef = {
    'SET_DATASOURCE_PEDIDORECEBIDO_RESUMO': (state, payload) => ({
        ...state,
        dataSource: payload.dataSource,
        needRefresh: false
    }),
    'SET_DATASOURCE_CLLIENTE_PEDIDORECEBIDO_RESUMO': (state, payload) => ({
        ...state,
        dataSourceCliente: payload.dataSource,
        needRefresh: false
    }),
    'SET_FILTER_PEDIDORECEBIDO_RESUMO': (state, payload) => ({
        ...state,
        currentFilter: payload.currentFilter,
        activeFilterIndex: payload.activeFilterIndex
    }),
    'CLEAR_PEDIDORECEBIDO_RESUMO': { initialState },
    'SET_NEEDREFRESH_PEDIDORECEBIDO_RESUMO': (state, payload) => ({
        ...state,
        needRefresh: true
    })
  };


export class ResumoPedidosRecebidosResource extends AnterosRemoteResource {
    constructor(){
        super(RESUMO_PEDIDOS_RECEBIDOS,`${version}/pedidoRecebido/`);
    }

    get actions() {
        return _actions;
    }
}

export const resumoPedidosRecebidosReducer = createReducer(initialState,resumoPedidosRecebidosReducerDef);
