import {version} from './Version';
import { AnterosRemoteResource, GET, makeDefaultReduxObject, initialState, createReducer } from '@anterostecnologia/anteros-react-api';
const MODELO_EMAIL = 'modeloEmail';
const modeloEmailReducerDef = makeDefaultReduxObject(MODELO_EMAIL);
const modeloEmailConsultaReducerDef = makeDefaultReduxObject(MODELO_EMAIL.toUpperCase()+"_SEARCH");

export class ModeloEmailResource extends AnterosRemoteResource {
    constructor(){
        super(MODELO_EMAIL,`${version}/modeloEmail/`);
    }

    findAllPalavraChave(resourceName, palavraChava, user) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}findAllPalavraChave/palavra/${palavraChava}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
}

export const modeloEmailReducer = createReducer(initialState,modeloEmailReducerDef);
export const modeloEmailConsultaReducer = createReducer(initialState,modeloEmailConsultaReducerDef);