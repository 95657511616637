import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const META_ATENDIMENTO = 'metaAtendimento';
const metaAtendimentoReducerDef = makeDefaultReduxObject(META_ATENDIMENTO);
const metaAtendimentoConsultaReducerDef = makeDefaultReduxObject(META_ATENDIMENTO.toUpperCase()+"_SEARCH");

export class MetaAtendimentoResource extends AnterosRemoteResource {
    constructor(){
        super(META_ATENDIMENTO,`${version}/metaAtendimento/`);
    }
}

export const metaAtendimentoReducer = createReducer(initialState,metaAtendimentoReducerDef);
export const metaAtendimentoConsultaReducer = createReducer(initialState,metaAtendimentoConsultaReducerDef);