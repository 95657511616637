import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const META_VENDA = 'metaVenda';
const metaVendaReducerDef = makeDefaultReduxObject(META_VENDA);
const metaVendaConsultaReducerDef = makeDefaultReduxObject(META_VENDA.toUpperCase()+"_SEARCH");

export class MetaVendaResource extends AnterosRemoteResource {
    constructor(){
        super(META_VENDA,`${version}/metaVenda/`);
    }
}

export const metaVendaReducer = createReducer(initialState,metaVendaReducerDef);
export const metaVendaConsultaReducer = createReducer(initialState,metaVendaConsultaReducerDef);