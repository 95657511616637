import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const TABPRECO_PRODUTO_CLIENTE = 'tabelaPrecoProdutoCliente';
const tabelaPrecoProdutoClienteReducerDef = makeDefaultReduxObject(TABPRECO_PRODUTO_CLIENTE);
const tabelaPrecoProdutoClienteConsultaReducerDef = makeDefaultReduxObject(TABPRECO_PRODUTO_CLIENTE.toUpperCase()+"_SEARCH");

export class TabelaPrecoProdutoClienteResource extends AnterosRemoteResource {
    constructor(){
        super(TABPRECO_PRODUTO_CLIENTE,`${version}/tabelaPrecoProdutoCliente/`);
    }
}

export const tabelaPrecoProdutoClienteReducer = createReducer(initialState,tabelaPrecoProdutoClienteReducerDef);
export const tabelaPrecoProdutoClienteConsultaReducer = createReducer(initialState,tabelaPrecoProdutoClienteConsultaReducerDef);