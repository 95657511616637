import {
    version
} from './Version';
import { AnterosRemoteResource, GET,initialState, createReducer } from '@anterostecnologia/anteros-react-api';

const _actions = {
    setDatasource(dataSource) {
      return { type: 'SET_DATASOURCE_CATALOGO_EDICAO', payload: { dataSource } };
    },
    setFilter(currentFilter, activeFilterIndex) {
      return {
        type: 'SET_FILTER_CATALOGO_EDICAO',
        payload: { currentFilter, activeFilterIndex }
      };
    },
    clear() {
      return {
        type: 'CLEAR_CATALOGO_EDICAO',
        payload: {}
      };
    },
    setNeedRefresh() {
      return {
        type: 'SET_NEEDREFRESH_CATALOGO_EDICAO',
        payload: { needRefresh: true }
      }
    }
  };

const CATALOGO_EDICAO = 'catalogoEdicao';
const catalogoEdicaoReducerDef = {
    'SET_DATASOURCE_CATALOGO_EDICAO': (state, payload) => ({
        ...state,
        dataSource: payload.dataSource,
        needRefresh: false
    }),
    'SET_FILTER_CATALOGO_EDICAO': (state, payload) => ({
        ...state,
        currentFilter: payload.currentFilter,
        activeFilterIndex: payload.activeFilterIndex
    }),
    'CLEAR_CATALOGO_EDICAO': { initialState },
    'SET_NEEDREFRESH_CATALOGO_EDICAO': (state, payload) => ({
        ...state,
        needRefresh: true
    })
  };

export class CatalogoEdicaoResource extends AnterosRemoteResource {

    constructor() {
        super(CATALOGO_EDICAO,`${version}/catalogo/`)
    }

    get actions() {
        return _actions;
    }

    buscarResumoCatalogos(resourceName, page, size, sort, user, idTable, idColecao) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        var url = `${_resourceName}buscaCatalogosResumo?page=${page}&size=${size}`;
        if (idTable && idColecao) {
            url = `${_resourceName}buscaCatalogosResumo?tabelaPreco=${idTable}&colecao=${idColecao}&page=${page}&size=${size}`;
        } else if (idTable) {
            url = `${_resourceName}buscaCatalogosResumo?tabelaPreco=${idTable}&page=${page}&size=${size}`;
        } else if (idColecao) {
            url = `${_resourceName}buscaCatalogosResumo?colecao=${idColecao}&page=${page}&size=${size}`;
        }
        let result = {
            url: url,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

}

export const catalogoEdicaoReducer = createReducer(initialState,catalogoEdicaoReducerDef);
