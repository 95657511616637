import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const STATUS_PEDIDO = 'statusPedido';
const statusPedidoReducerDef = makeDefaultReduxObject(STATUS_PEDIDO);
const statusPedidoConsultaReducerDef = makeDefaultReduxObject(STATUS_PEDIDO.toUpperCase()+"_SEARCH");

export class StatusPedidoResource extends AnterosRemoteResource {
    constructor(){
        super(STATUS_PEDIDO,`${version}/statusPedido/`);
    }
}

export const statusPedidoReducer = createReducer(initialState,statusPedidoReducerDef);
export const statusPedidoConsultaReducer = createReducer(initialState,statusPedidoConsultaReducerDef);