import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const PEDIDO = 'pedido';
const pedidoReducerDef = makeDefaultReduxObject(PEDIDO);
const pedidoConsultaReducerDef = makeDefaultReduxObject(PEDIDO.toUpperCase()+"_SEARCH");

export class PedidoResource extends AnterosRemoteResource {
    constructor(){
        super(PEDIDO,`${version}/pedido/`);
    }
}

export const pedidoReducer = createReducer(initialState,pedidoReducerDef);
export const pedidoConsultaReducer = createReducer(initialState,pedidoConsultaReducerDef);