import React, { Component, Fragment } from "react";
import L from "leaflet"; // eslint-disable-line
import "leaflet-extra-markers/dist/js/leaflet.extra-markers";
import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import "./App.css";
import "leaflet/dist/leaflet.css";
import "./assets/scss/sales-cloud.scss";
import { AnterosErrorBoundary } from "@anterostecnologia/anteros-react-core";
import { AnterosNotificationContainer } from "@anterostecnologia/anteros-react-notification";
import { DndProvider } from "react-dnd-multi-backend";
import HTML5Backend from "react-dnd-html5-backend";
import ContainerModal from "react-modal-promise";
  
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import LayoutBusiness from "./containers/LayoutBusiness"; 
import {
  AnterosSecurityRoute,
  AnterosNotFound,
} from "@anterostecnologia/anteros-react-security";
import LoginView from "./containers/LoginView";
import { connect } from "react-redux";
import { versatilBaseURL, authBaseURL } from "./constantes";
import { AnterosCornerRibbon } from "@anterostecnologia/anteros-react-misc";
import { AnterosErrorFallback } from "./AnterosErrorFallback";
import echarts from "echarts";
import { theme1 } from "./charts_theme";

echarts.registerTheme("versatil", theme1);

class App extends Component {
  componentDidMount() {
    var basic =
      "Basic dmVyc2F0aWwtdnJwOnVhdnFDZTlBTEF5VkZ0N2ttMmtmdGJuZ0hneFhSRQ=="; //versatil-vrp:password
    var settings = {
      async: true,
      crossDomain: true,
      url: `${authBaseURL}/oauth/token`,
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        authorization: basic,
      },
      data: {
        username: "admin",
        password: "admin1234",
        grant_type: "password",
      },
      error: function() {
        console.log("Ocorreu um erro localizando servidor de autorização.");
      },
      success: function(data) {
        localStorage.setItem("tk", data.access_token);
        console.log(data);
      },
    };
    window.$.ajax(settings);
  }

  componentWillReceiveProps(nextProps) {
    //
  }

  render() {
    const { authenticated } = this.props;
    return (
      <DndProvider backend={HTML5Backend}>
        <ContainerModal />
        <Router>
          <AnterosErrorBoundary FallbackComponent={AnterosErrorFallback}>
            {/* <DispacthError /> */}
            <div>
              {this.props.user && this.props.user.owner.urlAPI.includes("sandbox") ? (
                <AnterosCornerRibbon
                  position="top-right"
                  fontColor="#f0f0f0"
                  backgroundColor="#F03E02"
                  containerStyle={{zIndex: 9999}}
                  style={{}}
                  className=""
                >
                  SANDBOX
                </AnterosCornerRibbon>
              ) : null}
              <AnterosNotificationContainer />
              <Switch>
                <Route path="/owner/:uuid" component={LoginView} />
                <Route path="/login" component={LoginView} />
                <Fragment>
                  <Route
                    exact
                    path="/"
                    render={() => (
                      <Redirect to="/home/business/default" push={true} />
                    )}
                  />
                  <AnterosSecurityRoute
                    path="/home/business"
                    component={LayoutBusiness}
                    allowAccess={authenticated}
                  />
                </Fragment>
                <Route component={AnterosNotFound} />
              </Switch>
            </div>
          </AnterosErrorBoundary>
        </Router>
      </DndProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authenticationReducer.user,
    authenticating: state.authenticationReducer.authenticating,
    authenticated: state.authenticationReducer.authenticated,
    serviceWorkerInitialized: state.swReducer.serviceWorkerInitialized,
  };
};

export default connect(mapStateToProps, null)(App);
