import { TituloAReceberResource, tituloAReceberReducer, tituloAReceberConsultaReducer } from './resources/TituloAReceberResource';
import { AtendimentoResource, atendimentoReducer, atendimentoConsultaReducer } from './resources/AtendimentoResource';
import { AtendimentoEdicaoResource, atendimentoEdicaoReducer} from './resources/AtendimentoEdicaoResource';
import { ChecklistResource, checklistReducer, checklistConsultaReducer } from './resources/ChecklistResource';
import { CategoriaResource, categoriaReducer, categoriaConsultaReducer } from './resources/CategoriaResource';
import { ProprietarioResource, proprietarioReducer, proprietarioConsultaReducer } from './resources/ProprietarioResource';
import { CidadeResource, cidadeReducer, cidadeConsultaReducer } from './resources/CidadeResource';
import { ClassificacaoAtendimentoResource, classificacaoAtendimentoReducer, classificacaoAtendimentoConsultaReducer } from './resources/ClassificacaoAtendimentoResource';
import { CorResource, corReducer,corConsultaReducer } from './resources/CorResource';
import { ColecaoResource, colecaoReducer, colecaoConsultaReducer } from './resources/ColecaoResource';
import { EstadoResource, estadoReducer, estadoConsultaReducer } from './resources/EstadoResource';
import { EmpresaResource, empresaReducer, empresaConsultaReducer } from './resources/EmpresaResource';
import { MarcaResource, marcaReducer, marcaConsultaReducer } from './resources/MarcaResource';
import { EquipamentoResource, equipamentoReducer, equipamentoConsultaReducer } from './resources/EquipamentoResource';
import { CondicaoPagamentoResource, condicaoPagamentoReducer, condicaoPagamentoConsultaReducer } from './resources/CondicaoPagamentoResource';
import { FormaPagamentoResource, formaPagamentoReducer, formaPagamentoConsultaReducer } from './resources/FormaPagamentoResource';
import { ModalidadeNegociacaoResource, modalidadeNegociacaoReducer, modalidadeNegociacaoConsultaReducer } from './resources/ModalidadeNegociacaoResource';
import { MotivoNaoVendaResource, motivoNaoVendaReducer, motivoNaoVendaConsultaReducer } from './resources/MotivoNaoVendaResource';
import { PesquisaComercialResource, pesquisaComercialReducer, pesquisaComercialConsultaReducer } from './resources/PesquisaComercialResource';
import { PessoaResource, pessoaReducer, pessoaConsultaReducer } from './resources/PessoaResource';
import { PessoaEdicaoResource, pessoaEdicaoReducer, pessoaEdicaoConsultaReducer } from './resources/PessoaEdicaoResource';
import { ProdutoResource, produtoReducer, produtoConsultaReducer } from './resources/ProdutoResource';
import { ProdutoEdicaoResource, produtoEdicaoReducer } from './resources/ProdutoEdicaoResource';
import { GrupoClienteResource, grupoClienteReducer, grupoClienteConsultaReducer } from './resources/GrupoClienteResource';
import { SituacaoComercialResource, situacaoComercialReducer, situacaoComercialConsultaReducer } from './resources/SituacaoComercialResource';
import { StatusPedidoResource, statusPedidoReducer, statusPedidoConsultaReducer } from './resources/StatusPedidoResource';
import { TamanhoResource, tamanhoReducer, tamanhoConsultaReducer } from './resources/TamanhoResource';
import { TipoLogradouroResource, tipoLogradouroReducer, tipoLogradouroConsultaReducer } from './resources/TipoLogradouroResource';
import { TabelaPrecoResource, tabelaPrecoReducer, tabelaPrecoConsultaReducer } from './resources/TabelaPrecoResource';
import { TabelaPrecoEdicaoResource, tabelaPrecoEdicaoReducer } from './resources/TabelaPrecoEdicaoResource';
import { TabelaPrecoProdutoResource, tabelaPrecoProdutoReducer, tabelaPrecoProdutoConsultaReducer } from './resources/TabelaPrecoProdutoResource';
import { TabelaPrecoDescontoResource, tabelaPrecoDescontoReducer, tabelaPrecoDescontoConsultaReducer } from './resources/TabelaPrecoDescontoResource';
import { TabelaPrecoDescontoProgressivoResource, tabelaPrecoDescontoProgressivoReducer, tabelaPrecoDescontoProgressivoConsultaReducer } from './resources/TabelaPrecoDescontoProgressivoResource';
import { TabelaPrecoValorMinimoResource, tabelaPrecoVlMinimoReducer, tabelaPrecoVlMinimoConsultaReducer } from './resources/TabelaPrecoValorMinimoResource';
import { CatalogoImagemProdutoResource, catalogoImagemProdutoReducer, catalogoImagemProdutoConsultaReducer } from './resources/CatalogoImagemProdutoResource';
import { CatalogoImagemResource, catalogoImagemReducer, catalogoImagemConsultaReducer } from './resources/CatalogoImagemResource';
import { CatalogoResource, catalogoReducer, catalogoConsultaReducer } from './resources/CatalogoResource';
import { CatalogoEdicaoResource, catalogoEdicaoReducer } from './resources/CatalogoEdicaoResource';
import { ContratoLicencaResource, contratoLicencaReducer, contratoLicencaConsultaReducer } from './resources/ContratoLicencaResource';
import { TabelaPrecoProdutoClienteResource, tabelaPrecoProdutoClienteReducer, tabelaPrecoProdutoClienteConsultaReducer } from './resources/TabelaPrecoProdutoClienteResource';
import { ContratoRepresentacaoComercialResource, contratoRepresentacaoComercialReducer, contratoRepresentacaoComercialConsultaReducer } from './resources/ContratoRepresentacaoComercialResource';
import { MetaAtendimentoResource, metaAtendimentoReducer, metaAtendimentoConsultaReducer } from './resources/MetaAtendimentoResource';
import { MetaVendaResource, metaVendaReducer, metaVendaConsultaReducer } from './resources/MetaVendaResource';
import { AutorizacaoVendaResource, autorizacaoVendaReducer, autorizacaoVendaConsultaReducer } from './resources/AutorizacaoVendaResource';
import { RotaAtendimentoResource, rotaAtendimentoReducer,rotaAtendimentoConsultaReducer } from './resources/RotaAtendimentoResource';
import { PedidoResource,pedidoReducer, pedidoConsultaReducer } from './resources/PedidoResource';
import { AbstractEventoResource, abstractEventoReducer, abstractEventoConsultaReducer } from './resources/AbstractEventoResource';
import { PedidoRecebidoResource, pedidoRecebidoReducer,pedidoRecebidoConsultaReducer } from './resources/PedidoRecebidoResource';
import { PedidoRecebidoEdicaoResource, pedidoRecebidoEdicaoReducer } from './resources/PedidoRecebidoEdicaoResource';
import { PedidoEdicaoResource, pedidoEdicaoReducer } from './resources/PedidoEdicaoResource';
import { PlanejamentoRotaComercialResource, planejamentoRotaComercialReducer, planejamentoRotaComercialConsultaReducer } from './resources/PlanejamentoRotaComercialResource';
import { ModeloEmailResource, modeloEmailReducer, modeloEmailConsultaReducer } from './resources/ModeloEmailResource';
import { FilaEmailResource, filaEmailReducer, filaEmailConsultaReducer } from './resources/FilaEmailResource';
import { FilaGeocodificacaoResource, filaGeocodificacaoReducer, filaGeocodificacaoConsultaReducer } from './resources/FilaGeocodificacaoResource';
import { PedidoItemResource,pedidoItemReducer,pedidoItemConsultaReducer } from './resources/PedidoItemResource';
import { DashboardConfigResource,dashboardConfigReducer,dashboardConfigConsultaReducer } from './resources/DashboardConfigResource';
import { CubeConfigResource,cubeConfigReducer,cubeConfigConsultaReducer } from './resources/CubeConfigResource';
import { ProdutoSkuResource,produtoSkuReducer,produtoSkuConsultaReducer } from './resources/ProdutoSkuResource';
import { PeriodoEntregaResource, periodoEntregaReducer, periodoEntregaConsultaReducer } from './resources/PeriodoEntregaResource';
import { UsuariosResource, usuariosReducer, usuariosConsultaReducer } from './resources/UsuariosResource';
import { UsuarioAcaoResource, usuarioAcaoReducer, usuarioAcaoConsultaReducer } from './resources/UsuarioAcaoResource';
import { PerfilResource, perfilReducer, perfilConsultaReducer } from './resources/PerfilResource';
import { EnderecoResource, enderecoReducer, enderecoConsultaReducer } from './resources/EnderecoResource';
import { GeocodeHomologacaoResource, geocodeHomologacaoReducer, geocodeHomologacaoConsultaReducer } from './resources/GeocodeHomologacaoResource';
import { RegiaoComercialResource, regiaoComercialReducer, regiaoComercialConsultaReducer } from './resources/RegiaoComercialResource';
import { ResumoPedidosRecebidosResource, resumoPedidosRecebidosReducer} from './resources/ResumoPedidosRecebidosResource';
import { RegraTributacaoResource, regraTributacaoReducer, regraTributacaoConsultaReducer } from './resources/RegraTributacaoResource';
import { ExcecaoFiscalResource, excecaoFiscalReducer, excecaoFiscalConsultaReducer } from './resources/ExcecaoFiscalResource';
import { PoliticaVendaResource, politicaVendaReducer, politicaVendaConsultaReducer } from './resources/PoliticaVendaResource';
import { TabelaPrecoPoliticaVendaResource, tabelaPrecoPoliticaVendaReducer, tabelaPrecoPoliticaVendaConsultaReducer } from './resources/TabelaPrecoPoliticaVendaResource';
import { FunilVendaResource, funilVendaReducer, funilVendaConsultaReducer } from './resources/FunilVendaResource';
import { TipoLigacaoResource, tipoLigacaoReducer, tipoLigacaoConsultaReducer } from './resources/TipoLigacaoResource';
import { TipoCompromissoResource, tipoCompromissoReducer, tipoCompromissoConsultaReducer } from './resources/TipoCompromissoResource';
import { TagResource, tagReducer, tagConsultaReducer } from './resources/TagResource';
import { TipoAtividadeResource, tipoAtividadeReducer, tipoAtividadeConsultaReducer } from './resources/TipoAtividadeResource';
import { OportunidadesResource, oportunidadeReducer, oportunidadeConsultaReducer } from './resources/OportunidadesResource';
import { CompromissoResource, compromissoReducer, compromissoConsultaReducer } from './resources/CompromissoResource';
import { AtividadeResource, atividadeReducer, atividadeConsultaReducer } from './resources/AtividadeResource';
import { CanalAtendimentoResource, canalAtendimentoReducer, canalAtendimentoConsultaReducer } from './resources/CanalAtendimentoResource';


export const version = 'v1';

export const Resources = {
    ATENDIMENTO: new AtendimentoResource(),
    ATENDIMENTO_EDICAO: new AtendimentoEdicaoResource(),
    AUTORIZACAOVENDA: new AutorizacaoVendaResource(),
    CATEGORIA: new CategoriaResource(),
    CATALOGO: new CatalogoResource(),
    CATALOGOIMAGEM: new CatalogoImagemResource(),
    CATALOGOPRODUTO: new CatalogoImagemProdutoResource(),
    CATALOGO_EDICAO: new CatalogoEdicaoResource(),
    CHECKLIST: new ChecklistResource(),
    CIDADE: new CidadeResource(),
    CLASSIFATENDIMENTO: new ClassificacaoAtendimentoResource(),
    CLIENTE: new ProprietarioResource(),
    COLECAO: new ColecaoResource(),
    CONDICAOPAGAMENTO: new CondicaoPagamentoResource(),
    CONTRATOLICENCA: new ContratoLicencaResource(),
    CONTRATOREPRESENTANTE: new ContratoRepresentacaoComercialResource(),
    COR: new CorResource(),
    CUBE_CONFIG: new CubeConfigResource(),
    DASHBOARD_CONFIG: new DashboardConfigResource(),
    EMPRESA: new EmpresaResource(),
    ENDERECO: new EnderecoResource(),
    EQUIPAMENTO: new EquipamentoResource(),
    ESTADO: new EstadoResource(),
    EVENTO: new AbstractEventoResource(),
    EXCECAO_FISCAL: new ExcecaoFiscalResource(),
    FILAEMAIL: new FilaEmailResource(),
    FILAGEOCODE: new FilaGeocodificacaoResource(),
    FORMAPAGAMENTO: new FormaPagamentoResource(),
    GEOCODE_HOMOLOGACAO: new GeocodeHomologacaoResource(),
    GRUPOCLIENTE: new GrupoClienteResource(),
    MARCA: new MarcaResource(),
    METASATENDIMENTO: new MetaAtendimentoResource(),
    METASVENDA: new MetaVendaResource(),
    MODALIDADENEGOCIACAO: new ModalidadeNegociacaoResource(),
    MODELOEMAIL: new ModeloEmailResource(),
    MOTIVONAOVENDA: new MotivoNaoVendaResource(),
    PEDIDO: new PedidoResource(),
    PEDIDO_EDICAO: new PedidoEdicaoResource(),
    PEDIDOITEM: new PedidoItemResource(),
    PEDIDORECEBIDO: new PedidoRecebidoResource(),
    PEDIDORECEBIDO_EDICAO: new PedidoRecebidoEdicaoResource(),
    PERFIL: new PerfilResource(),
    PERIODOENTREGA: new PeriodoEntregaResource(),
    PESQUISACOMERCIAL: new PesquisaComercialResource(),
    PESSOA: new PessoaResource(),
    PESSOA_EDICAO: new PessoaEdicaoResource(),
    PLANEJAMENTOROTA: new PlanejamentoRotaComercialResource(),
    POLITICAVENDA: new PoliticaVendaResource(),
    PRODUTO: new ProdutoResource(),
    PRODUTO_EDICAO: new ProdutoEdicaoResource(),
    PROPRIETARIO: new ProprietarioResource(),
    REGIAOCOMERCIAL: new RegiaoComercialResource(),
    REGRA_TRIBUTACAO: new RegraTributacaoResource(),
    RESUMO_PEDIDO_RECEBIDO: new ResumoPedidosRecebidosResource(),
    ROTAATENDIMENTO: new RotaAtendimentoResource(),
    SITUACAOCOMERCIAL: new SituacaoComercialResource(),
    SKU: new ProdutoSkuResource(),
    STATUSPEDIDO: new StatusPedidoResource(),
    TABELACLIENTE: new TabelaPrecoProdutoClienteResource(),
    TABELAPRECO: new TabelaPrecoResource(),
    TABELAPRECOPOLITICAVENDA: new TabelaPrecoPoliticaVendaResource(),
    TABELAPRECODESCONTO: new TabelaPrecoDescontoResource(),
    TABELAPRECODESCPROG: new TabelaPrecoDescontoProgressivoResource(),
    TABELAPRECOPRODUTO: new TabelaPrecoProdutoResource(),
    TABELAPRECOVLMINIMO: new TabelaPrecoValorMinimoResource(),
    TABELAPRECO_EDICAO: new TabelaPrecoEdicaoResource(),
    TAMANHO: new TamanhoResource(),
    TIPOLOGRADOURO: new TipoLogradouroResource(),
    TITULOARECEBER: new TituloAReceberResource(),
    USUARIO: new UsuariosResource(),
    USUARIOACAO: new UsuarioAcaoResource(),
    FUNIL_VENDA: new FunilVendaResource(),
    TIPOCOMPROMISSO: new TipoCompromissoResource(),
    TIPOLIGACAO: new TipoLigacaoResource(),
    TAG: new TagResource(),
    TIPOATIVIDADE: new TipoAtividadeResource(),
    OPORTUNIDADE: new OportunidadesResource(),
    COMPROMISSO: new CompromissoResource(),
    ATIVIDADE: new AtividadeResource(),
    CANAL_ATENDIMENTO: new CanalAtendimentoResource(),
};

export const getAllReducersFromResources = () => {
    return {
        tituloAReceberReducer, tituloAReceberConsultaReducer,
        atendimentoReducer, atendimentoConsultaReducer,
        checklistReducer, checklistConsultaReducer,
        categoriaReducer, categoriaConsultaReducer,
        proprietarioReducer, proprietarioConsultaReducer,
        cidadeReducer, cidadeConsultaReducer,
        classificacaoAtendimentoReducer, classificacaoAtendimentoConsultaReducer,
        corReducer,corConsultaReducer,
        colecaoReducer, colecaoConsultaReducer,
        estadoReducer, estadoConsultaReducer,
        empresaReducer, empresaConsultaReducer,
        marcaReducer, marcaConsultaReducer,
        equipamentoReducer, equipamentoConsultaReducer,
        condicaoPagamentoReducer, condicaoPagamentoConsultaReducer,
        formaPagamentoReducer, formaPagamentoConsultaReducer,
        modalidadeNegociacaoReducer, modalidadeNegociacaoConsultaReducer,
        motivoNaoVendaReducer, motivoNaoVendaConsultaReducer,
        pesquisaComercialReducer, pesquisaComercialConsultaReducer,
        pessoaReducer, pessoaConsultaReducer,
        pessoaEdicaoReducer, pessoaEdicaoConsultaReducer,
        produtoReducer, produtoConsultaReducer,
        grupoClienteReducer, grupoClienteConsultaReducer,
        situacaoComercialReducer, situacaoComercialConsultaReducer,
        statusPedidoReducer, statusPedidoConsultaReducer,
        tamanhoReducer, tamanhoConsultaReducer,
        tipoLogradouroReducer, tipoLogradouroConsultaReducer,
        tabelaPrecoReducer, tabelaPrecoConsultaReducer,
        tabelaPrecoProdutoReducer, tabelaPrecoProdutoConsultaReducer,
        tabelaPrecoDescontoReducer, tabelaPrecoDescontoConsultaReducer,
        tabelaPrecoDescontoProgressivoReducer, tabelaPrecoDescontoProgressivoConsultaReducer,
        tabelaPrecoVlMinimoReducer, tabelaPrecoVlMinimoConsultaReducer,
        catalogoImagemReducer, catalogoImagemConsultaReducer,
        catalogoReducer, catalogoConsultaReducer,
        contratoLicencaReducer, contratoLicencaConsultaReducer,
        tabelaPrecoProdutoClienteReducer, tabelaPrecoProdutoClienteConsultaReducer,
        contratoRepresentacaoComercialReducer, contratoRepresentacaoComercialConsultaReducer,
        metaAtendimentoReducer, metaAtendimentoConsultaReducer,
        metaVendaReducer, metaVendaConsultaReducer,
        autorizacaoVendaReducer, autorizacaoVendaConsultaReducer,
        rotaAtendimentoReducer,rotaAtendimentoConsultaReducer,
        pedidoReducer, pedidoConsultaReducer,
        abstractEventoReducer, abstractEventoConsultaReducer,
        pedidoRecebidoReducer,pedidoRecebidoConsultaReducer,
        planejamentoRotaComercialReducer, planejamentoRotaComercialConsultaReducer,
        modeloEmailReducer, modeloEmailConsultaReducer,
        catalogoImagemProdutoReducer, catalogoImagemProdutoConsultaReducer,
        filaEmailReducer, filaEmailConsultaReducer,
        pedidoItemReducer,pedidoItemConsultaReducer,
        dashboardConfigReducer,dashboardConfigConsultaReducer,
        cubeConfigReducer,cubeConfigConsultaReducer,
        produtoSkuReducer,produtoSkuConsultaReducer,
        produtoEdicaoReducer,periodoEntregaReducer, periodoEntregaConsultaReducer,
        usuariosReducer, usuariosConsultaReducer,
        usuarioAcaoReducer, usuarioAcaoConsultaReducer,
        perfilReducer, perfilConsultaReducer,
        enderecoReducer, enderecoConsultaReducer,
        geocodeHomologacaoReducer, geocodeHomologacaoConsultaReducer,
        regiaoComercialReducer, regiaoComercialConsultaReducer,
        tabelaPrecoEdicaoReducer,
        catalogoEdicaoReducer, atendimentoEdicaoReducer,
        pedidoRecebidoEdicaoReducer,
        resumoPedidosRecebidosReducer, pedidoEdicaoReducer,
        regraTributacaoReducer, regraTributacaoConsultaReducer,
        excecaoFiscalReducer, excecaoFiscalConsultaReducer,
        politicaVendaReducer, politicaVendaConsultaReducer,
        tabelaPrecoPoliticaVendaConsultaReducer,
        tabelaPrecoPoliticaVendaReducer, funilVendaReducer, 
        funilVendaConsultaReducer, tipoCompromissoReducer,
        tipoCompromissoConsultaReducer, tipoLigacaoConsultaReducer,
        tipoLigacaoReducer, tagReducer, tagConsultaReducer,
        tipoAtividadeReducer, tipoAtividadeConsultaReducer,
        oportunidadeConsultaReducer, oportunidadeReducer,
        compromissoReducer, compromissoConsultaReducer,
        atividadeReducer, atividadeConsultaReducer,
        canalAtendimentoConsultaReducer, canalAtendimentoReducer,
        filaGeocodificacaoReducer, filaGeocodificacaoConsultaReducer
    }
}
