import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const FUNIL_VENDA = 'funilVenda';
const funilVendaReducerDef = makeDefaultReduxObject(FUNIL_VENDA);
const funilVendaConsultaReducerDef = makeDefaultReduxObject(FUNIL_VENDA.toUpperCase()+"_SEARCH");

export class FunilVendaResource extends AnterosRemoteResource {
    constructor(){
        super(FUNIL_VENDA,`${version}/funilVenda/`);
    }
}

export const funilVendaReducer = createReducer(initialState,funilVendaReducerDef);
export const funilVendaConsultaReducer = createReducer(initialState,funilVendaConsultaReducerDef);