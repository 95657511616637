import {version} from './Version';
import { AnterosRemoteResource, GET } from '@anterostecnologia/anteros-react-api';
import {makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
import {AnterosDateUtils} from '@anterostecnologia/anteros-react-core';
const PESQUISA_COMERCIAL = 'pesquisaComercial';
const pesquisaComercialReducerDef = makeDefaultReduxObject(PESQUISA_COMERCIAL);
const pesquisaComercialConsultaReducerDef = makeDefaultReduxObject(PESQUISA_COMERCIAL.toUpperCase()+"_SEARCH");

export class PesquisaComercialResource extends AnterosRemoteResource {
    constructor(){
        super(PESQUISA_COMERCIAL,`${version}/pesquisaComercial/`);
    }

    alterarStatusPesquisa(resourceName, id, status, user) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}alteraStatusPesquisa/${id}/${status}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }

    buscarPesquisasPeriodo(resourceName, dtInicial, dtFinal, user) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let dtInicio = AnterosDateUtils.formatDate(dtInicial, 'YYYY-MM-DD');
        let dtfim = AnterosDateUtils.formatDate(dtFinal, 'YYYY-MM-DD');
        let result = {
            url: `${_resourceName}buscaPesquisasPeriodo/dtInicial/${dtInicio}/dtFinal/${dtfim}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
}

export const pesquisaComercialReducer = createReducer(initialState,pesquisaComercialReducerDef);
export const pesquisaComercialConsultaReducer = createReducer(initialState,pesquisaComercialConsultaReducerDef);