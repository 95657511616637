import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const TAG = 'tag';
const tagReducerDef = makeDefaultReduxObject(TAG);
const tagConsultaReducerDef = makeDefaultReduxObject(TAG.toUpperCase()+"_SEARCH");

export class TagResource extends AnterosRemoteResource {
    constructor(){
        super(TAG,`${version}/tag/`);
    }
}

export const tagReducer = createReducer(initialState,tagReducerDef);
export const tagConsultaReducer = createReducer(initialState,tagConsultaReducerDef);