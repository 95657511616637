import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const PERFIL = 'perfil';
const perfilReducerDef = makeDefaultReduxObject(PERFIL);
const perfilConsultaReducerDef = makeDefaultReduxObject(PERFIL.toUpperCase()+"_SEARCH");

export class PerfilResource extends AnterosRemoteResource {
    constructor(){
        super(PERFIL,`${version}/profile/`);
    }
}

export const perfilReducer = createReducer(initialState,perfilReducerDef);
export const perfilConsultaReducer = createReducer(initialState,perfilConsultaReducerDef);