import {version} from './Version';
import { AnterosRemoteResource, POST, makeDefaultReduxObject, initialState, createReducer } from '@anterostecnologia/anteros-react-api';
const REGIAO_COMERCIAL = 'regiaoComercial';
const regiaoComercialReducerDef = makeDefaultReduxObject(REGIAO_COMERCIAL);
const regiaoComercialConsultaReducerDef = makeDefaultReduxObject(REGIAO_COMERCIAL.toUpperCase()+"_SEARCH");

export class RegiaoComercialResource extends AnterosRemoteResource {
    constructor(){
        super(REGIAO_COMERCIAL,`${version}/regiaoComercial/`);
    }

    findByRegiaoMultipleFields(
        resourceName,
        idRegiao,
        filter,
        fields,
        page,
        size,
        sort,
        user,
        fieldsToForceLazy = ""
    ) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}findByRegiaoMultipleFields/${idRegiao}?filter=${encodeURIComponent(filter)}&fields=${fields}&page=${page}&size=${size}&sort=${sort}&fieldsToForceLazy=${fieldsToForceLazy}`,
            method: POST
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
}

export const regiaoComercialReducer = createReducer(initialState,regiaoComercialReducerDef);
export const regiaoComercialConsultaReducer = createReducer(initialState,regiaoComercialConsultaReducerDef);