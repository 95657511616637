import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const PLANEJAMENTO_ROTA = 'planejamentoRotaComercial';
const planejamentoRotaComercialReducerDef = makeDefaultReduxObject(PLANEJAMENTO_ROTA);
const planejamentoRotaComercialConsultaReducerDef = makeDefaultReduxObject(PLANEJAMENTO_ROTA.toUpperCase()+"_SEARCH");

export class PlanejamentoRotaComercialResource extends AnterosRemoteResource {
    constructor(){
        super(PLANEJAMENTO_ROTA,`${version}/planejamentoRotaComercial/`);
    }
}

export const planejamentoRotaComercialReducer = createReducer(initialState,planejamentoRotaComercialReducerDef);
export const planejamentoRotaComercialConsultaReducer = createReducer(initialState,planejamentoRotaComercialConsultaReducerDef);