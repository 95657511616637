import {version} from './Version';
import { AnterosRemoteResource, GET } from '@anterostecnologia/anteros-react-api';
import {makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const COLECAO = 'colecao';
const colecaoReducerDef = makeDefaultReduxObject(COLECAO);
const colecaoConsultaReducerDef = makeDefaultReduxObject(COLECAO.toUpperCase()+"_SEARCH");

export class ColecaoResource extends AnterosRemoteResource {
    constructor(){
        super(COLECAO,`${version}/colecao/`);
    }

    buscarResumoColecoes(resourceName, sort, user, idTable) {
        var url = `${resourceName}buscaColecoesResumo`;
        if (idTable) {
            url = `${resourceName}buscaColecoesResumo?tabelaPreco=${idTable}`;
        }
        let result = {
            url: url,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
}

export const colecaoReducer = createReducer(initialState,colecaoReducerDef);
export const colecaoConsultaReducer = createReducer(initialState,colecaoConsultaReducerDef);