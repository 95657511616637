import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const ATIVIDADE = 'atividade';
const atividadeReducerDef = makeDefaultReduxObject(ATIVIDADE);
const atividadeConsultaReducerDef = makeDefaultReduxObject(ATIVIDADE.toUpperCase()+"_SEARCH");

export class AtividadeResource extends AnterosRemoteResource {
    constructor(){
        super(ATIVIDADE,`${version}/atividade/`);
    }
}

export const atividadeReducer = createReducer(initialState,atividadeReducerDef);
export const atividadeConsultaReducer = createReducer(initialState,atividadeConsultaReducerDef);