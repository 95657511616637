import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const EXCECAO_FISCAL = 'excecaoFiscal';
const excecaoFiscalReducerDef = makeDefaultReduxObject(EXCECAO_FISCAL);
const excecaoFiscalConsultaReducerDef = makeDefaultReduxObject(EXCECAO_FISCAL.toUpperCase()+"_SEARCH");

export class ExcecaoFiscalResource extends AnterosRemoteResource {
    constructor(){
        super(EXCECAO_FISCAL,`${version}/excecaoFiscal/`);
    }
}

export const excecaoFiscalReducer = createReducer(initialState,excecaoFiscalReducerDef);
export const excecaoFiscalConsultaReducer = createReducer(initialState,excecaoFiscalConsultaReducerDef);