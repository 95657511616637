import {version} from './Version';
import { AnterosRemoteResource, GET } from '@anterostecnologia/anteros-react-api';
import {makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const CUBE_CONFIG = 'cubeConfig';
const cubeConfigReducerDef = makeDefaultReduxObject(CUBE_CONFIG);
const cubeConfigConsultaReducerDef = makeDefaultReduxObject(CUBE_CONFIG.toUpperCase()+"_SEARCH");

export class CubeConfigResource extends AnterosRemoteResource {
    constructor(){
        super(CUBE_CONFIG,`${version}/cubeConfig/`);
    }

    buscarCubeConfig(resourceName, name, user) {
        let _resourceName = resourceName;
        if (resourceName instanceof AnterosRemoteResource){
            _resourceName = resourceName.name;
        }
        let result = {
            url: `${_resourceName}getCubesByForm?name=${name}`,
            method: GET
        };
        result = this.applyOwner(user, result);
        return this.applyCompany(user, result);
    }
}

export const cubeConfigReducer = createReducer(initialState,cubeConfigReducerDef);
export const cubeConfigConsultaReducer = createReducer(initialState,cubeConfigConsultaReducerDef);