import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const MOTIVO_NAO_VENDA = 'motivoNaoVenda';
const motivoNaoVendaReducerDef = makeDefaultReduxObject(MOTIVO_NAO_VENDA);
const motivoNaoVendaConsultaReducerDef = makeDefaultReduxObject(MOTIVO_NAO_VENDA.toUpperCase()+"_SEARCH");

export class MotivoNaoVendaResource extends AnterosRemoteResource {
    constructor(){
        super(MOTIVO_NAO_VENDA,`${version}/motivoNaoVenda/`);
    }
}

export const motivoNaoVendaReducer = createReducer(initialState,motivoNaoVendaReducerDef);
export const motivoNaoVendaConsultaReducer = createReducer(initialState,motivoNaoVendaConsultaReducerDef);