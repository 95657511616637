import {version} from './Version';
import { AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const TABPRECO_POLITICAVENDA = 'tabelaPrecoPoliticaVenda';
const tabelaPrecoPoliticaVendaReducerDef = makeDefaultReduxObject(TABPRECO_POLITICAVENDA);
const tabelaPrecoPoliticaVendaConsultaReducerDef = makeDefaultReduxObject(TABPRECO_POLITICAVENDA.toUpperCase()+"_SEARCH");

export class TabelaPrecoPoliticaVendaResource extends AnterosRemoteResource {
    constructor(){
        super(TABPRECO_POLITICAVENDA,`${version}/tabelaPrecoPoliticaVenda/`);
    }    
}

export const tabelaPrecoPoliticaVendaReducer = createReducer(initialState,tabelaPrecoPoliticaVendaReducerDef);
export const tabelaPrecoPoliticaVendaConsultaReducer = createReducer(initialState,tabelaPrecoPoliticaVendaConsultaReducerDef);