import {actions as actionsClient} from './wsClientReducer';

export const ActionType = {
    SERVER_INFO: 'SERVER_INFO'
}

export const initialServerState = {
    boundingBox: null,
    countryCodes: [],
    demos: [],
};

export const actions = {
    serverInfo: info => ({
        type: ActionType.SERVER_INFO,
        value: info,
    })
}

const serverReducer = (state = initialServerState, action) => {
    switch (action.type) {
        case ActionType.SERVER_INFO: {
            return action.value;
        }
        default:
            return state;
    }
};


export const serverInfo = (
    info => (dispatch) => {
        dispatch(actionsClient.resetViewport());
        dispatch(actions.serverInfo(info));
    });


export default serverReducer;