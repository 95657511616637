import {version} from './Version';
import { AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer } from '@anterostecnologia/anteros-react-api';
const POLITICA_VENDA = 'politicaVenda';
const politicaVendaReducerDef = makeDefaultReduxObject(POLITICA_VENDA);
const politicaVendaConsultaReducerDef = makeDefaultReduxObject(POLITICA_VENDA.toUpperCase()+"_SEARCH");

export class PoliticaVendaResource extends AnterosRemoteResource {
    constructor(){
        super(POLITICA_VENDA,`${version}/politicaVenda/`);
    }

}

export const politicaVendaReducer = createReducer(initialState,politicaVendaReducerDef);
export const politicaVendaConsultaReducer = createReducer(initialState,politicaVendaConsultaReducerDef);