import {version} from './Version';
import {AnterosRemoteResource, makeDefaultReduxObject, initialState, createReducer} from '@anterostecnologia/anteros-react-api';
const CANAL_ATENDIMENTO = 'canalAtendimento';
const canalAtendimentoReducerDef = makeDefaultReduxObject(CANAL_ATENDIMENTO);
const canalAtendimentoConsultaReducerDef = makeDefaultReduxObject(CANAL_ATENDIMENTO.toUpperCase()+"_SEARCH");

export class CanalAtendimentoResource extends AnterosRemoteResource {
    constructor(){
        super(CANAL_ATENDIMENTO,`${version}/canalAtendimento/`);
    }
}

export const canalAtendimentoReducer = createReducer(initialState,canalAtendimentoReducerDef);
export const canalAtendimentoConsultaReducer = createReducer(initialState,canalAtendimentoConsultaReducerDef);